import { InputPassword } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { passwordRule } from '@/shared/validation';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { z } from 'zod';

const PASSWORD = 'ПАРОЛЬ';
const REPEAT_PASSWORD = 'Повторите пароль';
const PASSWORD_MATCH = 'совпадение паролей';

export const PASSWORD_FORM_FIELDS = {
  password: 'password',
  confirmPassword: 'confirmPassword',
} as const;

export const passwordSchema = z
  .object({
    [PASSWORD_FORM_FIELDS.password]: passwordRule,
    [PASSWORD_FORM_FIELDS.confirmPassword]: passwordRule,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Пароль не совпадает',
    path: [PASSWORD_FORM_FIELDS.confirmPassword],
  });

type TSchema = z.infer<typeof passwordSchema>;

type TProps = {
  width?: string | number;
};

export const PasswordCreate = ({ width }: TProps) => {
  const {
    control,
    formState: { errors },
    trigger,
  } = useFormContext<TSchema>();

  const passwordValue = useWatch({
    control,
    name: PASSWORD_FORM_FIELDS.password,
  });
  const passwordRepeatValue = useWatch({
    control,
    name: PASSWORD_FORM_FIELDS.confirmPassword,
  });

  const isHasError = !!errors?.[PASSWORD_FORM_FIELDS.password];

  return (
    <ColumnWrapper style={{ width }}>
      {/* this life hack helps to turn off autofill and suggest generate password */}
      <input type="password" autoComplete="new-password" style={{ display: 'none' }} />
      <Controller
        name={PASSWORD_FORM_FIELDS.password}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputPassword
            {...field}
            hasError={!!error}
            autoComplete="off"
            labelProps={{ label: PASSWORD }}
            onChange={(e) => {
              field.onChange(e.target.value.trim());
              trigger(PASSWORD_FORM_FIELDS.confirmPassword);
            }}
            errorMsg={error?.message}
          />
        )}
      />
      <Controller
        name={PASSWORD_FORM_FIELDS.confirmPassword}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <InputPassword
            {...field}
            disabled={!passwordValue || isHasError}
            labelProps={{ label: REPEAT_PASSWORD }}
            autoComplete="off"
            hasError={!!error}
            rule={[
              {
                isValid: !!passwordValue && !!passwordRepeatValue && passwordValue === passwordRepeatValue,
                label:
                  passwordValue && passwordRepeatValue
                    ? passwordValue === passwordRepeatValue
                      ? 'пароли совпадают'
                      : 'пароли не совпадают'
                    : PASSWORD_MATCH,
              },
            ]}
            onPaste={(e) => e.preventDefault()}
            onChange={(e) => {
              field.onChange(e.target.value.trim());
              if (passwordValue === e.target.value.trim()) {
                trigger(PASSWORD_FORM_FIELDS.confirmPassword);
              }
            }}
          />
        )}
      />
    </ColumnWrapper>
  );
};
