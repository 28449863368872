import { TAnimalDto, usePatchAnimalMutation } from '@/entities/animal-controller';
import { TribalBadgeSelect } from '@/features/badge-select';
import { CountrySelect } from '@/features/select';
import { Drag } from '@/shared/components';
import { BadgeInput, Checkbox, Datepicker, Input, Popup, TPopupProps, TextareaInput } from '@/shared/ui';
import { Flex } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Controller } from 'react-hook-form';
import { FORM_FIELDS, useAnimalInfoForm } from './hooks';

const TITLE = 'Редактировать информацию';
const LOAD_PEDIGREE = 'Загрузить фото родословной';
const DESCRIPTION = 'Описание';
const PET_NAME = 'Домашняя кличка';
const BIRTH_COUNTRY = 'Страна рождения';
const LIVE_COUNTRY = 'Страна проживания';
const DEATH_DAY = 'Дата смерти';
const CANCEL = 'Отменить';
const SAVE_CHANGES = 'Сохранить изменения';
const TRIBAL_CLASS = 'Племенной класс';
const HASHTAGS = 'Хэштеги';
const HASHTAGS_PLACEHOLDER = 'Напишите хештег и нажмите Enter';

type TProps = TPopupProps & {
  onCancel: () => void;
  animal: Partial<TAnimalDto>;
};

export const EditAnimalInformationModal = ({ animal, ...props }: TProps) => {
  const [updateAnimal, { isLoading: isSaving }] = usePatchAnimalMutation();

  const { handleSubmit, control, watch } = useAnimalInfoForm(animal);
  const watchShowDeathData = watch(FORM_FIELDS.isShowDeadDate);

  const disabledDeathDate = (current: Dayjs) => current.isBefore(animal?.dateBirth) || current.isAfter(dayjs());

  const onSaveAnimal = handleSubmit(async (data) => {
    try {
      if (!animal.id) {
        return;
      }
      const imageIds = animal.images?.map((img) => img.id) || [];
      await updateAnimal({
        id: animal.id,
        ...data,
        pedigree: data.pedigreeId?.[0]?.id,
        images: imageIds,
      }).unwrap();
      props.onCancel();
    } catch (e) {
      console.error(e);
    }
  });

  return (
    <Popup
      title={TITLE}
      size="MEDIUM"
      cancelText={CANCEL}
      okText={SAVE_CHANGES}
      onOk={onSaveAnimal}
      okButtonProps={{
        loading: isSaving,
      }}
      {...props}
    >
      <Flex className="w-full" vertical gap={24}>
        <Controller
          render={({ field: { onChange, value } }) => (
            <Drag
              labelProps={{
                label: LOAD_PEDIGREE,
                subtitle: 'Данная информация необходима для модерации и не будет видна другим пользователям',
              }}
              maxCount={1}
              fileList={value}
              handleChange={onChange}
            />
          )}
          control={control}
          name={FORM_FIELDS.pedigree}
        />
        <TextareaInput
          maxLength={256}
          labelProps={{ isHorizontal: false, label: DESCRIPTION }}
          controllerProps={{ control, name: FORM_FIELDS.description }}
          style={{ height: 144, resize: 'none' }}
        />
        <Input labelProps={{ label: PET_NAME }} controllerProps={{ control, name: FORM_FIELDS.shortName }} />
        <CountrySelect
          labelProps={{ label: BIRTH_COUNTRY }}
          controllerProps={{ control, name: FORM_FIELDS.birthCountryId }}
        />
        <CountrySelect
          labelProps={{ label: LIVE_COUNTRY }}
          controllerProps={{ control, name: FORM_FIELDS.liveCountryId }}
        />
        <Datepicker
          labelProps={{
            label: <Checkbox controlledProps={{ control, name: FORM_FIELDS.isShowDeadDate }}>{DEATH_DAY}</Checkbox>,
          }}
          disabled={!watchShowDeathData}
          disabledDate={disabledDeathDate}
          controllerProps={{ control, name: FORM_FIELDS.dateDead }}
        />
        <TribalBadgeSelect
          labelProps={{ label: TRIBAL_CLASS }}
          controllerProps={{ control, name: FORM_FIELDS.tribalTypes }}
        />
        <BadgeInput
          controllerProps={{ control, name: FORM_FIELDS.hashTags }}
          labelProps={{ label: HASHTAGS }}
          placeholder={HASHTAGS_PLACEHOLDER}
        />
      </Flex>
    </Popup>
  );
};
