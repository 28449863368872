import { OutlineClose, Success } from '@/app/icons';

import { device } from '@/app/theme/device';
import styled from 'styled-components';
import { Caption2 } from '../Typography';

export const TextValidation = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.neutral[4]};
`;

export const IconValidation = styled.div`
  @media ${device.tablet} {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
  }
`;
type TProps = {
  isValid: boolean;
  label: string;
  color?: string;
};

export const ValidationText = ({ label, isValid, color }: TProps) => {
  return (
    <TextValidation>
      <IconValidation>{isValid ? <Success /> : <OutlineClose color={color} />}</IconValidation>
      <Caption2>{label}</Caption2>
    </TextValidation>
  );
};
