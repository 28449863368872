import { TGoodDtoExt } from '@/entities/goods-controller';
import { useMedia } from '@/shared/hooks';
import { FlexPage } from '@/shared/containers';
import cn from 'classnames';
import {
  ButtonActions,
  GoodsDelivery,
  GoodsDescription,
  GoodsFooter,
  GoodsImagesView,
  GoodsSeller,
  GoodsTitle,
} from '@/features/goods';
import { Collapse } from '@/shared/components';
import { ShowroomGoodsList } from '@/features/show-room';

type TProps = {
  data: TGoodDtoExt;
  accountId?: string;
};

export const ProductGoods = ({ data, accountId }: TProps) => {
  const { isDesktop } = useMedia();

  const { city, canEdit, product } = data;

  if (!product) {
    return null;
  }

  const { images, description } = product;

  return (
    <>
      <FlexPage className={cn({ 'pb-[120px]': !canEdit }, { 'pb-[70px]': canEdit }, ' lg:pb-0 relative')}>
        <GoodsTitle data={data} />
        <GoodsImagesView images={images} />
        {isDesktop && <ButtonActions goodData={data} />}
        {city && <GoodsDelivery city={city.name} />}
        {accountId && <GoodsSeller accountId={accountId} good={data} />}
        <Collapse
          defaultActiveKey={1}
          items={[{ key: 1, label: 'Описание', children: <GoodsDescription description={description} /> }]}
        />
        <ShowroomGoodsList />
      </FlexPage>
      <GoodsFooter goodData={data} />
    </>
  );
};
