import { MODALS, resetGlobalModal, selectGlobalModal } from '@/app/store';
import {
  AddAccountNotificationModal,
  AddAnimalNotificationModal,
  AddLitterNotificationModal,
  AddNurseryNotificationModal,
  AddProductCardNotificationModal,
  AuthModal,
  ChangesSavedModal,
  DeleteGoodsCardNotificationModal,
  EditAccountNotificationModal,
  EditNurseryNotificationModal,
  EmailConfirmationModal,
  EmailResendModal,
  PostChangedModal,
  PostCreateModal,
  PostDeletedModal,
  RegistrationModal,
} from '@/features/modals';
import { LoginModal } from '@/widgets/login';
import { ForgetPassword } from '@/widgets/password';
import { useTypedDispatch, useTypedSelector } from '../hooks';

export const GlobalModals = () => {
  const { modal, text } = useTypedSelector(selectGlobalModal);
  const dispatch = useTypedDispatch();

  const closeModals = () => dispatch(resetGlobalModal());

  return (
    <>
      {modal === MODALS.EMAIL_CONFIRMATION && <EmailConfirmationModal onClose={closeModals} />}
      {modal === MODALS.CHANGES_SAVED && <ChangesSavedModal onClose={closeModals} />}
      {modal === MODALS.ADD_ANIMAL && <AddAnimalNotificationModal onClose={closeModals} />}
      {modal === MODALS.ADD_NURSERY_NOTIFICATION && <AddNurseryNotificationModal onClose={closeModals} text={text} />}
      {modal === MODALS.ADD_PRODUCT_CARD_NOTIFICATION && (
        <AddProductCardNotificationModal onClose={closeModals} text={text} />
      )}
      {modal === MODALS.ADD_LITTER_NOTIFICATION && <AddLitterNotificationModal onClose={closeModals} />}
      {modal === MODALS.ADD_ACCOUNT_NOTIFICATION && <AddAccountNotificationModal onClose={closeModals} text={text} />}
      {modal === MODALS.EDIT_NURSERY_NOTIFICATION && <EditNurseryNotificationModal onClose={closeModals} text={text} />}
      {modal === MODALS.EDIT_ACCOUNT_NOTIFICATION && <EditAccountNotificationModal onClose={closeModals} />}
      {modal === MODALS.EMAIL_RESEND && <EmailResendModal onClose={closeModals} />}
      {modal === MODALS.AUTH && <AuthModal onClose={closeModals} />}
      {modal === MODALS.POST_CREATED && <PostCreateModal onClose={closeModals} />}
      {modal === MODALS.POST_CHANGED && <PostChangedModal onClose={closeModals} />}
      {modal === MODALS.POST_DELETED && <PostDeletedModal onClose={closeModals} />}
      {modal === MODALS.SIGN_IN && <LoginModal onClose={closeModals} />}
      {modal === MODALS.PASSWORD_RESET && <ForgetPassword onClose={closeModals} />}
      {modal === MODALS.REGISTRATION && <RegistrationModal onClose={closeModals} />}
      {modal === MODALS.EDIT_GOODS_NOTIFICATION && <ChangesSavedModal onClose={closeModals} />}
      {modal === MODALS.DELETE_GOODS_NOTIFICATION && (
        <DeleteGoodsCardNotificationModal onClose={closeModals} text={text} />
      )}
    </>
  );
};
