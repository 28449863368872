import { NotificationPopup } from '@/shared/components';
import { Body2 } from '@/shared/ui';

type TProps = {
  onClose: () => void;
  text: string | null;
};

const TITLE = 'Изменения сохранены';

export const EditNurseryNotificationModal = ({ onClose, text }: TProps) => (
  <NotificationPopup open title={TITLE} onCancel={onClose}>
    <Body2>
      Информация о питомнике
      <span className="text-headline6 font-semibold mx-2">{text}</span>
      успешно обновлена
    </Body2>
  </NotificationPopup>
);
