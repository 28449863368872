import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { baseApi } from '@/app/core';

import { animalApi } from '@/entities/animal-controller';
import { postApi } from '@/entities/post-controller';
import { userApi } from '@/entities/user-controller';

import { commentApi } from '@/entities/comments-controller';
import { litterApi } from '@/entities/litters-controller';
import {
  ERROR_SLICE_NAME,
  errorReducer,
  GLOBAL_MODALS_SLICE_NAME,
  globalModalsReducer,
  RIGHT_SIDEBAR_SLICE_NAME,
  rightSidebarReducer,
  USER_SLICE_NAME,
  userReducer,
} from './slices';
import { goodsApi } from '@/entities/goods-controller';
import { productsApi } from '@/entities/products-controller';

export const store = configureStore({
  reducer: {
    [GLOBAL_MODALS_SLICE_NAME]: globalModalsReducer,
    [baseApi.reducerPath]: baseApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [animalApi.reducerPath]: animalApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [ERROR_SLICE_NAME]: errorReducer,
    [USER_SLICE_NAME]: userReducer,
    [RIGHT_SIDEBAR_SLICE_NAME]: rightSidebarReducer,
    [litterApi.reducerPath]: litterApi.reducer,
    [goodsApi.reducerPath]: goodsApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      baseApi.middleware,
      postApi.middleware,
      userApi.middleware,
      animalApi.middleware,
      commentApi.middleware,
      litterApi.middleware,
      goodsApi.middleware,
      productsApi.middleware,
    ]),
});

setupListeners(store.dispatch);
