import { z } from 'zod';
import { SPECIES } from '@/entities/species-controller';

export const MARKETPLACE_FILTER_FIELDS = {
  from: 'from',
  to: 'to',
  country: 'country',
  city: 'city',
  breed: 'breed',
  species: 'species',
} as const;

export const defaultValues = {
  [MARKETPLACE_FILTER_FIELDS.from]: '',
  [MARKETPLACE_FILTER_FIELDS.to]: '',
  [MARKETPLACE_FILTER_FIELDS.country]: null,
  [MARKETPLACE_FILTER_FIELDS.city]: null,
  [MARKETPLACE_FILTER_FIELDS.breed]: null,
  [MARKETPLACE_FILTER_FIELDS.species]: null,
};

export const marketplaceSchema = z.object({
  [MARKETPLACE_FILTER_FIELDS.from]: z.string(),
  [MARKETPLACE_FILTER_FIELDS.to]: z.string(),
  [MARKETPLACE_FILTER_FIELDS.country]: z.number().nullable(),
  [MARKETPLACE_FILTER_FIELDS.city]: z.number().nullable(),
  [MARKETPLACE_FILTER_FIELDS.breed]: z.number().nullable(),
  [MARKETPLACE_FILTER_FIELDS.species]: z.nativeEnum(SPECIES).nullable(),
});

export type TMarketplaceSchema = z.infer<typeof marketplaceSchema>;
