import { Loader } from '@/app/icons';
import animalAvatar from '@/app/icons/svg/animalAvatar.svg';
import { LINK_RACY } from '@/app/navigation';
import { TAnimalShortDto } from '@/entities/animal-controller';
import { ANIMAL_SEX, ANIMAL_STATUS, useGetSpeciesSexQuery } from '@/entities/species-controller';
import { Typography } from '@/shared/components';
import { queries } from '@/shared/utils';
import { Flex } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { NurseryAnimalCard } from '@/features/nursery';
import { EmptySection } from './EmptySection';

const PetCardsList = ({
  data,
  handleOpenModal,
  canEdit,
}: {
  data: TAnimalShortDto[];
  handleOpenModal: () => void;
  canEdit: boolean;
}) => {
  const navigate = useNavigate();

  const onAddCallback = canEdit ? handleOpenModal : undefined;

  return (
    <>
      <Flex gap={16} className="flex-wrap">
        {data.map(({ images, id, fullName, nursery }) => (
          <NurseryAnimalCard
            onClick={() => navigate(`${LINK_RACY}/${id}`)}
            key={id}
            image={{
              src: images.length ? images[0]?.url : animalAvatar,
              bottomInfo: { title: `${fullName} ${nursery.nameEn}`, subTitle: nursery.registrationSystem.abbreviation },
            }}
          />
        ))}
      </Flex>
      {onAddCallback && <EmptySection onAddButtonClick={onAddCallback} />}
    </>
  );
};

export const PetsListSection = ({
  data,
  onOpenModal,
  speciesID,
  canEdit,
}: {
  data: TAnimalShortDto[];
  onOpenModal: ({ animalSex, status }: { animalSex: ANIMAL_SEX; status: ANIMAL_STATUS }) => void;
  speciesID: number;
  canEdit: boolean;
}) => {
  const { data: sections = [], isFetching } = useGetSpeciesSexQuery(queries.equal('species.id', speciesID));

  const { neuter, tribalMale, tribalFemale } = useMemo(
    () =>
      data.reduce(
        (acc: { neuter: TAnimalShortDto[]; tribalFemale: TAnimalShortDto[]; tribalMale: TAnimalShortDto[] }, item) => {
          if (item.speciesSex.status.name === ANIMAL_STATUS.NEUTER) {
            acc.neuter.push(item);
          } else {
            item.speciesSex.sex === ANIMAL_SEX.FEMALE ? acc.tribalFemale.push(item) : acc.tribalMale.push(item);
          }
          return acc;
        },
        { neuter: [], tribalFemale: [], tribalMale: [] },
      ),
    [data],
  );

  const animalData = (status: ANIMAL_STATUS, sex: ANIMAL_SEX) =>
    status === ANIMAL_STATUS.NEUTER ? neuter : sex === ANIMAL_SEX.FEMALE ? tribalFemale : tribalMale;

  if (isFetching) {
    return <Loader />;
  }

  return (
    <Flex vertical gap={32}>
      {sections.map(({ id, status: { name }, sex: animalSex, category }) => (
        <Flex vertical gap={16} key={id}>
          <Typography type="18B">{category}</Typography>
          <PetCardsList
            canEdit={canEdit}
            data={animalData(name, animalSex)}
            handleOpenModal={() => onOpenModal({ animalSex, status: name })}
          />
        </Flex>
      ))}
    </Flex>
  );
};
