import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { useAddAccountMutation, useUpdateAccountMutation } from '@/entities/account-controller';
import { Input, Popup, RadioGroup } from '@/shared/ui';
import { Flex, RadioChangeEvent } from 'antd';
import { FormProvider } from 'react-hook-form';

import { useLazyCheckNurseryNameEnQuery } from '@/entities/nursery-controller';
import { useGetSpeciesQuery } from '@/entities/species-controller';
import { TAccountDto, userApi } from '@/entities/user-controller';
import { AccountUploadPhoto } from '@/features/account';
import { BreedsSelect, SystemRegistrationSelect } from '@/features/select';
import { NAME_NURSERY_EXIST_MESSAGE, nurseryEngRule } from '@/shared/validation';
import { ADD_NURSERY_ACCOUNT_FORM_FIELDS, useNurseryAccountForm } from './hooks';

type TProps = {
  onCancel: () => void;
  accountData: TAccountDto | null;
};

const NAME_NURSERY_ENG = 'Название питомника (ENG)';

export const AddNurseryAccountModal = ({ onCancel, accountData }: TProps) => {
  const formMethods = useNurseryAccountForm(accountData);
  const [checkNurseryNameEn] = useLazyCheckNurseryNameEnQuery();
  const { handleSubmit, watch, setValue, setError, clearErrors } = formMethods;

  const dispatch = useTypedDispatch();

  const [postAccount, { isLoading: isCreating }] = useAddAccountMutation();
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();

  const species = watch(ADD_NURSERY_ACCOUNT_FORM_FIELDS.speciesId);

  const { data: speciesOptions = [] } = useGetSpeciesQuery();

  const onBlurName = async (nurseryNameEn: string) => {
    try {
      const isAccountNameValid = nurseryEngRule().safeParse(nurseryNameEn).success;
      if (isAccountNameValid) {
        const isNameExists = await checkNurseryNameEn(nurseryNameEn).unwrap();
        if (isNameExists) {
          setError(ADD_NURSERY_ACCOUNT_FORM_FIELDS.nurseryNameEn, {
            message: NAME_NURSERY_EXIST_MESSAGE,
          });
        } else {
          clearErrors(ADD_NURSERY_ACCOUNT_FORM_FIELDS.nurseryNameEn);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFormSubmit = handleSubmit(async (data) => {
    const { avatar, nurseryNameEn } = data;
    try {
      const payload = {
        ...data,
        avatar: avatar?.id,
        breeder: true,
        name: nurseryNameEn,
      };

      accountData
        ? await updateAccount({ id: accountData.id, data: payload }).unwrap()
        : await postAccount(payload).unwrap();
      dispatch(setGlobalModal({ modal: MODALS.EDIT_NURSERY_NOTIFICATION, text: nurseryNameEn }));
      dispatch(userApi.util.invalidateTags(['USER_ACCOUNTS']));
      onCancel();
    } catch (e) {
      console.error(e);
    }
  });

  const handleChangeSpecies = (e: RadioChangeEvent) => {
    const { value } = e.target;
    setValue(ADD_NURSERY_ACCOUNT_FORM_FIELDS.speciesId, value);
    setValue(ADD_NURSERY_ACCOUNT_FORM_FIELDS.breedId, 0);
    setValue(ADD_NURSERY_ACCOUNT_FORM_FIELDS.registrationSystemId, 0);
  };

  return (
    <Popup
      open
      size="MEDIUM"
      title={accountData ? 'Редактировать питомник' : 'Добавить питомник'}
      okText={accountData ? 'Сохранить' : 'Добавить'}
      onOk={onFormSubmit}
      okButtonProps={{ loading: isCreating || isUpdating }}
      onCancel={onCancel}
      cancelText="Отменить"
    >
      <FormProvider {...formMethods}>
        <Flex vertical gap={24} className="w-full overflow-auto">
          <Input
            placeholder="Введите название питомника"
            controllerProps={{ name: ADD_NURSERY_ACCOUNT_FORM_FIELDS.nurseryNameEn }}
            labelProps={{ label: NAME_NURSERY_ENG }}
            onBlur={(e) => onBlurName(e.target.value)}
          />
          <RadioGroup
            labelProps={{ label: 'Животные для разведения' }}
            className="w-[inherit]"
            options={speciesOptions}
            controllerProps={{ name: ADD_NURSERY_ACCOUNT_FORM_FIELDS.speciesId }}
            onChange={handleChangeSpecies}
            disabled={!!accountData}
          />
          <BreedsSelect controllerProps={{ name: ADD_NURSERY_ACCOUNT_FORM_FIELDS.breedId }} speciesId={species} />
          <SystemRegistrationSelect
            controllerProps={{ name: ADD_NURSERY_ACCOUNT_FORM_FIELDS.registrationSystemId }}
            speciesId={species}
          />
          <AccountUploadPhoto />
        </Flex>
      </FormProvider>
    </Popup>
  );
};
