import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { MODALS, selectCurrentAccountId, setGlobalModal, useTypedDispatch, useTypedSelector } from '@/app/store';
import {
  postMarketplaceList,
  TGoodDto,
  TGoodRequestDto,
  usePostGoodsMutation,
  usePutGoodsMutation,
} from '@/entities/goods-controller';
import { animalApi } from '@/entities/animal-controller';
import { useParams } from 'react-router-dom';
import { getDefaultValues, goodsSchema, isProduct, TAddGoodsSchema, TProductGoodSchema } from './useShowroom.helpers';
import { useGetAccountQuery } from '@/entities/user-controller';
import { usePostProductMutation, usePutProductMutation } from '@/entities/products-controller';
import { useLazyGetCategoriesByMainQuery } from '@/entities/good-category-controller';

export const useShowroomForm = ({
  defaultValues,
  onClose,
  data,
}: {
  defaultValues?: Partial<TAddGoodsSchema>;
  onClose: () => void;
  data?: TGoodDto;
}) => {
  const { catalogId } = useParams();
  const dispatch = useTypedDispatch();
  const accountId = useTypedSelector(selectCurrentAccountId);
  const { data: account } = useGetAccountQuery(String(accountId), { skip: !accountId });
  const [createGoods, { isLoading: isPublishingGood }] = usePostGoodsMutation();
  const [updateGoods, { isLoading: isUpdatingGood }] = usePutGoodsMutation();
  const [createProduct, { isLoading: isCreatingProduct }] = usePostProductMutation();
  const [updateProduct, { isLoading: isUpdatingProduct }] = usePutProductMutation();

  const [getCategories] = useLazyGetCategoriesByMainQuery();

  const getAsyncDefaultValues = async () => {
    const staticFields = { ...getDefaultValues({ data, defaultValues, species: account?.nursery?.species.id }) };
    if (data?.product) {
      const { parents, id } = await getCategories(data.category.id).unwrap();
      const reversedParents = parents.map((item) => ({ value: item.id })).reverse();
      return { ...staticFields, categories: [...reversedParents, { value: id }] };
    }

    return staticFields;
  };

  const formMethods = useForm<TAddGoodsSchema>({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    defaultValues: getAsyncDefaultValues,
    resolver: zodResolver(goodsSchema),
  });

  const { handleSubmit } = formMethods;

  const onCreateUpdateProduct = (productData: TProductGoodSchema) => {
    const payload = { ...productData, images: productData.images.map((item) => item.id) };
    return data?.product
      ? updateProduct({ id: data.product.id, ...payload }).unwrap()
      : createProduct(payload).unwrap();
  };

  const onCreateUpdateGoods = (payload: TGoodRequestDto) =>
    data ? updateGoods({ id: data.id, ...payload }).unwrap() : createGoods(payload).unwrap();

  const onFinishSaving = (response: TGoodDto) => {
    const { animal, account, category, product } = response;
    if (!data) {
      if (category.id === Number(catalogId) || !catalogId) {
        dispatch(postMarketplaceList(response, catalogId));
      }
      if (animal) {
        dispatch(
          setGlobalModal({
            modal: MODALS.ADD_PRODUCT_CARD_NOTIFICATION,
            text: `${animal.fullName} ${account.name}`,
          }),
        );
        dispatch(animalApi.util.invalidateTags([{ type: 'ANIMAL', id: animal.id }]));
      }
      if (product) {
        dispatch(
          setGlobalModal({
            modal: MODALS.ADD_PRODUCT_CARD_NOTIFICATION,
            text: `${product.name}`,
          }),
        );
      }
    } else {
      dispatch(setGlobalModal(MODALS.EDIT_GOODS_NOTIFICATION));
    }

    onClose();
  };

  const onFormSubmit = handleSubmit(async (goodsData) => {
    try {
      const category = goodsData.categories.at(-1)?.value;
      if (!category) {
        return;
      }
      const price = parseFloat(goodsData.price.replace(',', '.'));
      const phone = goodsData.phone.value;
      const payload: TGoodRequestDto = { ...goodsData, category, phone, price };

      if (isProduct(goodsData)) {
        const { id } = await onCreateUpdateProduct(goodsData);
        payload.product = id;
      }

      const response = await onCreateUpdateGoods(payload);
      onFinishSaving({ ...response, canEdit: true });
    } catch (e) {
      console.error('Failed to save changes:', e);
    }
  });

  return {
    isLoading: isUpdatingGood || isPublishingGood || isCreatingProduct || isUpdatingProduct,
    onFormSubmit,
    formMethods,
  };
};
