import React from 'react';
import { MarketplaceGoodsList } from '@/features/marketplace';

export const MarketplaceRecommendations = () => {
  // const accountId = useSelector(selectCurrentAccountId);

  return <MarketplaceGoodsList />;
  // return accountId ? <MarketplaceAuthorizedGoodsList /> : <MarketplaceGoodsList />;
  // временное использование апихи со всеми товарами
  // пока не появится спека для рекомендованных товаров
};
