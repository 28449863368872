import { Image as AntImage, ImageProps } from 'antd';
import cn from 'classnames';

type TProps = ImageProps;

export const Image = ({ className, ...props }: TProps) => {
  const { onClick } = props;
  return (
    <AntImage
      className={cn('rounded-xxs object-cover', { 'cursor-pointer': !!onClick }, className)}
      preview={false}
      {...props}
    />
  );
};
