import { PlusButtonIcon } from '@/app/icons';
import { PublishMarketplaceButton } from '@/features/buttons';
import { ShowroomGoodsList } from '@/features/show-room';
import { FlexPage } from '@/shared/containers';
import { useLocation } from 'react-router-dom';
import { HOME_PATHS } from '@/app/navigation';

export const ShowRoomList = () => {
  const { pathname } = useLocation();

  return (
    <FlexPage>
      {pathname === HOME_PATHS.showroom && (
        <PublishMarketplaceButton
          buttonProps={{
            text: 'Выставить на продажу',
            className: 'w-full md:w-auto',
            icon: <PlusButtonIcon width={16} height={16} />,
          }}
        />
      )}
      <ShowroomGoodsList />
    </FlexPage>
  );
};
