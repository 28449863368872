import { SVGProps } from 'react';

export const EditTwoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.0553 3.00002L4.21366 10.2417C3.95533 10.5167 3.70533 11.0584 3.65533 11.4334L3.347 14.1333C3.23866 15.1083 3.93866 15.775 4.90533 15.6084L7.58866 15.15C7.96366 15.0834 8.48866 14.8084 8.747 14.525L15.5887 7.28335C16.772 6.03335 17.3053 4.60835 15.4637 2.86668C13.6303 1.14168 12.2387 1.75002 11.0553 3.00002Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.90625 4.21094C10.2646 6.51094 12.1312 8.26927 14.4479 8.5026"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.5 18.332H17.5"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
