import { ReactComponent as CloseIcon } from '@/app/icons/svg/close-icon.svg';
import { ReactComponent as TrashIcon } from '@/app/icons/svg/trash-icon.svg';
import { ReactComponent as UploadIcon } from '@/app/icons/svg/upload-icon.svg';
import { device } from '@/app/theme/device';
import { Label, Slider, Image as UIImage } from '@/shared/components';
import { LabelWrapper, TLabelProps } from '@/shared/ui';
import { Flex, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile } from 'antd/es/upload';
import { useState } from 'react';
import Cropper, { Area } from 'react-easy-crop';
import styled, { css } from 'styled-components';

const StyledDrag = styled.div<{ error?: boolean }>`
  .ant-upload-wrapper .ant-upload {
    border: 1px solid transparent;

    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .ant-upload-hint {
      max-width: 500px;

      .active-text {
        color: ${({ theme }) => theme.palette.primary[1]};
      }
    }
  }

  ${({ error }) =>
    error &&
    css`
      .ant-upload-wrapper .ant-upload.ant-upload-drag {
        border: 1px solid ${({ theme }) => theme.palette.primary[3]};

        .ant-upload-drag-icon {
          svg path {
            stroke: ${({ theme }) => theme.palette.primary[3]};
          }
        }

        .ant-upload-hint {
          color: ${({ theme }) => theme.palette.primary[3]};

          .active-text {
            color: unset;
          }
        }

        &:hover {
          border: 1px solid ${({ theme }) => theme.palette.primary[3]};
        }
      }
    `}
`;

const StyledFileItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.neutral[6]};
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  .close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    .close {
      display: block;
    }
  }
`;

const DragContent = styled(Flex)`
  gap: 8px;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet}, ${device.mobile} {
    flex-direction: row;
  }
`;

const CropperContainer = styled.div<{ cropHeight: number }>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;

  @media ${device.tablet}, ${device.mobile} {
    height: 150px;
  }
`;

export enum LIST_TYPE {
  DEFAULT,
  IMAGE,
}

export type TDragFile = Omit<UploadFile, 'url'> & {
  id: string;
  url: string;
  croppedUrl?: string;
  originFileObj?: RcFile;
};

type TProps = {
  error?: boolean;
  handleChange: (value: TDragFile[]) => void;
  fileList?: TDragFile[];
  labelProps?: TLabelProps;
  typeList?: LIST_TYPE;
  onCropComplete?: (croppedArea: Area, croppedAreaPixels: Area, uid: string) => void;
} & Omit<UploadProps, 'fileList'>;

export const DragWithCrop = ({
  error,
  handleChange,
  fileList = [],
  maxCount,
  labelProps,
  typeList = LIST_TYPE.DEFAULT,
  onCropComplete,
  ...props
}: TProps) => {
  const { Dragger } = Upload;
  const [uploadedFiles, setUploadedFiles] = useState<TDragFile[]>(fileList);
  const [cropStates, setCropStates] = useState<Record<string, { crop: { x: number; y: number }; zoom: number }>>({});

  const onRemove = (value: TDragFile) => {
    setUploadedFiles((prev) => prev.filter((item) => item.uid !== value.uid));
    handleChange(uploadedFiles.filter((item) => item.uid !== value.uid));
  };

  const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area, uid: string) => {
    if (onCropComplete) {
      onCropComplete(croppedArea, croppedAreaPixels, uid);
    }
  };

  const beforeUpload = (fileRequest: RcFile, list: RcFile[]) => {
    if (fileRequest.uid !== list[list.length - 1].uid) {
      return false;
    }

    const getList = () => {
      if (maxCount) {
        const diff = maxCount - uploadedFiles.length;
        if (diff < list.length) {
          return list.splice(0, diff);
        }
      }
      return list;
    };

    const files = getList();
    const newFiles: TDragFile[] = files.map((file) => ({
      uid: file.uid,
      id: file.uid,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      originFileObj: file,
    }));

    setUploadedFiles((prev) => [...prev, ...newFiles]);
    handleChange([...uploadedFiles, ...newFiles]);
    return false;
  };

  const getCropSize = () => {
    if (uploadedFiles.length === 0 || !uploadedFiles[0].url) {
      return { width: 0, height: 0 };
    }

    const img = new Image();
    img.src = uploadedFiles[0].url;

    const originalWidth = img.width;
    const originalHeight = img.height;

    const isTabletOrMobile = window.matchMedia(device.tablet).matches || window.matchMedia(device.mobile).matches;

    const scaleFactor = isTabletOrMobile
      ? originalWidth > 800 || originalHeight > 800
        ? 0.1
        : 0.4
      : originalWidth > 800 || originalHeight > 800
      ? 0.2
      : 0.8;

    return {
      width: originalWidth * scaleFactor,
      height: originalHeight * scaleFactor,
    };
  };

  const renderCropping = () => {
    const cropSize = getCropSize();

    return (
      <Slider arrows={false}>
        {uploadedFiles.map((file) => (
          <div key={file.uid}>
            <CropperContainer cropHeight={cropSize.height}>
              <Cropper
                image={file.url}
                crop={cropStates[file.uid]?.crop || { x: 0, y: 0 }}
                zoom={cropStates[file.uid]?.zoom || 1}
                aspect={cropSize.width / cropSize.height}
                cropSize={{ width: cropSize.width, height: cropSize.height }}
                onCropChange={(crop) => setCropStates((prev) => ({ ...prev, [file.uid]: { ...prev[file.uid], crop } }))}
                onZoomChange={(zoom) => setCropStates((prev) => ({ ...prev, [file.uid]: { ...prev[file.uid], zoom } }))}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  handleCropComplete(croppedArea, croppedAreaPixels, file.uid)
                }
              />
            </CropperContainer>
          </div>
        ))}
      </Slider>
    );
  };

  return (
    <StyledDrag error={error}>
      <LabelWrapper {...labelProps} isHorizontal={false}>
        <Flex vertical gap={5} className="w-full">
          <Dragger
            multiple
            showUploadList={false}
            beforeUpload={beforeUpload}
            maxCount={maxCount}
            disabled={uploadedFiles.length === maxCount}
            fileList={uploadedFiles}
            {...props}
          >
            <DragContent>
              <p className="ant-upload-drag-icon">
                <UploadIcon />
              </p>
              <p className="ant-upload-hint">
                Перетащите или <span className="active-text">выберите</span> файл для загрузки. Разрешенные файлы: JPG,
                JPEG, PNG
              </p>
            </DragContent>
          </Dragger>
          {!!maxCount && (
            <Label $isHorizontal $error={error}>
              Загружено {uploadedFiles.length} из {maxCount}
            </Label>
          )}
          {typeList === LIST_TYPE.DEFAULT &&
            uploadedFiles.map((file) => (
              <StyledFileItem key={file.uid}>
                {file.name || file.url}
                <TrashIcon className="min-h-[16px] min-w-[16px]" onClick={() => onRemove(file)} />
              </StyledFileItem>
            ))}
          {typeList === LIST_TYPE.IMAGE && (
            <Flex gap={16} style={{ overflow: 'auto' }}>
              {uploadedFiles.map((file) => (
                <ImageWrapper key={file.uid}>
                  <UIImage scale={false} width={65} height={65} src={file.url} />
                  <CloseIcon className="close" onClick={() => onRemove(file)} />
                </ImageWrapper>
              ))}
            </Flex>
          )}
          {typeList === LIST_TYPE.IMAGE && renderCropping()}
        </Flex>
      </LabelWrapper>
    </StyledDrag>
  );
};
