import { Body3, Button, LabelWrapper, Select } from '@/shared/ui';
import { SHOWROOM_FORM_FIELDS, TAddGoodsSchema } from '@/widgets/show-room/add-showroom-product-modal/hooks';
import { Flex } from 'antd';
import { InfoIcon } from '@/app/icons';
import { TAnimalInfoDto, TAnimalShortDto, useGetAnimalsQuery } from '@/entities/animal-controller';
import { LINK_RACY } from '@/app/navigation';
import { useLocation } from 'react-router-dom';
import { queries } from '@/shared/utils';
import { AddAnimalPopup } from '@/widgets/show-room/add-showroom-product-modal/components';
import { TAnimalFormProps } from '@/widgets/animal/animal-crud-popup/hooks';
import { TSelectOption } from '@/shared/common';
import { useModal } from '@/shared/hooks';
import { useFormContext } from 'react-hook-form';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';

type TProps = {
  animal?: TAnimalInfoDto;
};

export const AnimalInfo = ({ animal }: TProps) => {
  const accountId = useTypedSelector(selectCurrentAccountId);

  const { pathname } = useLocation();
  const isAnimalSelectDisabled = pathname.includes(LINK_RACY) || !!animal;
  const { isOpen: isOpenAddAnimal, open: openAddAnimal, close: closeAddAnimal } = useModal();
  const { setValue } = useFormContext<TAddGoodsSchema>();

  const animalQuery = queries.equal('owner.id', String(accountId));
  const {
    data: animals,
    refetch,
    isFetching,
  } = useGetAnimalsQuery(
    { query: animalQuery },
    {
      skip: !!animal,
      selectFromResult: ({ data, ...rest }) => ({
        data: data?.filter(({ canPublishToMarket }) => canPublishToMarket),
        ...rest,
      }),
    },
  );

  const handleCreateAnimal: TAnimalFormProps['onSubmitFinish'] = async (data) => {
    closeAddAnimal();

    await refetch();
    const { id, liveCountryId, cityId } = data;
    setValue(SHOWROOM_FORM_FIELDS.animal, id, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.country, liveCountryId, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.city, cityId, { shouldValidate: true });
  };

  const handleChangeAnimal = (
    id: string,
    animal: (TAnimalShortDto & TSelectOption) | (TAnimalShortDto & TSelectOption)[],
  ) => {
    if (!Array.isArray(animal)) {
      setValue(SHOWROOM_FORM_FIELDS.animal, id, { shouldValidate: true });
      const { countryId, cityId } = animal;
      countryId && setValue(SHOWROOM_FORM_FIELDS.country, countryId, { shouldValidate: true });
      cityId && setValue(SHOWROOM_FORM_FIELDS.city, cityId, { shouldValidate: true });
    }
  };

  return (
    <LabelWrapper label="Кличка" contentClassName="flex flex-col gap-xs">
      <Select
        labelRender={animal && (() => `${animal.fullName} ${animal.nursery.nameEn}`)}
        onChange={handleChangeAnimal}
        loading={isFetching}
        options={animals}
        disabled={isAnimalSelectDisabled}
        controllerProps={{ name: SHOWROOM_FORM_FIELDS.animal }}
      />
      {!isAnimalSelectDisabled && (
        <>
          <Flex className="text-neutral4" gap={8}>
            <InfoIcon className="min-w-xs mt-[4px]" />
            <Body3>Если нужного питомца еще нет в системе, пожалуйста, добавьте его.</Body3>
          </Flex>
          <Button
            className="w-full"
            onClick={() => {
              openAddAnimal();
            }}
          >
            Добавить питомца
          </Button>
        </>
      )}
      {isOpenAddAnimal && (
        <AddAnimalPopup onSubmitFinish={handleCreateAnimal} open={isOpenAddAnimal} onCancel={closeAddAnimal} />
      )}
    </LabelWrapper>
  );
};
