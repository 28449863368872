import { Body2, Card, List } from '@/shared/ui';
import { Flex } from 'antd';
import { ArrowCircleDown } from '@/app/icons';
import { useHistory, useMedia } from '@/shared/hooks';
import cn from 'classnames';
import { useGetCategoriesByMainQuery } from '@/entities/good-category-controller';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { useState } from 'react';

export const ChildrenCategories = ({
  data,
}: {
  data: { id: number; title: string; src: string; alt: string; onClick: () => void }[];
}) => {
  const { isMobile } = useMedia();
  const [openCategoryId, setOpenCategoryId] = useState<number | null>(null);

  const isCategoryOpen = (id: number) => openCategoryId === id;

  const ChildCard = ({
    id,
    title,
    src,
    alt,
    onClick,
  }: {
    id: number;
    title: string;
    src: string;
    alt: string;
    onClick: () => void;
  }) => {
    const navigate = useHistory();

    const { data: categories } = useGetCategoriesByMainQuery(id);
    const children = categories?.children;

    return (
      <>
        {!isCategoryOpen(id) && (
          <Card
            onClick={onClick}
            hoverable
            className="rounded-xxs"
            metaProps={{
              className: 'text-center bg-neutral9',
              description: (
                <Flex align="center" className="p-xxs">
                  <img width={140} height={140} className="rounded-xxs" src={src} alt={alt} />
                  <Body2 className={cn('font-semibold mx-auto')}>{title}</Body2>
                  {!!categories?.children.length && (
                    <ArrowCircleDown
                      onClick={(event) => {
                        event.stopPropagation();
                        setOpenCategoryId(id);
                      }}
                    />
                  )}
                </Flex>
              ),
            }}
          />
        )}
        {isCategoryOpen(id) && (
          <div className="absolute left-[10px] right-[10px] top-[-16px] z-[100]">
            <Card
              onClick={onClick}
              hoverable
              className="rounded-xxs"
              metaProps={{
                className: 'text-center bg-neutral9',
                description: (
                  <Flex className="p-xxs" vertical gap={10}>
                    <Flex align="center">
                      <img width={80} height={80} className="rounded-xxs" src={src} alt={alt} />
                      <Body2 className={cn('font-semibold ml-xs')}>{title}</Body2>
                      {!!children?.length && (
                        <ArrowCircleDown
                          onClick={(event) => {
                            event.stopPropagation();
                            setOpenCategoryId(null);
                          }}
                          className={cn('rotate-180 ml-auto')}
                        />
                      )}
                    </Flex>
                    {isCategoryOpen(id) && !!children?.length && (
                      <Flex className="pl-[108px]" vertical gap={16}>
                        {children.map((item) => (
                          <Body2
                            onClick={(event) => {
                              event.stopPropagation();
                              navigate(`${MARKETPLACE_PATHS.catalog}/${item.id}`);
                            }}
                            className="text-start rounded-xxs py-[4px] px-xxs hover:bg-neutral7"
                            key={item.id}
                          >
                            {item.name}
                          </Body2>
                        ))}
                      </Flex>
                    )}
                  </Flex>
                ),
              }}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <List
      grid={isMobile ? { column: 1 } : { gutter: 20, column: 2 }}
      dataSource={data}
      customItem={(props) => <ChildCard {...props} />}
    />
  );
};
