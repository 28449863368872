import { MyGoodsButton } from '@/features/buttons/MyGoodsButton';
import { PublishMarketplaceButton } from '@/features/buttons';
import { PlusButtonIcon } from '@/app/icons';
import { TAccountDto } from '@/entities/user-controller';

export const Actions = ({ account }: { account: TAccountDto }) => {
  return (
    <>
      <MyGoodsButton />
      <PublishMarketplaceButton
        animal={{
          species: account?.nursery?.species,
        }}
        buttonProps={{
          className: 'w-full lg:w-auto',
          text: 'Подать объявление',
          icon: <PlusButtonIcon width={16} height={16} className="border-[1.5px] rounded border-white" />,
        }}
      />
    </>
  );
};
