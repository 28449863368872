import { SVGProps } from 'react';

export const FilterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 6.75H16C15.8661 6.75 15.75 6.63386 15.75 6.5C15.75 6.36614 15.8661 6.25 16 6.25H22C22.1339 6.25 22.25 6.36614 22.25 6.5C22.25 6.63386 22.1339 6.75 22 6.75Z"
      fill="#292D32"
      stroke="#212029"
    />
    <path
      d="M6 6.75H2C1.86614 6.75 1.75 6.63386 1.75 6.5C1.75 6.36614 1.86614 6.25 2 6.25H6C6.13386 6.25 6.25 6.36614 6.25 6.5C6.25 6.63386 6.13386 6.75 6 6.75Z"
      fill="#292D32"
      stroke="#212029"
    />
    <path
      d="M10 10.25C7.93614 10.25 6.25 8.56386 6.25 6.5C6.25 4.43614 7.93614 2.75 10 2.75C12.0639 2.75 13.75 4.43614 13.75 6.5C13.75 8.56386 12.0639 10.25 10 10.25ZM10 3.25C8.20386 3.25 6.75 4.70386 6.75 6.5C6.75 8.29614 8.20386 9.75 10 9.75C11.7961 9.75 13.25 8.29614 13.25 6.5C13.25 4.70386 11.7961 3.25 10 3.25Z"
      fill="#292D32"
      stroke="#212029"
    />
    <mask id="path-4-inside-1_17814_67265" fill="white">
      <path d="M22 18.25H18C17.59 18.25 17.25 17.91 17.25 17.5C17.25 17.09 17.59 16.75 18 16.75H22C22.41 16.75 22.75 17.09 22.75 17.5C22.75 17.91 22.41 18.25 22 18.25Z" />
    </mask>
    <path
      d="M22 18.25H18C17.59 18.25 17.25 17.91 17.25 17.5C17.25 17.09 17.59 16.75 18 16.75H22C22.41 16.75 22.75 17.09 22.75 17.5C22.75 17.91 22.41 18.25 22 18.25Z"
      fill="#292D32"
    />
    <path
      d="M22 16.75H18V19.75H22V16.75ZM18 16.75C18.4184 16.75 18.75 17.0816 18.75 17.5H15.75C15.75 18.7384 16.7616 19.75 18 19.75V16.75ZM18.75 17.5C18.75 17.9184 18.4184 18.25 18 18.25V15.25C16.7616 15.25 15.75 16.2616 15.75 17.5H18.75ZM18 18.25H22V15.25H18V18.25ZM22 18.25C21.5816 18.25 21.25 17.9184 21.25 17.5H24.25C24.25 16.2616 23.2384 15.25 22 15.25V18.25ZM21.25 17.5C21.25 17.0816 21.5816 16.75 22 16.75V19.75C23.2384 19.75 24.25 18.7384 24.25 17.5H21.25Z"
      fill="#333240"
      mask="url(#path-4-inside-1_17814_67265)"
    />
    <path
      d="M8 18.25H2C1.59 18.25 1.25 17.91 1.25 17.5C1.25 17.09 1.59 16.75 2 16.75H8C8.41 16.75 8.75 17.09 8.75 17.5C8.75 17.91 8.41 18.25 8 18.25Z"
      fill="#292D32"
    />
    <path
      d="M14 21.75C11.66 21.75 9.75 19.84 9.75 17.5C9.75 15.16 11.66 13.25 14 13.25C16.34 13.25 18.25 15.16 18.25 17.5C18.25 19.84 16.34 21.75 14 21.75ZM14 14.75C12.48 14.75 11.25 15.98 11.25 17.5C11.25 19.02 12.48 20.25 14 20.25C15.52 20.25 16.75 19.02 16.75 17.5C16.75 15.98 15.52 14.75 14 14.75Z"
      fill="#292D32"
    />
  </svg>
);
