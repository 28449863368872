import { TGoodDto } from '@/entities/goods-controller';
import { priceConverter } from '@/shared/utils';

import { z } from 'zod';
import { emailRule, selectRule, stringRule } from '@/shared/validation';
import { GOODS_CATEGORY } from '@/entities/good-category-controller';
import { SPECIES } from '@/entities/species-controller';

export const SHOWROOM_FORM_FIELDS = {
  phone: 'phone',
  animal: 'animal',
  price: 'price',
  currency: 'currency',
  categories: 'categories',
  email: 'email',
  country: 'country',
  city: 'city',
  delivery: 'delivery',
  name: 'name',
  description: 'description',
  images: 'images',
  isProduct: 'isProduct',
} as const;

const productSchema = z.object({
  [SHOWROOM_FORM_FIELDS.name]: stringRule(),
  [SHOWROOM_FORM_FIELDS.description]: z.string().min(1).max(5000),
  [SHOWROOM_FORM_FIELDS.images]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array(),
  [SHOWROOM_FORM_FIELDS.isProduct]: z.literal(true),
});

const animalSchema = z.object({
  [SHOWROOM_FORM_FIELDS.animal]: stringRule('Необходимо выбрать питомца'),
  [SHOWROOM_FORM_FIELDS.isProduct]: z.literal(false),
});

const defaultSchema = z.object({
  [SHOWROOM_FORM_FIELDS.phone]: z
    .object({
      value: z.string(),
      isValid: z.boolean(),
    })
    .refine(({ isValid }) => isValid, {
      path: [SHOWROOM_FORM_FIELDS.phone],
    }),
  [SHOWROOM_FORM_FIELDS.email]: emailRule,
  [SHOWROOM_FORM_FIELDS.categories]: z.array(z.object({ value: z.number() })),
  [SHOWROOM_FORM_FIELDS.country]: selectRule(),
  [SHOWROOM_FORM_FIELDS.city]: selectRule(),
  [SHOWROOM_FORM_FIELDS.delivery]: z.boolean(),
  [SHOWROOM_FORM_FIELDS.price]: stringRule(),
  [SHOWROOM_FORM_FIELDS.currency]: selectRule(),
});

const schemaCond = z.discriminatedUnion(SHOWROOM_FORM_FIELDS.isProduct, [productSchema, animalSchema]);
export const goodsSchema = z.intersection(schemaCond, defaultSchema);

export type TProductGoodSchema = z.infer<typeof productSchema>;
export type TAnimalGoodSchema = z.infer<typeof animalSchema>;
export type TAddGoodsSchema = z.infer<typeof goodsSchema>;

export const isProduct = (data: TAddGoodsSchema): data is TAddGoodsSchema & TProductGoodSchema => data.isProduct;
export const isAnimal = (data: TAddGoodsSchema): data is TAddGoodsSchema & TAnimalGoodSchema => !data.isProduct;

export const getDefaultValues = ({
  data,
  defaultValues = {},
  species,
}: {
  data?: TGoodDto;
  defaultValues?: Partial<TAddGoodsSchema>;
  species?: SPECIES;
}) => {
  if (!data) {
    const values = {
      [SHOWROOM_FORM_FIELDS.delivery]: false,
      [SHOWROOM_FORM_FIELDS.currency]: 1,
      [SHOWROOM_FORM_FIELDS.isProduct]: !species,
      [SHOWROOM_FORM_FIELDS.categories]: [{}],
      [SHOWROOM_FORM_FIELDS.name]: '',
      ...defaultValues,
    };

    if (species) {
      values[SHOWROOM_FORM_FIELDS.categories] =
        species === SPECIES.CATS ? [{ value: GOODS_CATEGORY.CATS }] : [{ value: GOODS_CATEGORY.DOGS }];
    }

    return values;
  }

  const { animal, product } = data;

  const values = {
    [SHOWROOM_FORM_FIELDS.phone]: { value: data.phone, isValid: true },
    [SHOWROOM_FORM_FIELDS.email]: data.email,
    [SHOWROOM_FORM_FIELDS.price]: priceConverter(data.price),
    [SHOWROOM_FORM_FIELDS.currency]: data.currency.id,
    [SHOWROOM_FORM_FIELDS.country]: data.country.id,
    [SHOWROOM_FORM_FIELDS.city]: data.city.id,
    [SHOWROOM_FORM_FIELDS.delivery]: data.delivery,
    [SHOWROOM_FORM_FIELDS.categories]: [{ value: data.category.id }],
    [SHOWROOM_FORM_FIELDS.isProduct]: !!product,
  };

  if (product) {
    return {
      [SHOWROOM_FORM_FIELDS.name]: product.name,
      [SHOWROOM_FORM_FIELDS.description]: product.description,
      [SHOWROOM_FORM_FIELDS.images]: product.images,
      ...values,
    };
  }

  if (animal) {
    return {
      [SHOWROOM_FORM_FIELDS.animal]: animal.id,
      ...values,
    };
  }

  return defaultValues;
};
