import { TGoodDtoExt } from '@/entities/goods-controller';
import { FlexPage } from '@/shared/containers';
import {
  GoodsCharacteristics,
  GoodsDelivery,
  GoodsDescription,
  GoodsFooter,
  GoodsImagesView,
  GoodsSeller,
  GoodsTitle,
  ButtonActions,
} from '@/features/goods';
import { Collapse } from '@/shared/components';
import { ShowroomGoodsList } from '@/features/show-room';
import { useMedia } from '@/shared/hooks';
import cn from 'classnames';
import { AnimalPedigreeInfo } from '@/widgets/animal';

type TProps = {
  data: TGoodDtoExt;
  accountId?: string;
};

export const AnimalGoods = ({ data, accountId }: TProps) => {
  const { isDesktop } = useMedia();

  const { animalExt, city, canEdit } = data;

  if (!animalExt) {
    return null;
  }

  const { images, description } = animalExt;

  return (
    <>
      <FlexPage className={cn({ 'pb-[120px]': !canEdit }, { 'pb-[70px]': canEdit }, ' lg:pb-0 relative')}>
        <GoodsTitle data={data} />
        <GoodsImagesView images={images} />
        {isDesktop && <ButtonActions goodData={data} />}
        {city && <GoodsDelivery city={city.name} />}
        {accountId && <GoodsSeller accountId={accountId} good={data} />}
        <Collapse
          defaultActiveKey={1}
          items={[
            { key: 1, label: 'Описание', children: <GoodsDescription description={description} /> },
            { key: 2, label: 'Характеристики', children: <GoodsCharacteristics good={data} /> },
            { key: 3, label: 'Родословная', children: <AnimalPedigreeInfo animal={animalExt} /> },
          ]}
        />
        <ShowroomGoodsList />
      </FlexPage>
      <GoodsFooter goodData={data} />
    </>
  );
};
