import { TRoute } from '@/app/navigation';
import { Catalog, Console, ShopPage } from 'src/pages/shop';
import { ShopCatalogGoods } from '@/pages/shop/catalog/nested';
import { MyCatalogList } from '@/pages/shop/catalog/catalog-list';

export const DYNAMIC_PARAMS = {
  catalogId: 'catalogId',
  goodsId: 'goodsId',
} as const;

export const SHOP_PATHS = {
  shop: '/shop',
  catalog: '/shop/catalog',
  catalogGoods: '/shop/catalog/goods',
} as const;

export const SHOP_ROUTES: TRoute = {
  path: SHOP_PATHS.shop,
  element: <ShopPage />,
  nestedRoutes: [
    { index: true, element: <Console /> },
    {
      path: SHOP_PATHS.catalog,
      element: <Catalog />,
      nestedRoutes: [
        { index: true, element: <MyCatalogList /> },
        {
          path: `${SHOP_PATHS.catalogGoods}/:${DYNAMIC_PARAMS.goodsId}`,
          element: <ShopCatalogGoods />,
        },
      ],
    },
  ],
};
