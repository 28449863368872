import { TRoute } from '@/app/navigation';
import {
  MarketplacePage,
  MarketplaceRecommendations,
  CatalogList,
  MarketplaceGoods,
  Favourite,
} from '@/pages/marketplace';
import { useGetFilterQuery } from '@/features/marketplace/marketplace-goods-list/hooks';

enum FILTERED_ROUTES {
  RECOMMENDATIONS,
  CATALOG,
}

const DYNAMIC_PARAMS = {
  catalogId: 'catalogId',
  goodsId: 'goodsId',
} as const;

const MARKETPLACE = '/marketplace';
const MP_CATALOG = `${MARKETPLACE}/catalog`;
const MP_CATALOG_ID = `${MP_CATALOG}/:${DYNAMIC_PARAMS.catalogId}?`;
const MP_GOODS_PAGE = `${MP_CATALOG}/:${DYNAMIC_PARAMS.catalogId}/goods/:${DYNAMIC_PARAMS.goodsId}`;
const MP_FAVOURITE = `${MARKETPLACE}/favourite`;

export const MARKETPLACE_PATHS = {
  marketplace: MARKETPLACE,
  catalog: MP_CATALOG,
  favourite: MP_FAVOURITE,
} as const;

const FilteredRoutes = ({ route }: { route: FILTERED_ROUTES }) => {
  const query = useGetFilterQuery();

  if (route === FILTERED_ROUTES.RECOMMENDATIONS) {
    return <MarketplaceRecommendations key={query} />;
  }

  if (route === FILTERED_ROUTES.CATALOG) {
    return <CatalogList />;
  }
};

export const MARKETPLACE_ROUTES: TRoute = {
  path: MARKETPLACE_PATHS.marketplace,
  element: <MarketplacePage />,
  nestedRoutes: [
    {
      index: true,
      element: <FilteredRoutes route={FILTERED_ROUTES.RECOMMENDATIONS} />,
    },
    {
      path: MP_CATALOG_ID,
      element: <CatalogList />,
    },
    {
      path: MARKETPLACE_PATHS.favourite,
      element: <Favourite />,
    },
    {
      path: MP_GOODS_PAGE,
      element: <MarketplaceGoods />,
    },
  ],
};
