import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  defaultValues,
  MARKETPLACE_FILTER_FIELDS,
  marketplaceSchema,
  TMarketplaceSchema,
} from './useFilterFrom.helpers';
import { useParams, useSearchParams } from 'react-router-dom';
import { GOODS_CATEGORY } from '@/entities/good-category-controller';
import { SPECIES } from '@/entities/species-controller';

export const useFilterForm = () => {
  const { catalogId } = useParams();
  const [searchParams] = useSearchParams();

  const getSpecies = () => {
    const catalog = catalogId ? Number(catalogId) : null;
    if (catalogId && (catalog === GOODS_CATEGORY.DOGS || catalog === GOODS_CATEGORY.CATS)) {
      return catalog === GOODS_CATEGORY.DOGS ? SPECIES.DOGS : SPECIES.CATS;
    }

    const species = searchParams.get(MARKETPLACE_FILTER_FIELDS.species);
    return species ? Number(species) : defaultValues.species;
  };

  const country = searchParams.get(MARKETPLACE_FILTER_FIELDS.country);
  const city = searchParams.get(MARKETPLACE_FILTER_FIELDS.city);
  const from = searchParams.get(MARKETPLACE_FILTER_FIELDS.from) || defaultValues.from;
  const to = searchParams.get(MARKETPLACE_FILTER_FIELDS.to) || defaultValues.to;
  const breed = searchParams.get(MARKETPLACE_FILTER_FIELDS.breed);

  return useForm<TMarketplaceSchema>({
    reValidateMode: 'onChange',
    defaultValues: {
      country: country ? Number(country) : defaultValues.country,
      city: city ? Number(city) : defaultValues.city,
      from,
      to,
      breed: breed ? Number(breed) : defaultValues.breed,
      species: getSpecies(),
    },
    resolver: zodResolver(marketplaceSchema),
  });
};
