import { SVGProps } from 'react';

export const DeleteAccountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
    {...props}
  >
    <path
      d="M13.3627 4.41481C11.3806 4.21838 9.38653 4.11719 7.39844 4.11719C6.21987 4.11719 5.04129 4.17671 3.86272 4.29576L2.64844 4.41481"
      stroke="#EF466F"
      strokeWidth="0.892857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.92188 3.81473L6.05283 3.03497C6.14807 2.46949 6.21949 2.04688 7.22545 2.04688H8.78497C9.79092 2.04688 9.8683 2.4933 9.95759 3.04092L10.0885 3.81473"
      stroke="#EF466F"
      strokeWidth="0.892857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0766 6.29688L11.6897 12.2909C11.6243 13.2254 11.5707 13.9516 9.90997 13.9516H6.08854C4.42783 13.9516 4.37426 13.2254 4.30878 12.2909L3.92188 6.29688"
      stroke="#EF466F"
      strokeWidth="0.892857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.00781 10.6758H8.98996"
      stroke="#EF466F"
      strokeWidth="0.892857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.51562 8.29688H9.49182"
      stroke="#EF466F"
      strokeWidth="0.892857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
