import { Popup, TPopupProps, Checkbox, LabelWrapper } from '@/shared/ui';
import { AlphabetSelect } from '@/features/select';
import { z } from 'zod';
import { Flex } from 'antd';
import { selectCurrentAccountId, useTypedDispatch, useTypedSelector } from '@/app/store';
import { FormProvider, useForm } from 'react-hook-form';
import { BirthMatingDatepickers, LittersFatherSelect, LittersMotherSelect } from '@/features/litters';
import { zodResolver } from '@hookform/resolvers/zod';

import styles from './styles.module.scss';
import { TLitterRequestDto, useLazyGetLitterQuery, usePostLitterMutation } from '@/entities/litters-controller';
import { useGetCurrentUserQuery } from '@/entities/user-controller';
import { updateLittersList } from '@/entities/litters-controller/actions';

export const LITTER_FORM_FIELDS = {
  name: 'name',
  father: 'father',
  mother: 'mother',
  matingDate: 'matingDate',
  birthDate: 'birthDate',
  awaiting: 'awaiting',
} as const;

export const litterSchema = z.object({
  [LITTER_FORM_FIELDS.name]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.father]: z
    .object({
      id: z.string(),
      dateBirth: z.string().optional(),
    })
    .nullable()
    .refine((item) => !!item),
  [LITTER_FORM_FIELDS.mother]: z.object({
    id: z.string(),
    dateBirth: z.string().optional(),
  }),
  [LITTER_FORM_FIELDS.matingDate]: z.string().optional().nullable(),
  [LITTER_FORM_FIELDS.birthDate]: z.string().nonempty(),
  [LITTER_FORM_FIELDS.awaiting]: z.boolean(),
});

export type TLitterSchema = z.infer<typeof litterSchema>;

type TProps = TPopupProps & { nurseryId?: number; defaultValues?: Partial<TLitterSchema> };

export const LitterPopup = ({ nurseryId, defaultValues, ...props }: TProps) => {
  const dispatch = useTypedDispatch();
  const isEditMode = !!defaultValues;

  const accountId = useTypedSelector(selectCurrentAccountId);
  const { data: currentAccount } = useGetCurrentUserQuery();
  const nursery = currentAccount?.nurseries[0];

  const [postLitter] = usePostLitterMutation();
  const [getLitter] = useLazyGetLitterQuery();

  const formMethods = useForm<TLitterSchema>({
    defaultValues: { [LITTER_FORM_FIELDS.awaiting]: false, ...defaultValues },
    resolver: zodResolver(litterSchema),
  });
  const { setValue, handleSubmit } = formMethods;

  const handleChangeParent = () => {
    if (!isEditMode) {
      setValue(LITTER_FORM_FIELDS.birthDate, '');
      setValue(LITTER_FORM_FIELDS.matingDate, '');
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const { name, father, mother, ...rest } = data;
      const nursery = nurseryId || currentAccount?.nurseries[0]?.id;

      if (!father || !nursery) {
        return;
      }
      const payload: TLitterRequestDto = {
        name,
        fatherId: father.id,
        motherId: mother.id,
        nurseryId: nursery,
        ...rest,
      };
      const { id } = await postLitter(payload).unwrap();
      const litter = await getLitter(id).unwrap();
      dispatch(updateLittersList(litter, nursery));
      props.onCancel();
    } catch (e) {
      console.error(e);
    }
  });

  const okText = isEditMode ? 'Сохранить изменения' : 'Добавить';
  const title = isEditMode ? 'Редактировать помет' : 'Добавить помет';

  if (!accountId) {
    return null;
  }

  return (
    <Popup size="MEDIUM" title={title} okText={okText} cancelText="Отменить" onOk={onSubmit} {...props}>
      <FormProvider {...formMethods}>
        <Flex vertical gap={24} className={styles['litter-popup']}>
          <AlphabetSelect
            showErrorMsg={false}
            controllerProps={{ name: LITTER_FORM_FIELDS.name }}
            labelProps={{ label: 'Буква помета:' }}
            disabled={isEditMode}
          />
          <LittersMotherSelect
            handleChangeParent={handleChangeParent}
            labelProps={{ label: 'Мать:' }}
            accountId={accountId}
            showErrorMsg={false}
            controllerProps={{ name: LITTER_FORM_FIELDS.mother }}
          />
          <LabelWrapper label="Отец:">
            <LittersFatherSelect
              handleChangeParent={handleChangeParent}
              placeholder="Введите имя питомца"
              accountId={accountId}
              showErrorMsg={false}
              controllerProps={{ name: LITTER_FORM_FIELDS.father }}
              breedId={nursery?.breeds[0].id}
              speciesId={nursery?.species.id}
            />
          </LabelWrapper>
          <BirthMatingDatepickers isEditMode={isEditMode} />
          <Checkbox controlledProps={{ name: LITTER_FORM_FIELDS.awaiting }}>
            <span className="font-normal">Показывать в Пометах, что ожидаем</span>
          </Checkbox>
        </Flex>
      </FormProvider>
    </Popup>
  );
};
