import { Controller } from 'react-hook-form';
import { InputComponent, TInputProps } from '@/shared/ui';

type TProps = TInputProps & {
  decimalLimit?: number;
  onChange?: (value: string) => void;
};

const NumberInputComponent = ({ value = '', onChange, decimalLimit, error, ...props }: TProps) => (
  <InputComponent
    onChange={(e) => {
      const { value } = e.target;
      const checkValue = value.replace('.', ',');
      const regex = decimalLimit ? new RegExp(`^\\d*(,\\d{0,${decimalLimit}})?$`) : /^\d*$/;

      if (!regex.test(checkValue) || checkValue.startsWith(',')) {
        return;
      }

      if (onChange) {
        onChange(checkValue);
      }
    }}
    onBlur={() => {
      if (typeof value === 'string' && value && value.at(-1) === ',') {
        onChange?.(value.slice(0, -1));
      }
    }}
    error={error}
    value={value}
    {...props}
  />
);

export const NumberInput = ({ controllerProps, ...props }: TProps) => {
  if (!controllerProps) {
    return <NumberInputComponent {...props} />;
  }

  return (
    <Controller
      render={({ field, fieldState: { error } }) => <NumberInputComponent {...field} error={error} {...props} />}
      {...controllerProps}
    />
  );
};
