import { FormProvider } from 'react-hook-form';
import { defaultValues, useFilterForm } from '../form/hooks';
import { FilterForm } from '../form';
import { Button, Drawer, TDrawerProps } from '@/shared/ui';
import { Flex } from 'antd';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

type TProps = TDrawerProps;

export const FilterDrawer = ({ onCancel, ...props }: TProps) => {
  const { catalogId } = useParams();
  const formMethods = useFilterForm();
  const [_, setSearchParams] = useSearchParams();

  const {
    reset,
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = formMethods;

  const onClearFilters = () => {
    reset(defaultValues);
    setSearchParams({});
    onCancel();
  };

  const onSetFilters = handleSubmit((data) => {
    const filters = Object.entries(data).reduce((res: Record<string, string>, [key, value]) => {
      if (value) {
        res[key] = String(value);
      }
      return res;
    }, {});

    onCancel();
    setSearchParams({ ...filters });
  });

  const isClearDisabled = useMemo(() => {
    const getData = () => {
      if (catalogId) {
        const { species, ...rest } = getValues();
        return { ...rest };
      }

      return getValues();
    };

    return !Object.values(getData()).some((item) => !!item);
  }, [catalogId, getValues]);

  const footer = () => {
    return (
      <Flex gap={16} vertical>
        <Button onClick={onClearFilters} disabled={isClearDisabled} className="w-full" type="default">
          Сбросить фильтры
        </Button>
        <Button onClick={onSetFilters} disabled={!isDirty} className="w-full">
          Показать результаты
        </Button>
      </Flex>
    );
  };

  return (
    <Drawer title="Фильтр" onCancel={onCancel} footer={footer()} {...props}>
      <FormProvider {...formMethods}>
        <FilterForm />
      </FormProvider>
    </Drawer>
  );
};
