import { Loader } from '@/app/icons';
import { useSendFilesMutation } from '@/entities/file-controller';
import { useGetLitterQuery } from '@/entities/litters-controller';
import { Collapse, Divider, Headline3 } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import { Headline4 } from '@/shared/ui';
import { convertDateToString } from '@/shared/utils';
import { useParams } from 'react-router-dom';
import { LitterImagesHeader } from './litter-images-header';
import { LitterImagesList } from './litter-images-list';
import { LitterIndividuals } from './litter-individuals';
import { LitterInfo } from './litter-info';

export const LitterDetails = () => {
  const { id } = useParams();
  const { data, refetch } = useGetLitterQuery(id as string);
  const [, { isLoading: isUploading }] = useSendFilesMutation();

  if (!data) return <Loader />;

  const { nursery, id: litterId, images, canEdit, name, birthDate } = data;

  return (
    <FlexPage>
      <Headline3>
        {name} / {birthDate && `${convertDateToString(birthDate)} г.`} / {nursery.nameEn}
      </Headline3>
      <Divider />
      <Collapse
        collapsible="icon"
        defaultActiveKey={1}
        items={[
          {
            label: (
              <LitterImagesHeader
                isUploading={isUploading}
                canEdit={canEdit}
                litterId={litterId}
                images={images}
                refetch={refetch}
                data={data}
              />
            ),
            key: 1,
            children: <LitterImagesList canEdit={canEdit} images={images} />,
          },
        ]}
      />
      <Headline4>Информация</Headline4>
      <LitterInfo data={data} />
      <Divider />
      <LitterIndividuals nursery={nursery} canEdit={canEdit} />
    </FlexPage>
  );
};
