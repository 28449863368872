import { SVGProps } from 'react';

export const MyGoodsButtonIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.3132 6.6665H2.64648V11.9998C2.64648 13.9998 3.31315 14.6665 5.31315 14.6665H10.6465C12.6465 14.6665 13.3132 13.9998 13.3132 11.9998V6.6665Z"
      stroke="#212029"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3327 4.66683V5.3335C14.3327 6.06683 13.9793 6.66683 12.9993 6.66683H2.99935C1.97935 6.66683 1.66602 6.06683 1.66602 5.3335V4.66683C1.66602 3.9335 1.97935 3.3335 2.99935 3.3335H12.9993C13.9793 3.3335 14.3327 3.9335 14.3327 4.66683Z"
      stroke="#212029"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.75921 3.33329H4.07921C3.85254 3.08663 3.85921 2.70663 4.09921 2.46663L5.04587 1.51996C5.29254 1.27329 5.69921 1.27329 5.94587 1.51996L7.75921 3.33329Z"
      stroke="#212029"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9124 3.33329H8.23242L10.0458 1.51996C10.2924 1.27329 10.6991 1.27329 10.9458 1.51996L11.8924 2.46663C12.1324 2.70663 12.1391 3.08663 11.9124 3.33329Z"
      stroke="#212029"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.96094 6.6665V10.0932C5.96094 10.6265 6.5476 10.9398 6.99427 10.6532L7.62094 10.2398C7.8476 10.0932 8.13427 10.0932 8.35427 10.2398L8.9476 10.6398C9.3876 10.9332 9.98094 10.6198 9.98094 10.0865V6.6665H5.96094Z"
      stroke="#212029"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
