import { RcFile } from 'antd/es/upload';
import { Area } from 'react-easy-crop';

export const compressImage = async (file: RcFile): Promise<File> => {
  return new Promise<File>((resolve) => {
    if (!file || file.size <= 5 * 1024 * 1024) {
      resolve(file);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        let quality = 1;
        let compressedFile;

        const updateCanvas = () => {
          const targetWidth = img.width;
          const targetHeight = img.height;

          canvas.width = targetWidth;
          canvas.height = targetHeight;

          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

          const fileType = file.type || (file.name.endsWith('.png') ? 'image/png' : 'image/jpeg');

          canvas.toBlob(
            (blob) => {
              if (blob) {
                compressedFile = new File([blob], file.name, { type: fileType });
                if (compressedFile.size > 5 * 1024 * 1024) {
                  quality -= 0.1;
                  if (quality > 0) {
                    updateCanvas();
                  } else {
                    resolve(compressedFile);
                  }
                } else {
                  resolve(compressedFile);
                }
              }
            },
            fileType,
            quality,
          );
        };

        updateCanvas();
      };
    };

    reader.readAsDataURL(file);
  });
};

export const getCroppedImg = (compressedFile: File, pixelCrop: Area): Promise<File> => {
  return new Promise<File>((resolve) => {
    const image = new Image();
    image.src = URL.createObjectURL(compressedFile);

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height,
      );
      const fileType = image.src.endsWith('.png') ? 'image/png' : 'image/jpeg';

      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], compressedFile.name, { type: fileType });
          resolve(croppedFile);
        }
      }, fileType);
    };
  });
};
