import { TGoodDto, useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { Body2, Caption2, Caption4, Card, Hairline3, Headline6 } from '@/shared/ui';
import React from 'react';
import { Flex } from 'antd';
import { useCurrencyConvert } from '@/shared/hooks/currency';
import { useGetCategoriesByMainQuery } from '@/entities/good-category-controller';
import { Success } from '@/app/icons';
import { Collapse, Divider } from '@/shared/components';
import './styles.scss';
import { GoodsCharacteristics, GoodsDescription } from '@/features/goods';
import { Pencil } from '@/app/icons/Pencil';
import { useNavigate } from 'react-router';
import { SHOP_PATHS } from '@/app/navigation';

type TProps = {
  good: TGoodDto;
};

export const ShopCard = ({ good }: TProps) => {
  const { animal, product, currency, price, category, id } = good;
  const getPrice = useCurrencyConvert();
  const { data } = useGetCategoriesByMainQuery(Number(category.id), { skip: !category.id });
  const navigate = useNavigate();
  const { data: animalData } = useGetGoodsByIdQuery(String(id), { skip: !id });

  const onEditCard = () => {
    navigate(`${SHOP_PATHS.catalogGoods}/${good.id}`);
  };

  if (!animal && !product) {
    return null;
  }

  return (
    <Card
      className="shop-card w-full p-[8px]"
      hoverable
      metaProps={{
        description: (
          <>
            <Flex gap={16}>
              <img
                alt="card-img"
                width={84}
                className="h-[84px] object-cover"
                src={product?.images[0].url || animal?.avatar?.url}
              />
              <Flex className="flex-col max-w-[408px] w-full">
                <Body2 className="font-semibold line-clamp-1">{product?.name || animal?.fullName}</Body2>
                <Headline6 className="">{getPrice(currency, price)}</Headline6>
              </Flex>
              <Flex className="max-h-max items-center">
                <Caption2 className="">В наличии</Caption2>
                <Success />
              </Flex>
              <Flex className="group bg-[#F4F5F9] h-max rounded-md self-end transition-colors hover:bg-[#333240] ms-auto">
                <Pencil
                  width={20}
                  height={20}
                  className="m-[10px] text-black transition-colors group-hover:text-[#FBFCFE]"
                  onClick={onEditCard}
                />
              </Flex>
            </Flex>
            <Collapse
              collapsible="icon"
              className="shop-card-collapse self-end text-neutral2"
              bordered={false}
              items={[
                {
                  key: 1,
                  label: (
                    <Flex justify="space-between" className="pl-[100px]">
                      <Caption4 className="text-[#8A91A9]">
                        {data?.parents?.map((el) => `${el.name} / `).reverse()} {category.name}
                      </Caption4>
                      <Hairline3 className="self-end">Подробнее</Hairline3>
                    </Flex>
                  ),
                  children: (
                    <Flex vertical>
                      <Divider />
                      <Flex className="pl-[100px] pt-[8px]">
                        {animal && animalData && <GoodsCharacteristics good={animalData} />}
                        {product && <GoodsDescription description={product?.description} />}
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
          </>
        ),
      }}
    />
  );
};
