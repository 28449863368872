import { SVGProps } from 'react';

export const ChangeAvatarIcon = ({ className, ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    cursor="pointer"
    className={className}
    {...props}
  >
    <path
      d="M5.9974 6.66667C6.73378 6.66667 7.33073 6.06971 7.33073 5.33333C7.33073 4.59695 6.73378 4 5.9974 4C5.26102 4 4.66406 4.59695 4.66406 5.33333C4.66406 6.06971 5.26102 6.66667 5.9974 6.66667Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.66927 1.33398H6.0026C2.66927 1.33398 1.33594 2.66732 1.33594 6.00065V10.0007C1.33594 13.334 2.66927 14.6673 6.0026 14.6673H10.0026C13.3359 14.6673 14.6693 13.334 14.6693 10.0007V6.66732"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M10.5 3.33398H14.1667" stroke="currentColor" strokeLinecap="round" />
    <path d="M12.3359 5.16667V1.5" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M1.78125 12.633L5.06792 10.4264C5.59458 10.073 6.35458 10.113 6.82792 10.5197L7.04792 10.713C7.56792 11.1597 8.40792 11.1597 8.92792 10.713L11.7012 8.33305C12.2212 7.88638 13.0613 7.88638 13.5813 8.33305L14.6679 9.26638"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
