import { MenuProps } from 'antd';
import { Body2, Dropdown } from '@/shared/ui';
import { useState } from 'react';
import { AddShowroomProductModal, DeleteProductCardModal } from '@/widgets/show-room';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { useParams } from 'react-router-dom';
import { EditTwoIcon, Trash } from '@/app/icons';

enum ACTIONS {
  EDIT,
  DELETE,
}

export const GoodsCrudDropdown = () => {
  const { goodsId } = useParams();
  const { data: goodsData } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });

  const [actions, setActions] = useState<ACTIONS | null>(null);
  const resetActions = () => setActions(null);

  const isEditMode = ACTIONS.EDIT === actions;
  const isOpenDelete = ACTIONS.DELETE === actions;

  if (!goodsData || !goodsData.canEdit || !goodsId) {
    return null;
  }

  const { animal, product } = goodsData;

  const items: MenuProps['items'] = [
    {
      icon: <EditTwoIcon className="text-neutral4 size-[14px]" />,
      key: 'edit',
      label: <Body2>Редактировать</Body2>,
      onClick: () => setActions(ACTIONS.EDIT),
    },
    {
      icon: <Trash className="text-primary3 size-[14px]" />,
      key: 'delete',
      label: <Body2 className="text-primary3">Удалить из каталога</Body2>,
      onClick: () => setActions(ACTIONS.DELETE),
    },
  ];

  const text = () => {
    if (animal) {
      return `${animal.fullName} ${animal.nursery.nameEn}`;
    }
    if (product) {
      return product.name;
    }

    return '';
  };

  return (
    <>
      <Dropdown menu={{ items }} placement="bottomLeft" />
      {isEditMode && <AddShowroomProductModal data={goodsData} onCancel={resetActions} open={isEditMode} />}
      {isOpenDelete && <DeleteProductCardModal onClose={resetActions} id={goodsData.id} text={text()} />}
    </>
  );
};
