import { TRoute } from '@/app/navigation';
import { AccountPage, AccountPosts, AccountShowroom, AccountNursery } from '@/pages/account';
import { ShowRoomList, ShowroomGoods } from '@/pages/showroom';

export const ACCOUNT_LINKS = {
  account: '/account',
} as const;

export const ACCOUNT_TABS = {
  showroom: 'showroom',
  posts: 'posts',
  nursery: 'nursery',
} as const;

export const ACCOUNT_PATHS = {
  account: `${ACCOUNT_LINKS.account}/:accountId`,
} as const;

export const ACCOUNT_ROUTES: TRoute = {
  path: ACCOUNT_PATHS.account,
  element: <AccountPage />,
  nestedRoutes: [
    {
      path: ACCOUNT_TABS.posts,
      element: <AccountPosts />,
    },
    {
      path: ACCOUNT_TABS.nursery,
      element: <AccountNursery />,
    },
    {
      path: ACCOUNT_TABS.showroom,
      element: <AccountShowroom />,
      nestedRoutes: [
        { index: true, element: <ShowRoomList /> },
        {
          path: ':goodsId',
          element: <ShowroomGoods />,
        },
      ],
    },
  ],
};
