import { Button } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';

const ADD_BUTTON_LABEL = 'Добавить питомца';

type TProps = {
  onAddButtonClick: () => void;
};

export const EmptySection = ({ onAddButtonClick }: TProps) => {
  const { isMobile } = useMedia();

  return (
    <Button type="default" className={isMobile ? 'w-full' : ''} onClick={onAddButtonClick}>
      {ADD_BUTTON_LABEL}
    </Button>
  );
};
