import { FlexPage } from '@/shared/containers';
import { Outlet, useParams } from 'react-router-dom';
import { Breadcrumb, TBreadcrumbProps } from '@/shared/components';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { SHOP_PATHS } from '@/app/navigation/shop';

export const Catalog = () => {
  const { goodsId } = useParams();
  const { data } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });

  const goodName = data?.product?.name || `${data?.animalExt?.nursery.nameEn} ${data?.animalExt?.fullName}`;

  const items: TBreadcrumbProps['items'] = [
    {
      title: 'Каталог',
      path: SHOP_PATHS.catalog,
      isHidden: !goodsId,
    },
    {
      title: goodName,
      isHidden: !goodsId,
    },
  ];

  return (
    <FlexPage>
      <Breadcrumb items={items} />
      <Outlet />
    </FlexPage>
  );
};
