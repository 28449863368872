import { MARKETPLACE_PATHS } from '@/app/navigation';
import { Breadcrumb, TBreadcrumbProps } from '@/shared/components';
import { useParams } from 'react-router-dom';

import { useGetCategoriesByMainQuery } from '@/entities/good-category-controller';

export const MarketplaceBreadcrumbs = () => {
  const { catalogId } = useParams();

  const { data } = useGetCategoriesByMainQuery(Number(catalogId), { skip: !catalogId });

  if (!data || !catalogId) {
    return null;
  }

  const getBreads = () => {
    const init = {
      title: data.name,
      path: `${MARKETPLACE_PATHS.catalog}/${data.id}`,
    };

    if (data.parents) {
      return [
        ...data.parents
          .map((item) => ({
            title: item.name,
            path: `${MARKETPLACE_PATHS.catalog}/${item.id}`,
          }))
          .reverse(),
        init,
      ];
    }

    return [init];
  };

  const items: TBreadcrumbProps['items'] = [
    {
      path: MARKETPLACE_PATHS.catalog,
      title: 'Каталог',
    },
    ...getBreads(),
  ];

  return <Breadcrumb items={items} />;
};
