import { z } from 'zod';
import { selectRule, stringRule } from '@/shared/validation';
import { useForm } from 'react-hook-form';
import { TGoodDtoExt, usePatchGoodsMutation } from '@/entities/goods-controller';
import { zodResolver } from '@hookform/resolvers/zod';
import { priceConverter } from '@/shared/utils';
import { TProductsPatchDto, usePatchProductMutation } from '@/entities/products-controller';
import { TAnimalPutRequestDto, usePatchAnimalMutation } from '@/entities/animal-controller';

export const MAIN_FORM_FIELDS = {
  name: 'name',
  price: 'price',
  currency: 'currency',
  description: 'description',
  images: 'images',
} as const;

const schema = z.object({
  [MAIN_FORM_FIELDS.name]: stringRule(),
  [MAIN_FORM_FIELDS.price]: stringRule(),
  [MAIN_FORM_FIELDS.currency]: selectRule(),
  [MAIN_FORM_FIELDS.description]: stringRule(),
  [MAIN_FORM_FIELDS.images]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array()
    .refine((data) => !!data.length),
});

type TMainInfoSchema = z.infer<typeof schema>;

export const useMainForm = (goodData: TGoodDtoExt) => {
  const [patchProduct, { isLoading: isUpdatingProduct }] = usePatchProductMutation();
  const [updateAnimal, { isLoading: isUpdatingAnimal }] = usePatchAnimalMutation();
  const [patchGoods, { isLoading: isUpdatingGood }] = usePatchGoodsMutation();

  const getDefaultValues = (): TMainInfoSchema => {
    const { product, animalExt, price, currency } = goodData;
    return {
      [MAIN_FORM_FIELDS.name]: product?.name || animalExt?.fullName || '',
      [MAIN_FORM_FIELDS.price]: priceConverter(price),
      [MAIN_FORM_FIELDS.currency]: currency.id,
      [MAIN_FORM_FIELDS.description]: product?.description || animalExt?.description || '',
      [MAIN_FORM_FIELDS.images]: product?.images || animalExt?.images || [],
    };
  };

  const formMethods = useForm<TMainInfoSchema>({ defaultValues: getDefaultValues(), resolver: zodResolver(schema) });

  const onSubmit = formMethods.handleSubmit(async (formData) => {
    const { name, description, images, price, currency } = formData;
    const { product, animal } = goodData;

    const requestImages = images.map(({ id }) => id);

    if (product) {
      const productPayload: TProductsPatchDto = { id: product.id, name, description, images: requestImages };
      await patchProduct(productPayload).unwrap();
    }
    if (animal) {
      const animalId = animal.id;
      const animalPayload: TAnimalPutRequestDto = { description, images: requestImages };
      await updateAnimal({ id: animalId, ...animalPayload }).unwrap();
    }

    await patchGoods({
      id: goodData.id,
      price: parseFloat(price.replace(',', '.')),
      currencyId: currency,
    }).unwrap();
  });

  return { formMethods, onSubmit, isLoading: isUpdatingAnimal || isUpdatingGood || isUpdatingProduct };
};
