import { NotificationPopup } from '@/shared/components';
import { selectIsBreeder, useTypedDispatch, useTypedSelector } from '@/app/store';
import { MODALS, setGlobalModal } from '@/app/store/slices/globalModals';
import { useDeleteGoodsMutation } from '@/entities/goods-controller';
import { useLocation } from 'react-router-dom';
import { useHistory } from '@/shared/hooks';
import { HOME_PATHS, MARKETPLACE_PATHS } from '@/app/navigation';

type TProps = {
  id: string;
  onClose: () => void;
  text: string;
};

export const DeleteProductCardModal = ({ id, text, onClose }: TProps) => {
  const [deleteGoods, { isLoading }] = useDeleteGoodsMutation();
  const dispatch = useTypedDispatch();
  const navigate = useHistory();
  const { pathname } = useLocation();
  const isBreeder = useTypedSelector(selectIsBreeder);

  const handleDelete = async () => {
    try {
      await deleteGoods(id).unwrap();
      onClose();
      dispatch(
        setGlobalModal({
          modal: MODALS.DELETE_GOODS_NOTIFICATION,
          text: `${text}`,
        }),
      );

      if (pathname.includes(MARKETPLACE_PATHS.marketplace)) {
        return navigate(MARKETPLACE_PATHS.marketplace);
      }
      return navigate(HOME_PATHS.showroom);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <NotificationPopup
      open
      title="Удалить карточку?"
      onCancel={onClose}
      cancelButtonProps={{ children: 'Отменить', onClick: onClose }}
      okButtonProps={{ children: 'Удалить', onClick: handleDelete, loading: isLoading }}
    >
      Вы уверены, что хотите удалить карточку <span className="text-headline6 font-semibold mx-2">{text}</span> из
      каталога? {isBreeder && 'Карточка питомца останется в Вашем питомнике'}
    </NotificationPopup>
  );
};
