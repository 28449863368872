import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { useParams } from 'react-router-dom';
import { Loader } from '@/app/icons';
import { MainInfo } from './main-info';

export const ShopCatalogGoods = () => {
  const { goodsId } = useParams();
  const { data: goodData } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });

  if (!goodData) {
    return <Loader />;
  }

  return <MainInfo goodData={goodData} />;
};
