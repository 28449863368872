import { useTypedDispatch } from '@/app/store';
import { MODALS, setGlobalModal } from '@/app/store/slices/globalModals';
import { useLazyGetCitiesQuery } from '@/entities/city-controller';
import { useGetCountriesQuery } from '@/entities/country-controller';
import { TUserDto, userApi, useUpdateCurrentUserMutation } from '@/entities/user-controller';
import { ProfileAvatar } from '@/features/profile/ProfileAvatar';
import { CITY, COUNTRY } from '@/features/profile/constants';
import { ProfileHeader } from '@/features/profile/styles';
import { Button, Divider, Input, Select, TextArea } from '@/shared/components';
import { ButtonsWrapper } from '@/shared/containers';
import { CANCEL, SAVE_CHANGES } from '@/shared/text';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';

const Form = styled.form`
  display: contents;
`;

type TProps = {
  user: TUserDto;
  onCancel: () => void;
};

const FORM_FIELDS = {
  avatar: 'avatar',
  name: 'name',
  surname: 'surname',
  countryId: 'countryId',
  cityId: 'cityId',
  description: 'description',
} as const;

const validation = z.object({
  [FORM_FIELDS.avatar]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .optional(),
  [FORM_FIELDS.name]: z.string().nonempty(),
  [FORM_FIELDS.surname]: z.string().nonempty(),
  [FORM_FIELDS.countryId]: z.number().optional(),
  [FORM_FIELDS.cityId]: z.number().optional(),
  [FORM_FIELDS.description]: z.string().optional(),
});

type TProfileSchema = z.infer<typeof validation>;

export const ProfileEditForm = ({ user, onCancel }: TProps) => {
  const dispatch = useTypedDispatch();

  const [updateProfile, { isLoading }] = useUpdateCurrentUserMutation();
  const { avatar, country, city, name, surname, description } = user;

  const formMethods = useForm<TProfileSchema>({
    defaultValues: {
      [FORM_FIELDS.avatar]: avatar,
      [FORM_FIELDS.cityId]: city?.id,
      [FORM_FIELDS.countryId]: country?.id,
      [FORM_FIELDS.name]: name,
      [FORM_FIELDS.surname]: surname,
      [FORM_FIELDS.description]: description,
    },
    resolver: zodResolver(validation),
  });

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
    resetField,
  } = formMethods;

  const countryWatch = watch(FORM_FIELDS.countryId);

  const { data: countries, isLoading: isLoadingCountries } = useGetCountriesQuery();
  const [getCities, { data: cities, isLoading: isLoadingCities }] = useLazyGetCitiesQuery();

  const onSubmit = async (data: TProfileSchema) => {
    try {
      await updateProfile({ ...user, ...data, avatarId: data.avatar?.id }).unwrap();
      onCancel();
      dispatch(setGlobalModal(MODALS.CHANGES_SAVED));
      dispatch(userApi.util.invalidateTags(['ACCOUNT', 'USER_ACCOUNTS']));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    countryWatch && getCities(countryWatch);
  }, [getCities, countryWatch]);

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ProfileHeader>
          <Controller
            render={({ field: { value, onChange } }) => (
              <ProfileAvatar name={user.name} url={value?.url} onChange={onChange} />
            )}
            name={FORM_FIELDS.avatar}
          />
          <div>
            <Controller
              render={({ field, fieldState: { error } }) => <Input {...field} hasError={!!error} placeholder="Имя" />}
              name={FORM_FIELDS.name}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <Input {...field} hasError={!!error} placeholder="Фамилия" />
              )}
              name={FORM_FIELDS.surname}
            />
            <Controller
              render={({ field }) => <TextArea {...field} autoSize={{ maxRows: 3, minRows: 3 }} placeholder="О себе" />}
              name={FORM_FIELDS.description}
            />
          </div>
        </ProfileHeader>
        <Divider />
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              onChange={(value) => {
                field.onChange(value);
                resetField(FORM_FIELDS.cityId, { defaultValue: 0 });
              }}
              labelProps={{ label: COUNTRY, isHorizontal: true }}
              options={countries}
              loading={isLoadingCountries}
            />
          )}
          name={FORM_FIELDS.countryId}
        />
        <Controller
          render={({ field }) => (
            <Select
              {...field}
              labelProps={{ label: CITY, isHorizontal: true }}
              options={cities}
              loading={isLoadingCities}
            />
          )}
          name={FORM_FIELDS.cityId}
        />
        <Divider />
        <ButtonsWrapper>
          <Button type="default" onClick={onCancel}>
            {CANCEL}
          </Button>
          <Button disabled={!isDirty} loading={isLoading} htmlType="submit">
            {SAVE_CHANGES}
          </Button>
        </ButtonsWrapper>
      </Form>
    </FormProvider>
  );
};
