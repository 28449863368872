import { Flex } from 'antd';
import { Button, Headline2, Headline4, Input, LabelWrapper, NumberInput, TextareaInput } from '@/shared/ui';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { MAIN_FORM_FIELDS, useMainForm } from './hooks';
import { CurrencySelect } from '@/features/select';
import { Divider, Drag, LIST_TYPE } from '@/shared/components';
import styles from '@/widgets/animal/animal-crud-popup/styles.module.scss';
import { Controller, FormProvider } from 'react-hook-form';

export const MainInfo = ({ goodData }: { goodData: TGoodDtoExt }) => {
  const { animalExt, product } = goodData;
  const { formMethods, onSubmit, isLoading } = useMainForm(goodData);

  const name = product?.name || `${animalExt?.nursery.nameEn} ${animalExt?.fullName}`;

  return (
    <Flex gap={16} vertical>
      <Headline2>{name}</Headline2>
      <Headline4>Основное</Headline4>
      <FormProvider {...formMethods}>
        {product && (
          <Input
            labelProps={{
              label: 'Название',
            }}
            showErrorMsg={false}
            controllerProps={{ name: MAIN_FORM_FIELDS.name }}
          />
        )}
        <LabelWrapper label="Цена">
          <Flex gap={16} className="w-full">
            <NumberInput controllerProps={{ name: MAIN_FORM_FIELDS.price }} decimalLimit={2} showErrorMsg={false} />
            <CurrencySelect controllerProps={{ name: MAIN_FORM_FIELDS.currency }} />
          </Flex>
        </LabelWrapper>
        <TextareaInput
          maxLength={5000}
          className="font-semibold"
          labelProps={{ isHorizontal: false, label: 'Описание' }}
          controllerProps={{ name: MAIN_FORM_FIELDS.description }}
          style={{ height: 172, resize: 'none' }}
          showErrorMsg={false}
        />
        <Controller
          render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
            <Drag
              labelProps={{
                labelWrapperClassName: styles.drag,
                label: 'Медиафайлы',
              }}
              maxCount={product ? 10 : undefined}
              handleChange={onChange}
              error={!!error}
              fileList={value}
              typeList={LIST_TYPE.IMAGE}
            />
          )}
          name={MAIN_FORM_FIELDS.images}
        />
      </FormProvider>
      <Divider />
      <Flex gap={32} className="mx-auto">
        <Button type="default">Отменить</Button>
        <Button onClick={onSubmit} loading={isLoading}>
          Сохранить
        </Button>
      </Flex>
    </Flex>
  );
};
