import { Flex } from 'antd';
import { Body3, Headline5 } from '@/shared/ui';
import { Success } from '@/app/icons';
import { ReactNode } from 'react';

export const PaymentRules = () => {
  const items: {
    key: number;
    description: string;
    reference?: ReactNode;
  }[] = [
    {
      key: 1,
      description: 'Оплата осуществляется банковской платёжной картой через систему ',
      reference: (
        <a href="https://www.webpay.by" target="_blank" className="text-primary1 underline" rel="noreferrer">
          WebPay
        </a>
      ),
    },
    {
      key: 2,
      description:
        'Безопасный сервер WEBPAY устанавливает шифрованное соединение по защищенному протоколу TLS и конфиденциально\n' +
        '          принимает от клиента данные его платёжной карты (номер карты, имя держателя, дату окончания действия, и\n' +
        '          контрольный номер банковской карточке CVC/CVC2)',
    },
    {
      key: 3,
      description:
        'После совершения оплаты с использованием банковской карты необходимо сохранять полученные карт-чеки\n' +
        '          (подтверждения об оплате) для сверки с выпиской из карт-счёта (с целью подтверждения совершённых операций в\n' +
        '          случае возникновения спорных ситуаций)',
    },
    {
      key: 4,
      description: `В случае, если Вы не получили заказ (не оказана услуга), Вам необходимо обратиться в службу 
      технической поддержки по телефону `,
      reference: (
        <>
          <span className="font-semibold">+375 (44) 795–11–57 </span>
          или e-mail{' '}
          <a href="mailto:info@breed.show">
            <b>info@breed.show. </b>
          </a>
          <p className="inline-block lg:block">Вас проконсультируют</p>
        </>
      ),
    },
  ];

  return (
    <Flex vertical gap={8}>
      <Headline5 className="mx-auto lg:text-headline4">Перед оплатой важно ознакомиться</Headline5>
      {items.map(({ description, reference, key }) => (
        <Flex gap={8} key={key}>
          <Success className="min-w-[24px]" />
          <Body3 className="lg:text-body2">
            {description}
            {reference}
          </Body3>
        </Flex>
      ))}
    </Flex>
  );
};
