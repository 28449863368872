import { Body2, Card, List } from '@/shared/ui';
import { useGetCategoriesByMainQuery, useGetMainCategoriesQuery } from '@/entities/good-category-controller';
import { useParams } from 'react-router-dom';
import { useHistory } from '@/shared/hooks';
import { MARKETPLACE_PATHS } from '@/app/navigation';
import { MarketplaceGoodsList } from '@/features/marketplace';
import { Loader } from '@/app/icons';
import { ChildrenCategories } from '@/pages/marketplace/nested/catalog/catalog-list/ChildrenCategories';

export const CatalogList = () => {
  const { catalogId } = useParams();
  const navigate = useHistory();
  const { data: mainCategories = [], isLoading: isLoadingMain } = useGetMainCategoriesQuery(undefined, {
    skip: !!catalogId,
  });
  const { data: categories, isLoading: isLoadingSub } = useGetCategoriesByMainQuery(Number(catalogId), {
    skip: !catalogId,
  });

  const listData = catalogId && categories?.children.length ? categories.children : mainCategories;

  const data = listData.map((item) => ({
    id: item.id,
    title: item.name,
    src: item.fileImageId?.url,
    alt: item.fileImageId?.fileName,
    onClick: () => navigate(`${MARKETPLACE_PATHS.catalog}/${item.id}`),
  }));

  if (isLoadingMain || isLoadingSub) {
    return <Loader />;
  }

  if (catalogId && !categories?.children.length) {
    return <MarketplaceGoodsList />;
  }

  if (catalogId) {
    return <ChildrenCategories data={data} />;
  }

  return (
    <List
      grid={{ gutter: 20, column: 2 }}
      dataSource={data}
      customItem={({ title, src, alt, onClick }) => (
        <Card
          onClick={onClick}
          hoverable
          cover={
            <div className="bg-neutral6 rounded-t-xxs h-[120px] text-center">
              <img className="w-full h-full object-contain" src={src} alt={alt} />
            </div>
          }
          metaProps={{ title: <Body2 className="font-semibold">{title}</Body2>, className: 'text-center bg-neutral9' }}
        />
      )}
    />
  );
};
