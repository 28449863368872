import { AddSquare, Loader, ShareIcon } from '@/app/icons';
import { LINK_RACY, ROUTE } from '@/app/navigation';
import {
  TAnimalDto,
  TPedigreeDto,
  useGetAnimalPedigreeQuery,
  usePutAssignFatherMutation,
  usePutAssignMotherMutation,
} from '@/entities/animal-controller';
import { ANIMAL_SEX } from '@/entities/species-controller';
import { ShareButton } from '@/features/buttons';
import { z } from 'zod';
import dayjs from 'dayjs';
import { Divider } from '@/shared/components';
import { useModal } from '@/shared/hooks';
import { Body3, Button, Caption4, TButtonProps } from '@/shared/ui';
import { AnimalCrudPopup, OWNER } from '@/widgets/animal/animal-crud-popup';
import { ANIMAL_FORM_FIELDS, TAnimalFormProps, animalSchema } from '@/widgets/animal/animal-crud-popup/hooks';
import { Flex, Image } from 'antd';
import cn from 'classnames';
import { Link } from 'react-router-dom';

const schema = animalSchema.partial().required({
  [ANIMAL_FORM_FIELDS.fullName]: true,
  [ANIMAL_FORM_FIELDS.animalRegistrationSystemId]: true,
  [ANIMAL_FORM_FIELDS.nursery]: true,
});

const ParentName = ({ animal, depth }: { animal: TAnimalDto; depth?: number }) => {
  return (
    <Flex vertical>
      <Caption4 className={cn({ 'truncate w-full max-w-[160px]': depth === 4, 'font-bold underline': true })}>
        {animal.fullName} {animal.nursery.nameEn}
      </Caption4>
      {depth !== 4 && <Body3>{animal.animalRegistrationSystem.abbreviation}</Body3>}
    </Flex>
  );
};

const ParentColors = ({ animal: { colors }, depth }: { animal: TAnimalDto; depth?: number }) => {
  return (
    <Flex vertical={depth !== 4} gap={depth === 4 ? 2 : 0}>
      {colors.map((item) => (
        <Caption4
          key={item.id}
          className={cn({ 'truncate w-full max-w-[125px]': depth === 3, 'truncate gap-1': depth === 4 })}
        >
          {item.name}
        </Caption4>
      ))}
    </Flex>
  );
};

type MainInfoProps = {
  animal: TAnimalDto;
  depth: number;
  sex: ANIMAL_SEX;
};

const MainInfo = ({ animal, depth }: MainInfoProps) => {
  const {
    images,
    speciesSex: { sex: animalSex },
  } = animal;
  const image = images[0];
  const sexName = animalSex === ANIMAL_SEX.MALE ? 'Отец' : 'Мать';

  if (depth === 1) {
    return (
      <Flex vertical gap={8}>
        {!!image && (
          <Image width={182} height={140} className="rounded-xxs object-cover" src={image.url} preview={false} />
        )}
        <Body3 className="font-semibold flex items-center gap-1">
          {animal.fullName}
          <Caption4>
            <Body3 className="font-normal">({sexName})</Body3>
          </Caption4>
        </Body3>

        <ParentName animal={animal} />
        <ParentColors animal={animal} />
      </Flex>
    );
  }
  if (depth === 2) {
    return (
      <Flex vertical gap={8}>
        {!!image && (
          <Image
            wrapperClassName="mx-auto"
            className="rounded-xxs object-cover"
            width={140}
            height={84}
            src={image.url}
            preview={false}
          />
        )}
        <ParentName animal={animal} />
        <ParentColors animal={animal} />
      </Flex>
    );
  }
  if (depth === 3) {
    return (
      <Flex gap={3}>
        {!!image && (
          <Image
            wrapperClassName="mx-auto"
            className="rounded-xxs object-cover"
            width={64}
            height={84}
            src={image.url}
            preview={false}
          />
        )}
        <Flex vertical>
          <ParentName animal={animal} />
          <ParentColors animal={animal} depth={depth} />
        </Flex>
      </Flex>
    );
  }
  if (depth === 4) {
    return (
      <Flex vertical>
        <ParentName animal={animal} depth={depth} />
        <Flex className="gap-1 items-center">
          <Body3>{animal.animalRegistrationSystem.abbreviation}</Body3>
          <ParentColors animal={animal} depth={depth} />
        </Flex>
      </Flex>
    );
  }
  return null;
};

const ParentCard = ({
  animal,
  child,
  depth,
  sex,
  canEdit,
  isParentFilled,
}: {
  animal: TAnimalDto | null;
  child: TAnimalDto | null;
  depth: number;
  sex: ANIMAL_SEX;
  canEdit: boolean;
  isParentFilled: boolean;
}) => {
  const dateSchema = z.object({
    [ANIMAL_FORM_FIELDS.dateBirth]: z
      .string()
      .nullable()
      .refine((date) => !date || dayjs(date).isBefore(dayjs(child?.dateBirth).subtract(180, 'day')), {
        message: 'Дата рождения родителя должна быть не ранее 180 дней от даты рождения ребёнка',
      })
      .optional(),
  });
  const mainSchema = schema.merge(dateSchema);

  const [assignMother] = usePutAssignMotherMutation();
  const [assignFather] = usePutAssignFatherMutation();

  const { open, close, isOpen } = useModal();

  const onAssignParent: TAnimalFormProps['onSubmitFinish'] = async ({ id: parentId, animalSex }) => {
    if (!child) {
      return;
    }

    const props = { animalId: child.id, parentId };
    if (animalSex === ANIMAL_SEX.FEMALE) {
      await assignMother(props);
    }
    if (animalSex === ANIMAL_SEX.MALE) {
      await assignFather(props);
    }

    close();
  };

  const btnProps: Partial<TButtonProps> = {
    size: 'unset',
    type: 'link',
    className: 'absolute right-0 bottom-0 text-neutral2',
  };

  const noDataText = sex === ANIMAL_SEX.MALE ? 'Отец' : 'Мать';

  const canEditCard = canEdit && (depth === 1 || isParentFilled);

  return (
    <>
      <Flex
        vertical
        wrap="nowrap"
        className={cn(
          'min-w-[200px] max-w-[100px] border border-1 border-neutral-4 rounded-xxs p-xxs relative overflow-hidden cursor-pointer',
          {
            'h-[382px]': depth === 1,
            'h-[190px]': depth === 2,
            'h-[94px]': depth === 3,
            'h-[46px]': depth === 4,
            'py-[5px]': depth === 4,
          },
        )}
      >
        {!!animal && (
          <Link to={`${LINK_RACY}/${animal.id}`} target="_blank">
            <MainInfo animal={animal} depth={depth} sex={sex} />
          </Link>
        )}
        {!animal && (
          <Body3
            className={cn('text-neutral4 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 text-center')}
          >
            {noDataText}
            <Body3 className="text-neutral4 block">(Нет данных)</Body3>
          </Body3>
        )}
        {canEditCard && !animal && <Button onClick={open} icon={<AddSquare width={18} height={18} />} {...btnProps} />}
        {!!animal && (
          <ShareButton
            buttonProps={{
              ...btnProps,
              icon: <ShareIcon width={18} height={18} />,
              text: '',
            }}
            shareProps={{
              text: 'Racy goods card',
              title: 'Checkout racy goods card',
              url: `${window.location.origin}/${ROUTE.PETS}/${animal.id}`,
            }}
          />
        )}
      </Flex>
      {isOpen && child && (
        <AnimalCrudPopup
          speciesId={child.species.id}
          defaultValues={{
            [ANIMAL_FORM_FIELDS.animalSex]: sex,
          }}
          disabledFields={[ANIMAL_FORM_FIELDS.animalSex]}
          open={isOpen}
          owner={OWNER.EXTERNAL}
          schema={mainSchema}
          onCancel={close}
          onSubmitFinish={onAssignParent}
        />
      )}
    </>
  );
};

const Parents = ({
  data,
  depth,
  canEdit,
  isParentFilled = true,
}: {
  data: TPedigreeDto | null;
  depth: number;
  canEdit: boolean;
  isParentFilled?: boolean;
}) => {
  if (depth > 4) {
    return null;
  }

  const { mother, father, animal } = data || {
    mother: null,
    father: null,
    animal: null,
  };

  const isFatherFilled = !!father?.animal;
  const isMotherFilled = !!mother?.animal;

  return (
    <Flex vertical gap={2} className="w-fit">
      <Flex gap={4}>
        <ParentCard
          canEdit={canEdit}
          child={animal}
          animal={father?.animal || null}
          depth={depth}
          sex={ANIMAL_SEX.MALE}
          isParentFilled={isParentFilled}
        />
        <Parents canEdit={canEdit} data={father} depth={depth + 1} isParentFilled={isFatherFilled} />
      </Flex>
      {depth === 1 && <Divider />}
      <Flex gap={4}>
        <ParentCard
          canEdit={canEdit}
          child={animal}
          animal={mother?.animal || null}
          depth={depth}
          sex={ANIMAL_SEX.FEMALE}
          isParentFilled={isParentFilled}
        />
        <Parents canEdit={canEdit} data={mother} depth={depth + 1} isParentFilled={isMotherFilled} />
      </Flex>
    </Flex>
  );
};

export const PedigreeGrid = ({ animalId, canEdit }: { animalId: string; canEdit: boolean }) => {
  const { data } = useGetAnimalPedigreeQuery(animalId);

  if (!data) {
    return <Loader />;
  }

  return (
    <div className="w-full overflow-x-auto">
      <Parents data={data} depth={1} canEdit={canEdit} />
    </div>
  );
};
