import { FlexPage } from '@/shared/containers';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { Breadcrumb, TBreadcrumbProps } from '@/shared/components';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { Loader } from '@/app/icons';
import { HOME_PATHS } from '@/app/navigation';
import { Flex } from 'antd';
import { GoodsCrudDropdown } from '@/features/goods';

export const MyShowRoom = () => {
  const { goodsId } = useParams();
  const { data, isFetching } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });
  const { pathname } = useLocation();

  const getBreadGoodsTitle = () => {
    if (isFetching) {
      return <Loader />;
    }
    if (data?.animal) {
      return `${data?.animal?.fullName} ${data?.animal?.nursery.nameEn}`;
    }

    if (data?.product) {
      return data.product.name;
    }
  };

  const breadcrumbItems: TBreadcrumbProps['items'] = [
    {
      title: 'Шоурум',
      path: HOME_PATHS.showroom,
    },
    {
      title: getBreadGoodsTitle(),
    },
  ];

  return (
    <FlexPage>
      <Flex justify="space-between">
        {pathname !== HOME_PATHS.showroom && <Breadcrumb items={breadcrumbItems} />}
        {data?.canEdit && goodsId && (
          <Flex justify="space-between" align="center">
            <GoodsCrudDropdown />
          </Flex>
        )}
      </Flex>
      <Outlet />
    </FlexPage>
  );
};
