import { useGetMyGoodsQuery } from '@/entities/goods-controller';
import { InfinityContainer, List } from '@/shared/ui';
import { ShopCard } from '@/features/shop/shop-card';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { useState } from 'react';

export const ShopCatalogList = () => {
  const myAccountId = useTypedSelector(selectCurrentAccountId);
  const [page, setPage] = useState<number>(1);
  const { data, isFetching } = useGetMyGoodsQuery(
    { page, accountId: String(myAccountId) },
    {
      skip: !myAccountId,
    },
  );
  const emptyText = !data ? 'У вас еще нет товаров' : undefined;

  return (
    <InfinityContainer
      data={data}
      setPage={setPage}
      isFetching={isFetching}
      renderedItem={(content) => (
        <List
          emptyText={emptyText}
          grid={{ gutter: 16, column: 1 }}
          dataSource={content}
          customItem={(item) => <ShopCard key={item.id} good={item} />}
        />
      )}
    />
  );
};
