import { ArrowCircleDown, ChangeAvatarIcon, DeleteAccountIcon, Loader } from '@/app/icons';
import { selectCurrentAccountId, useTypedDispatch, useTypedSelector } from '@/app/store';
import { useGetAccountQuery, userApi } from '@/entities/user-controller';
import { useSwitchAccount } from '@/shared/hooks/account';
import { Body2, Body3, Dropdown, Radio } from '@/shared/ui';
import { Flex } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import Avatar from 'react-avatar';

import { useUpdateAccountMutation } from '@/entities/account-controller';
import { AccountModal, AddNurseryAccountModal } from '@/features/account';
import { TUploadFile, Upload } from '@/shared/components';
import { Actions } from '@/widgets/account/account-manage/actions';
import styles from './styles.module.scss';

export enum ACCOUNT_STATE {
  ACCOUNT,
  NURSERY,
}

enum DROPDOWN_TYPE {
  ACCOUNT,
  AVATAR,
}

const DropdownContainer = ({
  title,
  children,
  isLight,
}: {
  title: string;
  children: React.ReactNode;
  isLight?: boolean;
}) => (
  <Flex
    vertical
    className={cn('border w-[220px] rounded-xxs py-xxs px-[6px]', {
      'bg-neutral9 border-neutral7 shadow-dropdown': isLight,
      'border-[#8A91A933] bg-neutral3': !isLight,
    })}
  >
    <Body2 className="font-semibold text-neutral4 ml-[6px]">{title}</Body2>
    {children}
  </Flex>
);

const DropdownItem = ({
  children,
  isLight,
  onClick,
  icon,
  text,
  className,
}: {
  children?: React.ReactNode;
  isLight?: boolean;
  onClick?: () => void;
  icon?: React.ReactNode;
  text?: string;
  className?: string;
}) => (
  <Flex
    align="center"
    className={cn('py-xxs px-[6px] cursor-pointer rounded-xxs', styles['account-manage'], {
      'hover:bg-neutral7': isLight,
      'hover:bg-neutral2': !isLight,
    })}
    onClick={onClick}
  >
    {icon}
    {text && (
      <Body3 className={cn('ml-[6.5px] w-[130px] truncate', { 'text-neutral8': !isLight }, className)}>{text}</Body3>
    )}
    {children}
  </Flex>
);

const AccountDropdown = ({
  type,
  onAddAccount,
  onDeleteAvatar,
  onChangeAvatar,
  isLight,
}: {
  type: DROPDOWN_TYPE;
  onAddAccount: (state: ACCOUNT_STATE) => void;
  onDeleteAvatar: () => void;
  onChangeAvatar: (file: TUploadFile) => void;
  isLight?: boolean;
}) => {
  const { accounts, isActiveAccount, handleSwitchAccount, canAdd } = useSwitchAccount();

  const isAvatarType = type === DROPDOWN_TYPE.AVATAR;
  const title = isAvatarType ? 'Изменить аватар' : 'Сменить аккаунт';

  return (
    <DropdownContainer title={title} isLight={isLight}>
      {isAvatarType ? (
        <>
          <Upload handleChange={(files: TUploadFile[]) => onChangeAvatar(files[0])} maxCount={1}>
            <DropdownItem
              isLight={isLight}
              icon={<ChangeAvatarIcon className={cn({ 'text-neutral8': !isLight })} />}
              text="Выбрать из библиотеки"
              className={cn('w-[160px]')}
            />
          </Upload>
          <DropdownItem
            isLight={isLight}
            onClick={onDeleteAvatar}
            icon={<DeleteAccountIcon />}
            text="Удалить аватар"
            className="text-primary3"
          />
        </>
      ) : (
        <>
          <Flex vertical>
            {accounts.map((account) => (
              <DropdownItem
                key={account.id}
                isLight={isLight}
                onClick={() => handleSwitchAccount(account.id)}
                icon={<Avatar size="24" src={account.avatar?.url} round name={account.name} className="object-cover" />}
                text={account.name}
              >
                <Radio className={cn('ml-auto', { dark: !isLight })} checked={isActiveAccount(account.id)} />
              </DropdownItem>
            ))}
          </Flex>
          <Actions onAddAccount={onAddAccount} isLight={isLight} canAdd={canAdd} />
        </>
      )}
    </DropdownContainer>
  );
};

export const AccountManage = ({ isLight }: { isLight?: boolean }) => {
  const dispatch = useTypedDispatch();
  const accountId = useTypedSelector(selectCurrentAccountId);
  const [isAddAccountOpen, setIsAccountOpen] = useState<ACCOUNT_STATE | null>(null);
  const [dropdownType, setDropdownType] = useState<DROPDOWN_TYPE | null>(null);

  const { data: account, isFetching } = useGetAccountQuery(String(accountId), { skip: !accountId });
  const [updateAccount] = useUpdateAccountMutation();
  const isAccountType = dropdownType === DROPDOWN_TYPE.ACCOUNT;

  const onAddAccount = (state: ACCOUNT_STATE) => {
    setIsAccountOpen(state);
  };

  if (!accountId || !account) {
    return null;
  }

  const isBreeder = !!account.nursery;

  const handleAvatarUpdate = async (file: TUploadFile | null) => {
    try {
      await updateAccount({
        ...account,
        id: accountId,
        data: {
          name: account?.name,
          description: account?.description,
          avatar: file?.id || null,
          ...(isBreeder && {
            breedId: account?.nursery?.breeds[0].id,
            registrationSystemId: account?.nursery?.registrationSystem.id,
          }),
        },
      }).unwrap();
      dispatch(userApi.util.invalidateTags(['ACCOUNT', 'USER_ACCOUNTS', 'CURRENT_USER']));
      setDropdownType(null);
    } catch (error) {
      console.error('Ошибка при обновлении или удалении аватара:', error);
    }
  };

  const onDeleteAvatar = () => handleAvatarUpdate(null);
  const onChangeAvatar = (file: TUploadFile) => handleAvatarUpdate(file);

  const toggleDropdown = (type: DROPDOWN_TYPE) => {
    setDropdownType((prev) => (prev === type ? null : type));
  };

  if (isFetching) {
    return <Loader className="h-[48px]" />;
  }

  return (
    <>
      <Dropdown
        destroyPopupOnHide
        open={dropdownType !== null}
        dropdownRender={() => (
          <AccountDropdown
            type={dropdownType || DROPDOWN_TYPE.ACCOUNT}
            onAddAccount={onAddAccount}
            onDeleteAvatar={onDeleteAvatar}
            onChangeAvatar={onChangeAvatar}
            isLight={isLight}
          />
        )}
        placement="bottomLeft"
      >
        <Flex
          className={cn('w-fit h-[48px] p-[12px] rounded-xxs items-center', {
            'bg-neutral7': isAccountType && isLight,
            'hover:bg-neutral7 bg-transparent': isLight && !isAccountType,
            'bg-neutral3': !isLight,
          })}
        >
          <Avatar
            onClick={() => toggleDropdown(DROPDOWN_TYPE.AVATAR)}
            size="24"
            src={account.avatar?.url}
            round
            name={account.name}
            className="object-cover"
          />
          <Body3 className={cn('ml-[6.5px] w-[72px] truncate', { 'text-neutral8': !isLight })}>{account.name}</Body3>
          <ArrowCircleDown
            onClick={() => toggleDropdown(DROPDOWN_TYPE.ACCOUNT)}
            className={cn('cursor-pointer transition-transform ml-[8px]', {
              'rotate-180': dropdownType === DROPDOWN_TYPE.ACCOUNT || dropdownType === DROPDOWN_TYPE.AVATAR,
              'text-neutral8': !isLight,
            })}
          />
        </Flex>
      </Dropdown>
      {isAddAccountOpen === ACCOUNT_STATE.ACCOUNT && (
        <AccountModal onCancel={() => setIsAccountOpen(null)} accountData={null} />
      )}
      {isAddAccountOpen === ACCOUNT_STATE.NURSERY && (
        <AddNurseryAccountModal onCancel={() => setIsAccountOpen(null)} accountData={null} />
      )}
    </>
  );
};
