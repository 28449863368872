import { goodsApi } from '@/entities/goods-controller/api';
import { TGoodDto, TMyGoodsQuery } from '@/entities/goods-controller/types';
import { queries } from '@/shared/utils';

export const updateShowRoomCache = ({ add, remove, update }: { add?: TGoodDto; remove?: string; update?: TGoodDto }) =>
  goodsApi.util.updateQueryData('getMyGoods', {} as TMyGoodsQuery, (draft) => {
    if (remove) {
      draft.content = draft.content.filter(({ id }) => id !== remove);
      draft.totalElements -= 1;
    } else if (add) {
      draft.content = [add, ...draft.content];
      draft.totalElements += 1;
    } else if (update) {
      const updatedItem = draft.content.find(({ id }) => id === update.id);
      updatedItem && Object.assign(updatedItem, update);
    }
  });

export const postMarketplaceList = (response: TGoodDto, catalogId?: string) =>
  goodsApi.util.updateQueryData(
    'getGoods',
    { query: catalogId && queries.equal('category.id', catalogId) },
    (draft) => {
      draft.content = [response, ...draft.content];
      draft.totalElements += 1;
    },
  );
