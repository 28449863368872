import { Flex } from 'antd';
import { Headline5, Body3 } from '@/shared/ui';
import { TariffsPlans } from '@/pages/shop/console/tariffs/tariffs-plans';

export const Tariffs = () => {
  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8}>
        <Headline5 className="mx-auto lg:text-headline3">Тарифы BREED SHOW</Headline5>
        <Body3 className="lg:text-body2">Оптимизируйте ваш магазин с тарифами Breed Show! </Body3>
        <Body3 className="lg:text-body2">
          Мы предлагаем три тарифа, чтобы вы могли начать с малого и расти вместе с нами.
          <p>
            На бесплатном тарифе <span className="font-semibold">«Старт»</span> вы сможете оценить платформу и понять,
            что нужно вашему бизнесу. А выбрав <span className="font-semibold">«Стандарт»</span> или{' '}
            <span className="font-semibold">«Бизнес»</span>, вы получите не только доступ ко всем текущим функциям, но и
            все будущие обновления вашего тарифа в течение года — без изменения стоимости.
          </p>
        </Body3>
        <Body3 className="lg:text-body2">
          Начните со «Старта», а когда будете готовы к большему, переходите на новый уровень!
        </Body3>
        <Body3 className="lg:text-body2">Спасибо, что Вы с нами!</Body3>
      </Flex>
      <TariffsPlans />
    </Flex>
  );
};
