import { Outlet, useLocation } from 'react-router-dom';

import { GlobalModals } from '@/app/store/providers';
import { device } from '@/app/theme/device';
import { useMedia } from '@/shared/hooks/useMedia';
import { MainSearch } from '@/widgets/MainSearch';
import { MobileSidebars } from '@/widgets/layouts/Mobile';
import { Flex } from 'antd';
import styled from 'styled-components';
import { LeftSidebar } from './LeftSidebar';
import { RightSidebar } from './RightSidebar';
import { ROUTE, MARKETPLACE_PATHS, LINK_RACY } from '@/app/navigation';
import { SHOP_PATHS } from 'src/app/navigation/shop';
import cn from 'classnames';
import { ProjectInfoPage } from '@/widgets/project-info';

const LayoutWrapper = styled(Flex)`
  height: 100svh;
  width: 100vw;
  overflow: hidden;
`;

const MainWrapper = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 32px;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.neutral[8]};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  @media screen and (${device.tablet}) {
    padding-top: 16px;
  }
`;

export const MainLayout = () => {
  const { isMobile } = useMedia();
  const { pathname } = useLocation();
  const isRegistration = pathname === ROUTE.REGISTRATION;
  const isManagement = pathname.includes(SHOP_PATHS.shop);
  const isMarketplacePage = pathname.includes(MARKETPLACE_PATHS.marketplace);
  const isAnimalPage = pathname.includes(LINK_RACY);

  const isShowSearch = !isRegistration && !isMarketplacePage && !isManagement;

  return (
    <LayoutWrapper vertical={isMobile} justify="space-between">
      {isMobile && <MobileSidebars />}
      {!isMobile && <LeftSidebar />}
      <MainWrapper>
        {isShowSearch && <MainSearch />}
        <div className="h-full flex flex-col px-xs justify-between scrollbar-gutter-stable overflow-y-auto">
          <div
            className={cn('w-full pb-md mx-auto', {
              'max-w-[1080px]': isMarketplacePage,
              'max-w-[820px]': isManagement || isAnimalPage,
              'max-w-[540px]': !isManagement && !isMarketplacePage && !isAnimalPage,
            })}
          >
            <Outlet />
          </div>
          {isMarketplacePage && !isMobile && <ProjectInfoPage contentWidth="1080px" />}
        </div>
        <GlobalModals />
      </MainWrapper>
      {!isMobile && !isMarketplacePage && <RightSidebar />}
    </LayoutWrapper>
  );
};
