import { Input, LabelWrapper, Select, TextareaInput } from '@/shared/ui';
import { SHOWROOM_FORM_FIELDS, TAddGoodsSchema } from '../hooks';
import { Divider, Drag, LIST_TYPE } from '@/shared/components';
import styles from '@/widgets/animal/animal-crud-popup/styles.module.scss';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  GOODS_CATEGORY,
  useGetCategoriesByMainQuery,
  useGetMainCategoriesQuery,
} from '@/entities/good-category-controller';
import { Flex } from 'antd';
import { convertToSelectOption } from '@/shared/utils';
import { useEffect } from 'react';

const SelectCategories = ({
  index,
  onChange,
  remove,
}: {
  index: number;
  onChange: (index: number, value: number) => void;
  remove: (value: number) => void;
}) => {
  const { watch } = useFormContext<TAddGoodsSchema>();
  const watchParent = watch(`${SHOWROOM_FORM_FIELDS.categories}.${index - 1}`);

  const { data: subCategories, isLoading } = useGetCategoriesByMainQuery(watchParent?.value, {
    skip: !watchParent?.value,
  });

  useEffect(() => {
    if (!isLoading && !subCategories?.children.length) {
      remove(index);
    }
  }, [index, remove, isLoading, subCategories]);

  return subCategories?.children.length ? (
    <Select
      onChange={(value) => onChange(index, value)}
      options={subCategories.children ? convertToSelectOption(subCategories.children) : []}
      controllerProps={{ name: `${SHOWROOM_FORM_FIELDS.categories}.${index}` }}
    />
  ) : null;
};

export const ProductInfo = () => {
  const { getValues, clearErrors, setValue } = useFormContext<TAddGoodsSchema>();
  const { fields, append, remove } = useFieldArray({ name: SHOWROOM_FORM_FIELDS.categories });

  const { data: mainCategories = [] } = useGetMainCategoriesQuery(undefined, {
    selectFromResult: (response) => ({
      ...response,
      data: response.data?.filter(({ id }) => id !== GOODS_CATEGORY.CATS && id !== GOODS_CATEGORY.DOGS),
    }),
  });

  const handleChange = (valueIndex: number, value: number) => {
    const { categories } = getValues();

    const result = categories.reduce((res: { value: number }[], cur, index) => {
      if (index < valueIndex) {
        res.push(cur);
      }
      if (index === valueIndex) {
        res.push({ value });
      }
      return res;
    }, []);

    setValue(SHOWROOM_FORM_FIELDS.categories, result, { shouldValidate: true });
    clearErrors(SHOWROOM_FORM_FIELDS.categories);

    append({});
  };

  return (
    <>
      <Input
        labelProps={{ label: 'Название' }}
        showErrorMsg={false}
        controllerProps={{ name: SHOWROOM_FORM_FIELDS.name }}
      />
      <TextareaInput
        maxLength={5000}
        labelProps={{ isHorizontal: false, label: 'Описание' }}
        controllerProps={{ name: SHOWROOM_FORM_FIELDS.description }}
        style={{ height: 172, resize: 'none', marginBottom: 24 }}
        showCount
        showErrorMsg={false}
      />
      <LabelWrapper label="Категория">
        <Flex vertical gap={16}>
          {fields.map((item, index) =>
            index === 0 ? (
              <Select
                onChange={(value) => handleChange(index, value)}
                options={mainCategories}
                controllerProps={{ name: `${SHOWROOM_FORM_FIELDS.categories}.${index}` }}
                key={item.id}
              />
            ) : (
              <SelectCategories remove={remove} onChange={handleChange} key={item.id} index={index} />
            ),
          )}
        </Flex>
      </LabelWrapper>
      <Divider />
      <Controller
        render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
          <Drag
            labelProps={{ labelWrapperClassName: styles.drag }}
            maxCount={6}
            handleChange={onChange}
            error={!!error}
            fileList={value}
            typeList={LIST_TYPE.IMAGE}
          />
        )}
        name={SHOWROOM_FORM_FIELDS.images}
      />
      <Divider />
    </>
  );
};
