import { Loader } from '@/app/icons';
import { MODALS, setGlobalModal, useTypedDispatch } from '@/app/store';
import { device } from '@/app/theme/device';
import { useGetPostCommentsQuery } from '@/entities/comments-controller';
import { TImageInfoDto } from '@/entities/file-controller';
import {
  TPostReadDto,
  useGetPostByIdQuery,
  usePostPostMutation,
  useUpdatePostMutation,
} from '@/entities/post-controller';
import { useLazyGetAccountQuery, useLazyGetForeignUserQuery } from '@/entities/user-controller';
import { AccountAvatar, TAccountAvatarProps } from '@/features/account';
import { AddComment, Comment } from '@/features/comments';
import { Body2, Button, Divider, Image, Popup, Slider, TextArea } from '@/shared/components';
import { Dropdown } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import { Flex, MenuProps } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';
import { PostActions } from '../PostActions';
import { DeleteModal } from './DeleteModal';
import { HashTagList } from '@/features/hastag-list';

const FORM_FIELDS = {
  description: 'description',
  images: 'images',
} as const;

const ImagesBlock = styled.div`
  width: 100%;
  max-width: 736px;
  .custom-image {
    border-radius: 16px;
  }
`;

const ContentBlock = styled(Flex)`
  flex: 1;
  max-height: 736px;
  margin-top: 8px;

  svg {
    align-self: end;
  }

  @media screen and ${device.tablet} {
    max-height: 600px;

    svg {
      align-self: center;
    }
  }

  @media screen and ${device.mobile} {
    max-height: 400px;

    svg {
      align-self: center;
    }
  }
`;
const DropdownWrapper = styled(Flex)`
  @media screen and ${device.tablet} {
    margin-right: 20px;
  }
  @media screen and ${device.mobile} {
    margin-right: 20px;
  }
`;
const PostWrapper = ({
  title,
  images,
  children,
  onCancel,
  header,
}: {
  title: string;
  images: TImageInfoDto[];
  children: ReactNode;
  header?: ReactNode;
  onCancel: () => void;
}) => {
  const { isDesktop } = useMedia();
  return (
    <Popup
      open
      width={!isDesktop ? 608 : undefined}
      size="LARGE"
      title={title}
      withFooterDivider={false}
      onCancel={onCancel}
    >
      <Flex gap={24} vertical={!isDesktop}>
        {!isDesktop && header}
        <ImagesBlock>
          <Slider arrows={images.length > 1} dots={images.length > 1}>
            {images.map(({ url, id }: TImageInfoDto) => (
              <Image className="rounded-xxs" key={id} src={url} scale={false} />
            ))}
          </Slider>
        </ImagesBlock>
        <ContentBlock gap={16} vertical style={{ overflowY: 'auto' }}>
          {isDesktop && header}
          {children}
        </ContentBlock>
      </Flex>
    </Popup>
  );
};

const Avatar = ({ accountId, post }: { accountId: string; post?: TPostReadDto }) => {
  const [getAccount] = useLazyGetAccountQuery();
  const [getUser] = useLazyGetForeignUserQuery();

  const [info, setInfo] = useState<TAccountAvatarProps | null>(null);

  useEffect(() => {
    const getAvatarInfo = async () => {
      try {
        if (post) {
          setInfo({
            accountInfo: {
              name: `${post.account.accountName} ${post.account.accountSurname}`,
              src: post.account.accountAvatar?.url,
              id: post.account.accountId,
              canEdit: post.canEdit,
            },
            createdAt: post.createdAt,
            nurseryName: post?.nursery?.nameEn || null,
          });
        } else {
          const account = await getAccount(accountId).unwrap();
          const user = await getUser(accountId).unwrap();
          setInfo({
            accountInfo: {
              name: `${user.name} ${user.surname}`,
              src: account.avatar?.url,
              id: account.id,
              canEdit: false,
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    getAvatarInfo();
  }, [getUser, getAccount, accountId, post]);

  if (!info) {
    return <Loader />;
  }
  return <AccountAvatar {...info} />;
};

const validation = z.object({
  [FORM_FIELDS.description]: z.string().nonempty(),
  [FORM_FIELDS.images]: z
    .object({
      id: z.string(),
      url: z.string(),
    })
    .array(),
});

type TSchema = z.infer<typeof validation>;

const CreateUpdate = ({
  onCancel,
  postId,
  defaultValues,
}: {
  onCancel: () => void;
  postId?: string;
  defaultValues: TSchema;
}) => {
  const [createPost, { isLoading: isPostCreating }] = usePostPostMutation();
  const [updatePost, { isLoading: isPostUpdating }] = useUpdatePostMutation();

  const dispatch = useTypedDispatch();

  const formMethods = useForm<TSchema>({
    defaultValues,
    resolver: zodResolver(validation),
  });

  const {
    handleSubmit,
    formState: { isDirty },
    reset,
  } = formMethods;

  const onSubmit = async (data: TSchema) => {
    try {
      const { description, images } = data;
      const imageIds = images.map((item) => item.id);
      const response = postId
        ? await updatePost({ imageIds, description, id: postId }).unwrap()
        : await createPost({ imageIds, description }).unwrap();
      dispatch(setGlobalModal(postId ? MODALS.POST_CHANGED : MODALS.POST_CREATED));
      reset({ images: response.images, description: response.description });
      onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Divider />
      <Controller
        render={({ field }) => (
          <TextArea
            {...field}
            isBlank
            style={{ marginBottom: 10 }}
            autoSize={{ maxRows: 5 }}
            showCount
            maxLength={250}
            placeholder="Добавьте подпись"
          />
        )}
        name={FORM_FIELDS.description}
      />
      <Flex vertical gap={16} style={{ marginTop: 'auto' }}>
        <Divider />
        <Flex justify="space-between">
          <Button
            type="default"
            onClick={() => {
              reset();
              onCancel();
            }}
          >
            Отменить
          </Button>
          <Button loading={isPostCreating || isPostUpdating} disabled={!isDirty} onClick={handleSubmit(onSubmit)}>
            {postId ? 'Сохранить изменения' : 'Опубликовать'}
          </Button>
        </Flex>
      </Flex>
    </FormProvider>
  );
};

type TProps = {
  createPostProps?: {
    accountId: string;
    images: TImageInfoDto[];
  };
  post?: TPostReadDto;
  onCancel: () => void;
};

enum ACTIONS {
  EDIT,
  DELETE,
}

const CommentPost = ({ post }: { post: TPostReadDto }) => {
  const { likeCount, likedByCurrent, id: postId } = post;
  const [isAddComment, setIsAddComment] = useState<boolean>(false);

  const { data: comments = [] } = useGetPostCommentsQuery(postId);

  return (
    <>
      <Divider />
      <Flex vertical gap={24} style={{ overflowY: 'auto', paddingRight: 10 }}>
        {comments.map((item) => (
          <Comment key={item.id} data={item} />
        ))}
      </Flex>
      <Flex vertical gap={16} style={{ marginTop: 'auto' }}>
        <Divider />
        <PostActions
          messageAction={() => setIsAddComment(!isAddComment)}
          postId={postId}
          likedByCurrent={likedByCurrent}
          likeCount={likeCount}
        />
        {isAddComment && (
          <>
            <Divider />
            <AddComment postId={postId} />
          </>
        )}
      </Flex>
    </>
  );
};
const ViewEditPost = ({ initialPostData, onCancel }: { initialPostData: TPostReadDto; onCancel: () => void }) => {
  const { data: post = initialPostData } = useGetPostByIdQuery(initialPostData.id);
  const {
    images,
    account: { accountId },
    canEdit,
    description,
    hashTags,
  } = post;

  const [actions, setActions] = useState<ACTIONS | null>(null);

  const resetActions = () => setActions(null);

  const items: MenuProps['items'] = [
    {
      key: 'delete',
      label: 'Удалить пост ',
      onClick: () => setActions(ACTIONS.DELETE),
    },
    {
      key: 'edit',
      label: 'Редактировать',
      onClick: () => setActions(ACTIONS.EDIT),
    },
  ];

  const isEditMode = ACTIONS.EDIT === actions;
  const isOpenDelete = ACTIONS.DELETE === actions;

  const isShowActions = canEdit && !isEditMode;

  const title = isEditMode ? 'Редактировать публикацию' : '';

  return (
    <PostWrapper
      title={title}
      onCancel={onCancel}
      images={images}
      header={
        <Flex vertical={false} justify="space-between" gap={10}>
          <Avatar accountId={accountId} post={post} />
          <DropdownWrapper justify="space-between" align="center">
            {isShowActions && <Dropdown menu={{ items }} placement="bottomRight" />}
          </DropdownWrapper>
        </Flex>
      }
    >
      {!isEditMode && <Body2 styles={{ wordBreak: 'break-all' }}>{description}</Body2>}
      {isEditMode && (
        <CreateUpdate
          postId={post.id}
          defaultValues={{ [FORM_FIELDS.images]: images, [FORM_FIELDS.description]: description }}
          onCancel={resetActions}
        />
      )}
      {isOpenDelete && (
        <DeleteModal accountId={accountId} postId={post?.id} onClose={resetActions} onDelete={onCancel} />
      )}
      {!!hashTags?.length && <HashTagList hashTags={hashTags} />}
      {!isEditMode && !isOpenDelete && <CommentPost post={post} />}
    </PostWrapper>
  );
};

const CreatePost = ({
  accountId,
  images,
  onCancel,
}: {
  accountId: string;
  images: TImageInfoDto[];
  onCancel: () => void;
}) => {
  const title = 'Добавить медиа файлы';
  return (
    <PostWrapper title={title} images={images} onCancel={onCancel}>
      <Avatar accountId={accountId} />
      <CreateUpdate
        defaultValues={{ [FORM_FIELDS.images]: images, [FORM_FIELDS.description]: '' }}
        onCancel={onCancel}
      />
    </PostWrapper>
  );
};

export const PostModal = ({ createPostProps, post, onCancel }: TProps) => {
  if (createPostProps) {
    return <CreatePost {...createPostProps} onCancel={onCancel} />;
  }

  if (post) {
    return <ViewEditPost initialPostData={post} onCancel={onCancel} />;
  }
};
