import { PasswordEyeIcon, PasswordEyeSlashIcon } from '@/app/icons';
import { device } from '@/app/theme/device';
import { ErrorMessage, LabelWrapper, TLabelProps } from '@/shared/components';
import { Input as AntInput, Flex, InputProps, InputRef } from 'antd';
import { ForwardedRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { ValidationText } from './ValidationText';
import {
  CAPITAL_LETTER,
  DIGITS,
  LIMIT_SYMBOLS,
  LOWER_LETTER,
  SYMBOLS,
  passwordCapitalLetterRule,
  passwordDigitRule,
  passwordLowerLetterRule,
  passwordMinMaxRule,
  passwordSymbolRule,
} from './constants';
import { theme } from '@/app/theme';

const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const StyledInputPasswordShowIcon = styled(PasswordEyeIcon)<{ $error: boolean }>`
  color: ${({ theme }) => theme.palette.neutral[2]};
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.primary[3]};
    `}

  @media ${device.tablet} {
    width: 16px;
    height: 16px;
  }
`;

const StyledInputPasswordHideIcon = styled(PasswordEyeSlashIcon)<{ $error: boolean }>`
  color: ${({ theme }) => theme.palette.neutral[2]};
  ${({ $error }) =>
    $error &&
    css`
      color: ${({ theme }) => theme.palette.primary[3]};
    `}

  @media ${device.tablet} {
    width: 16px;
    height: 16px;
  }
`;

type TProps = InputProps & {
  errorMsg?: string;
  hasError?: boolean;
  labelProps?: TLabelProps;
  rule?: { isValid: boolean; label: string }[];
  showTips?: boolean;
};

const passwordRulesCheck = (value: string) => [
  { isValid: passwordMinMaxRule.safeParse(value).success, label: LIMIT_SYMBOLS },
  { isValid: passwordLowerLetterRule.safeParse(value).success, label: LOWER_LETTER },
  { isValid: passwordCapitalLetterRule.safeParse(value).success, label: CAPITAL_LETTER },
  { isValid: passwordDigitRule.safeParse(value).success, label: DIGITS },
  { isValid: passwordSymbolRule.safeParse(value).success, label: SYMBOLS },
];

export const InputPassword = forwardRef(
  (
    { width, labelProps, errorMsg, showTips = true, hasError, value, rule, ...props }: TProps,
    ref: ForwardedRef<InputRef | null>,
  ) => {
    const status = errorMsg || hasError ? 'error' : undefined;
    const validation = rule ?? passwordRulesCheck(value as string);

    const renderPasswordIcon = (visible: boolean) => {
      const Icon = visible ? StyledInputPasswordShowIcon : StyledInputPasswordHideIcon;
      return (
        <IconContainer>
          <Icon $error={!!status} />
        </IconContainer>
      );
    };

    return (
      <LabelWrapper style={{ width }} {...labelProps}>
        <Flex vertical>
          <AntInput.Password
            ref={ref}
            size="large"
            status={status}
            iconRender={renderPasswordIcon}
            value={value}
            {...props}
          />
          {errorMsg && <ErrorMessage message={errorMsg} />}
          {showTips && (
            <Flex vertical style={{ marginTop: 8 }}>
              {validation.map(({ isValid, label }) => (
                <ValidationText
                  color={status ? theme.palette.primary[3] : theme.palette.neutral[4]}
                  key={label}
                  isValid={isValid}
                  label={label}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </LabelWrapper>
    );
  },
);
