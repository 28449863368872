import { Drawer as AntDrawer, DrawerProps } from 'antd';
import { ModalCloseIcon } from '@/app/icons';
import styles from './styles.module.scss';
import cn from 'classnames';
import { useMedia } from '@/shared/hooks';

export type TDrawerProps = DrawerProps & {
  onCancel: () => void;
};

export const Drawer = ({ className, width = 400, onCancel, ...props }: TDrawerProps) => {
  const { isMobile } = useMedia();

  const resWidth = isMobile ? '100vw' : width;
  return (
    <AntDrawer
      className={cn(styles['custom-drawer'], className)}
      rootClassName={styles['custom-root']}
      closeIcon={<ModalCloseIcon />}
      width={resWidth}
      placement="right"
      {...props}
      onClose={onCancel}
    />
  );
};
