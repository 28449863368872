import { OutlineClose, Success } from '@/app/icons';
import { Popup } from '@/shared/components';
import { Body2, Headline6 } from '@/shared/ui';
import { Flex } from 'antd';

type TProps = {
  onConfirm?: () => void;
  onReject?: () => void;
  onClose: () => void;
};

export const ConfirmNurseryModal = ({ onConfirm, onReject, onClose }: TProps) => {
  return (
    <Popup
      title="Подтвердите владение питомником"
      open
      onCancel={onClose}
      okButtonProps={{
        children: 'Подтвердить',
        onClick: onConfirm,
      }}
      cancelButtonProps={{
        children: 'Отказаться',
        onClick: onReject,
      }}
    >
      <div>
        <Headline6>Этот питомник найден в базе. Подтвердите, что он принадлежит вам.</Headline6>
        <Flex align="center">
          <Success />
          <Body2>Если вы подтверждаете владение, питомник и все его животные будут закреплены за вами.</Body2>
        </Flex>

        <Flex align="center">
          <OutlineClose color="red" />
          <Body2> Если вы откажетесь, данные останутся в базе, но не будут привязаны к вашему профилю.</Body2>
        </Flex>
      </div>
    </Popup>
  );
};
