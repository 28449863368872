import { Flex, Radio as AtnRadio, RadioGroupProps } from 'antd';
import { Radio } from './Radio';
import { Controller } from 'react-hook-form';
import { TControllerProps } from '@/shared/common';
import { LabelWrapper, TLabelProps } from '@/shared/ui';

type TProps = Omit<RadioGroupProps, 'options'> & {
  options?: { value: string | number | boolean; label: string }[];
  vertical?: boolean;
  labelProps?: TLabelProps;
};

export type TRadioGroupProps = TProps & {
  controllerProps?: TControllerProps;
};

const RadioGropuComponent = ({ options, labelProps, vertical, ...props }: TProps) => (
  <LabelWrapper {...labelProps}>
    <AtnRadio.Group {...props}>
      <Flex vertical={vertical} gap={vertical ? 6 : 0}>
        {options?.map(({ value, label }) => (
          <Radio key={label} value={value}>
            {label}
          </Radio>
        ))}
      </Flex>
    </AtnRadio.Group>
  </LabelWrapper>
);

export const RadioGroup = ({ controllerProps, ...props }: TRadioGroupProps) => {
  if (!controllerProps) {
    return <RadioGropuComponent {...props} />;
  }

  return <Controller render={({ field }) => <RadioGropuComponent {...field} {...props} />} {...controllerProps} />;
};
