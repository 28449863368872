import { Headline4, Headline6 } from '@/shared/ui';
import { cityConverter } from '@/shared/utils';
import { Flex } from 'antd';
import { useScrollTop } from '@/shared/hooks';
import { TGoodDtoExt } from '@/entities/goods-controller';

export const GoodsTitle = ({ data }: { data: TGoodDtoExt }) => {
  const scrollRef = useScrollTop();

  const { animal, city } = data;

  if (!animal) {
    return null;
  }

  const {
    nursery: { nameEn },
    fullName,
  } = animal;

  return (
    <Flex ref={scrollRef} justify="space-between" wrap="wrap" align="center">
      <Headline4>
        {fullName} {nameEn}
      </Headline4>
      {city && <Headline6>{cityConverter(city.name)}</Headline6>}
    </Flex>
  );
};
