import { Tabs, TTabsProps } from '@/shared/components';
import { SHOP_PATHS } from 'src/app/navigation/shop';
import { Outlet, useLocation } from 'react-router-dom';
import { FlexPage } from '@/shared/containers';
import './styles.scss';

export const ShopPage = () => {
  const { pathname } = useLocation();

  const items: TTabsProps['items'] = [
    { key: SHOP_PATHS.shop, label: 'Консоль' },
    { key: SHOP_PATHS.catalog, label: 'Каталог' },
  ];

  const getActiveKey = () => {
    if (pathname.includes(SHOP_PATHS.catalog)) {
      return SHOP_PATHS.catalog;
    }

    return pathname;
  };

  return (
    <FlexPage>
      <Tabs className="shop-page-tabs" activeKey={getActiveKey()} items={items} />
      <Outlet />
    </FlexPage>
  );
};
