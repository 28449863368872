import { TAnimalDto } from '@/entities/animal-controller';
import { z } from 'zod';
import { useForm } from 'react-hook-form';

export const FORM_FIELDS = {
  pedigree: 'pedigreeId',
  liveCountryId: 'liveCountryId',
  birthCountryId: 'birthCountryId',
  description: 'description',
  shortName: 'shortName',
  tribalTypes: 'tribalTypes',
  dateDead: 'dateDead',
  isShowDeadDate: 'isShowDeadDate',
  hashTags: 'hashTags',
} as const;

export const animalInfoSchema = z.object({
  [FORM_FIELDS.pedigree]: z
    .object({
      id: z.string(),
      url: z.string(),
      uid: z.string(),
      name: z.string(),
    })
    .array()
    .optional(),
  [FORM_FIELDS.description]: z.string().optional(),
  [FORM_FIELDS.shortName]: z.string().optional(),
  [FORM_FIELDS.birthCountryId]: z.number(),
  [FORM_FIELDS.liveCountryId]: z.number(),
  [FORM_FIELDS.tribalTypes]: z.number().array(),
  [FORM_FIELDS.isShowDeadDate]: z.boolean(),
  [FORM_FIELDS.dateDead]: z.string().optional(),
  [FORM_FIELDS.hashTags]: z.string().array().optional(),
});

export type TAnimalInfoSchema = z.infer<typeof animalInfoSchema>;

export const useAnimalInfoForm = (defaultValue: Partial<TAnimalDto>) => {
  return useForm<TAnimalInfoSchema>({
    defaultValues: {
      [FORM_FIELDS.shortName]: defaultValue.shortName,
      [FORM_FIELDS.birthCountryId]: defaultValue.birthCountry?.id,
      [FORM_FIELDS.liveCountryId]: defaultValue.liveCountry?.id,
      [FORM_FIELDS.description]: defaultValue.description,
      [FORM_FIELDS.tribalTypes]: defaultValue.tribalTypes?.map((item) => item.id),
      [FORM_FIELDS.dateDead]: defaultValue.dateDead,
      [FORM_FIELDS.pedigree]: defaultValue.pedigree ? [defaultValue.pedigree] : [],
      [FORM_FIELDS.hashTags]: defaultValue.hashTags,
    },
  });
};
