import { Flex, Progress } from 'antd';
import { Body2, Body3, Headline4 } from '@/shared/ui';
import { ManagementCheckedIcon, ManagementClockIcon } from '@/app/icons';
import cn from 'classnames';
import { Divider } from '@/shared/components';
import { queries } from '@/shared/utils';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { useGetGoodsQuery } from '@/entities/goods-controller';
import { PublishMarketplaceButton } from '@/features/buttons';
import { ArrowRight } from '@/app/icons/ArrowRight';
import './styles.scss';
import { ReactNode } from 'react';

const Icon = ({ status }: { status: 'done' | 'development' | 'pending' }) => (
  <Flex vertical align="center" className="clock-icon lg:self-center w-[20px] lg:w-[64px]">
    {status === 'development' ? (
      <>
        <ManagementClockIcon width={20} height={20} className="w-full lg:min-w-[40px] lg:min-h-[40px]" />
        <div className="hidden lg:flex font-[500] text-[9px] leading-[11px] text-[#EFAF1A]">В разработке</div>
      </>
    ) : (
      <ManagementCheckedIcon
        width={20}
        height={20}
        className={cn('lg:min-w-[40px] lg:min-h-[40px]', {
          'text-primary4': status === 'done',
          'text-neutral6': status === 'pending',
        })}
      />
    )}
  </Flex>
);

export const DevelopmentSteps = () => {
  const accountId = useTypedSelector(selectCurrentAccountId);
  const query = queries.equal('account.id', String(accountId));
  const { data } = useGetGoodsQuery({ query });

  const items: {
    status: 'done' | 'development' | 'pending';
    title: string;
    description: string;
    action?: ReactNode;
  }[] = [
    {
      title: 'Добавить новый товар',
      description:
        'Ваши клиенты ждут! Чем больше товаров, тем больше шансов быть замеченным. Добавьте хотя бы один новый товар уже сегодня',
      status: data?.totalElements ? 'done' : 'pending',
      action: (
        <PublishMarketplaceButton
          buttonProps={{
            type: 'default',
            className: 'publish-btn',
            icon: <ArrowRight className="hover:scale-[1.1] transition ease-in-out w-[16px] lg:w-[24px]" />,
          }}
        />
      ),
    },
    {
      title: 'Улучшить фотографии',
      description:
        'Качественные изображения повышают доверие. Загрузите новые фото, чтобы ваши товары выглядели лучше!',
      status: 'pending',
    },
    {
      title: 'Применить специальные предложения и скидки',
      description:
        'Привлекайте покупателей, создавая временные скидки и акции на ваши товары. Специальные предложения помогают стимулировать спрос и выделяют ваш магазин среди конкурентов',
      status: 'pending',
    },
    {
      title: 'Встройте лендинг с актуальными  товарами',
      description:
        'Добавьте блок с товарами на ваш сайт. Управляйте ассортиментом и обновляйте информацию прямо в нашей системе — ваш лендинг всегда будет актуальным.',
      status: 'development',
    },
    {
      title: 'Улучшить SEO товаров',
      description:
        'Повышайте видимость вашего товара в поисковых системах, добавив ключевые слова, точные описания и уникальные заголовки. Это поможет вам привлечь больше клиентов из органического поиска',
      status: 'development',
    },
    {
      title: 'Настроить рекламные кампании',
      description:
        'Привлекайте больше покупателей! Настройте рекламу и делитесь своими товарами в соцсетях и на других платформах',
      status: 'development',
    },
  ];

  const percent = data?.totalElements ? 14 : 0;

  return (
    <Flex
      vertical
      className="p-0 border-0 items-center gap-[20px] lg:gap-[24px] lg:items-start lg:p-md lg:border lg:border-neutral6 lg:rounded-xs"
    >
      <Headline4 className="lg:text-headline2 lg:font-bold">Готовы развивать свой магазин?</Headline4>
      <Body2 className="mt-0">
        Ваш магазин уже запущен, и это только начало! Используйте наш чек-лист для успешного роста
      </Body2>
      <Flex vertical>
        <Progress percent={percent} showInfo={false} status="success" size="small" />
        <Body3 className="text-neutral4">
          Ваш магазин готов на <span className="font-semibold text-body2">{percent}%</span>
        </Body3>
        <Body3 className="text-neutral4">Завершите настройку, чтобы получить максимум от продаж</Body3>
      </Flex>
      <Flex className={cn('list-items')} vertical>
        {items.map(({ title, status, description, action }, index) => (
          <>
            {!!index && <Divider />}
            <Flex key={title} align="center" gap={16} className="item py-[12px]">
              <Icon status={status} />
              <Flex vertical className="flex-1">
                <Body3 className={cn({ 'text-neutral4': status === 'done' }, 'font-semibold lg:text-headline6')}>
                  {title}
                </Body3>
                <Body3 className={cn({ 'text-neutral4': status === 'done' }, 'lg:text-body2')}>{description}</Body3>
              </Flex>
              {action}
            </Flex>
          </>
        ))}
      </Flex>
      <Flex vertical>
        <Body3 className="mx-auto font-semibold lg:text-headline4">Ваш магазин - Ваши правила!</Body3>
        <Body3 className="text-center lg:text-start lg:text-body2">
          Следуйте этим шагам, и вы увидите, как растет интерес к вашим товарам и увеличиваются продажи!
        </Body3>
      </Flex>
    </Flex>
  );
};
