import { TGoodDto } from '@/entities/goods-controller';
import React from 'react';
import { Card, Button } from '@/shared/ui';
import { useFavouriteGood } from '@/shared/hooks/goods';
import { useGoodCard } from './hooks';

type TProps = {
  good: TGoodDto;
  onClick: (good: TGoodDto) => void;
};

export const GoodCard = ({ good, onClick }: TProps) => {
  const { animal, product, canEdit } = good;

  const { callback, isLoading, text, icon } = useFavouriteGood(good);
  const cardProps = useGoodCard(good);

  if (!animal && !product) {
    return null;
  }

  return (
    <Card
      onClick={() => onClick(good)}
      hoverable
      actionIcon={canEdit ? undefined : icon}
      actions={
        canEdit
          ? undefined
          : [
              <Button
                loading={isLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  return callback();
                }}
                key="button"
                size="unset"
                className="w-full rounded-[4px] p-[4px]"
              >
                <span className="font-semibold uppercase text-[10px]">{text}</span>
              </Button>,
            ]
      }
      {...cardProps}
    />
  );
};
