import { Warning } from '@/app/icons';
import { selectIsBreeder, useTypedSelector } from '@/app/store';
import { CitySelect, CountrySelect, CurrencySelect } from '@/features/select';
import { Divider } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import {
  Body2,
  Headline4,
  Input,
  LabelWrapper,
  NumberInput,
  PhoneInput,
  Popup,
  RadioGroup,
  TPopupProps,
} from '@/shared/ui';
import { Flex } from 'antd';
import { ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { SHOWROOM_FORM_FIELDS, TAddGoodsSchema, useShowroomForm } from './hooks';

import { useModal } from '@/shared/hooks';

import { TGoodDto } from '@/entities/goods-controller';
import { AnimalInfo } from './animal';
import { ProductInfo } from './product';

type TProps = TPopupProps & {
  defaultValues?: Partial<TAddGoodsSchema>;
  data?: TGoodDto;
};

export const OPTIONS = [
  { value: false, label: 'Не осуществляю' },
  { value: true, label: 'Осуществляю' },
];

const Block = ({
  children,
  subtitle,
  title,
  className,
}: {
  children: ReactNode;
  title: string;
  subtitle?: string;
  className?: string;
}) => (
  <Flex vertical gap={className ? 0 : 16} className={className}>
    <Flex vertical>
      <Headline4>{title}</Headline4>
      {subtitle && (
        <Flex gap={8} align="center">
          <Warning className="text-neutral4" />
          <Body2 className="text-neutral4">{subtitle}</Body2>
        </Flex>
      )}
    </Flex>
    <Flex vertical gap={24}>
      {children}
    </Flex>
  </Flex>
);

export const AddShowroomProductModal = ({ defaultValues, open, onCancel, data, ...props }: TProps) => {
  const { isOpen } = useModal(open);
  const isBreeder = useTypedSelector(selectIsBreeder);

  const { formMethods, onFormSubmit, isLoading } = useShowroomForm({
    defaultValues,
    onClose: onCancel,
    data,
  });

  const { setValue, watch } = formMethods;
  const watchCountry = watch(SHOWROOM_FORM_FIELDS.country);

  const handleChangeCountry = (value: number) => {
    setValue(SHOWROOM_FORM_FIELDS.country, value, { shouldValidate: true });
    setValue(SHOWROOM_FORM_FIELDS.city, 0);
  };

  const title = `Информация о ${isBreeder ? 'питомце' : 'товаре'}`;

  return (
    <Popup
      size="MEDIUM"
      title={data ? 'Редактирование карточки' : 'Публикация на продажу'}
      cancelText="Отменить"
      okText={data ? 'Сохранить' : 'Опубликовать'}
      onOk={onFormSubmit}
      okButtonProps={{ loading: isLoading }}
      open={isOpen}
      onCancel={onCancel}
      {...props}
    >
      <FormProvider {...formMethods}>
        <FlexPage>
          <Block title={title}>
            {isBreeder && <AnimalInfo animal={data?.animal} />}
            {!isBreeder && <ProductInfo />}
            <LabelWrapper label="Цена">
              <Flex gap={16} className="w-full">
                <NumberInput
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.price }}
                  decimalLimit={2}
                  showErrorMsg={false}
                />
                <CurrencySelect controllerProps={{ name: SHOWROOM_FORM_FIELDS.currency }} />
              </Flex>
            </LabelWrapper>
          </Block>
          <Divider />
          <Block
            className={data ? 'block-wrapper' : ''}
            title={data ? '' : 'Ваши контактные данные'}
            subtitle={data ? '' : 'Данная информация будет доступна другим пользователям'}
          >
            <PhoneInput controllerProps={{ name: SHOWROOM_FORM_FIELDS.phone }} labelProps={{ label: 'Телефон' }} />
            <Input controllerProps={{ name: SHOWROOM_FORM_FIELDS.email }} labelProps={{ label: 'E-mail' }} />
          </Block>
          <Divider />
          <Block title="Настройки доставки">
            <RadioGroup
              className="w-full"
              labelProps={{ label: 'Доставка' }}
              options={OPTIONS}
              controllerProps={{ name: SHOWROOM_FORM_FIELDS.delivery }}
            />
            <LabelWrapper label="Местоположение">
              <Flex vertical gap={12} className="w-full">
                <CountrySelect
                  labelProps={{ label: 'Страна', isHorizontal: false }}
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.country }}
                  onChange={handleChangeCountry}
                />
                <CitySelect
                  countryId={watchCountry}
                  labelProps={{ label: 'Город', isHorizontal: false }}
                  controllerProps={{ name: SHOWROOM_FORM_FIELDS.city }}
                />
              </Flex>
            </LabelWrapper>
          </Block>
        </FlexPage>
      </FormProvider>
    </Popup>
  );
};
