import { MARKETPLACE_FILTER_FIELDS, TMarketplaceSchema } from './hooks';
import { Flex, RadioChangeEvent } from 'antd';
import { BreedsSelect, CitySelect, CountrySelect } from '@/features/select';
import { LabelWrapper } from '@/shared/ui';
import { useFormContext } from 'react-hook-form';
import { SpeciesRadioGroup } from '@/features/radio-group';
import { useParams } from 'react-router-dom';

export const FilterForm = () => {
  const { watch, setValue } = useFormContext<TMarketplaceSchema>();
  const { catalogId } = useParams();
  const [watchCountry, watchSpecies] = watch([MARKETPLACE_FILTER_FIELDS.country, MARKETPLACE_FILTER_FIELDS.species]);

  const handleChangeCountry = (value: number) => {
    setValue(MARKETPLACE_FILTER_FIELDS.country, value, { shouldDirty: true });
    setValue(MARKETPLACE_FILTER_FIELDS.city, null, { shouldDirty: true });
  };

  const handleChangeSpecies = (event: RadioChangeEvent) => {
    const { value } = event.target;
    setValue(MARKETPLACE_FILTER_FIELDS.species, value, { shouldDirty: true });
    setValue(MARKETPLACE_FILTER_FIELDS.breed, null, { shouldDirty: true });
  };

  return (
    <Flex vertical gap={24}>
      {/* waiting for the implementation with currency from backend */}
      {/* <LabelWrapper secondary label="Цена" isHorizontal={false} contentClassName="flex flex-row gap-xs"> */}
      {/*   <NumberInput controllerProps={{ name: MARKETPLACE_FILTER_FIELDS.from }} /> */}
      {/*   <NumberInput controllerProps={{ name: MARKETPLACE_FILTER_FIELDS.to }} /> */}
      {/* </LabelWrapper> */}
      <LabelWrapper secondary label="Местоположение" isHorizontal={false}>
        <Flex vertical gap={12} className="w-full">
          <CountrySelect
            labelProps={{ label: 'Страна', isHorizontal: false }}
            controllerProps={{ name: MARKETPLACE_FILTER_FIELDS.country }}
            onChange={handleChangeCountry}
          />
          <CitySelect
            countryId={watchCountry || 0}
            labelProps={{ label: 'Город', isHorizontal: false }}
            controllerProps={{ name: MARKETPLACE_FILTER_FIELDS.city }}
          />
        </Flex>
      </LabelWrapper>
      <LabelWrapper secondary label="Характеристики животного" isHorizontal={false}>
        <Flex vertical gap={12} className="w-full mt-xxs">
          <SpeciesRadioGroup
            disabled={!!catalogId}
            onChange={handleChangeSpecies}
            controllerProps={{ name: MARKETPLACE_FILTER_FIELDS.species }}
          />
          <BreedsSelect
            speciesId={watchSpecies}
            disabled={!watchSpecies}
            labelProps={{ label: 'Порода', isHorizontal: false }}
            controllerProps={{ name: MARKETPLACE_FILTER_FIELDS.breed }}
          />
        </Flex>
      </LabelWrapper>
    </Flex>
  );
};
