import { useParams, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { comparisonNode, queries } from '@/shared/utils';
import { MARKETPLACE_FILTER_FIELDS } from '@/widgets/marketplace/filter';

export const useGetFilterQuery = () => {
  const { catalogId } = useParams();
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const arr = [];

    if (catalogId) {
      arr.push(comparisonNode.equal('category.id', catalogId));
    }

    const country = searchParams.get(MARKETPLACE_FILTER_FIELDS.country);
    if (country) {
      arr.push(comparisonNode.equal('city.country.id', country));
    }

    const city = searchParams.get(MARKETPLACE_FILTER_FIELDS.city);
    if (city) {
      arr.push(comparisonNode.equal('city.id', city));
    }

    const breed = searchParams.get(MARKETPLACE_FILTER_FIELDS.breed);
    if (breed) {
      arr.push(comparisonNode.equal('animal.breed.id', breed));
    }

    const species = searchParams.get(MARKETPLACE_FILTER_FIELDS.species);
    if (species) {
      arr.push(comparisonNode.equal('animal.species.id', species));
    }

    return arr.length ? queries.and(arr) : '';
  }, [searchParams, catalogId]);
};
