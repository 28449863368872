import { TRoute } from '@/app/navigation';
import { useParams } from 'react-router-dom';
import { AnimalPage } from '@/pages';

export const ANIMAL_PATHS = {
  racy: '/racy',
} as const;

const AnimalPageRoute = () => {
  const { id } = useParams();

  return <AnimalPage key={id} />;
};

export const ANIMAL_ROUTES: TRoute = {
  path: `${ANIMAL_PATHS.racy}/:id`,
  element: <AnimalPageRoute />,
};
