import { MODALS, selectCurrentAccountId, setGlobalModal, useTypedDispatch } from '@/app/store';
import { TAnimalDto, animalApi } from '@/entities/animal-controller';
import { useDeleteGoodsMutation } from '@/entities/goods-controller';
import { useModal } from '@/shared/hooks';
import { Button, TButtonProps } from '@/shared/ui';
import { AddShowroomProductModal } from '@/widgets/show-room';
import { useSelector } from 'react-redux';

type TProps = {
  animal?: Partial<TAnimalDto>;
  buttonProps: TButtonProps & {
    text?: string;
  };
};

export const PublishMarketplaceButton = ({ animal, buttonProps }: TProps) => {
  const goodId = animal?.goodId;
  const { isOpen: isPublishOpen, close: closePublish, open: openPublish } = useModal();
  const [deleteGoods, { isLoading }] = useDeleteGoodsMutation();
  const dispatch = useTypedDispatch();
  const accountId = useSelector(selectCurrentAccountId);

  const buttonHandler = async () => {
    if (!accountId) {
      dispatch(setGlobalModal(MODALS.AUTH));
      return;
    }
    if (goodId) {
      try {
        await deleteGoods(goodId);
        dispatch(animalApi.util.invalidateTags([{ type: 'ANIMAL', id: animal?.id }]));
      } catch (e) {
        console.error(e);
      }
    } else {
      openPublish();
    }
  };

  return (
    <>
      <Button loading={isLoading} disabled={isLoading} onClick={buttonHandler} {...buttonProps}>
        {buttonProps?.text}
      </Button>
      {isPublishOpen && (
        <AddShowroomProductModal
          open={isPublishOpen}
          onCancel={closePublish}
          defaultValues={{ animal: animal?.id, country: animal?.liveCountry?.id, city: animal?.city?.id }}
        />
      )}
    </>
  );
};
