import { TAccountDto, TUserDto } from '@/entities/user-controller';
import { Flex } from 'antd';
import { Body2 } from '@/shared/components';
import { SubscribeButton } from '@/features/buttons';
import { AccountAvatar } from '@/features/account';

type TProps = {
  account: TAccountDto;
  user: TUserDto;
};

export const AccountHeader = ({ account, user }: TProps) => {
  const { avatar, postsCount, subscribersCount, subscriptionsCount, nursery, id, canEdit, subscribed } = account;
  const { name = '', surname = '' } = user;

  return (
    <Flex vertical gap={12}>
      <Flex justify="space-between" align="center">
        <AccountAvatar
          accountInfo={{ src: avatar?.url, name: `${name} ${surname}`, id, canEdit }}
          nurseryName={nursery && nursery.nameEn}
        />
        {!canEdit && <SubscribeButton accountId={id} subscribed={subscribed} />}
      </Flex>
      <Flex justify="space-between">
        <Body2>
          <b>{postsCount}</b> публикаций
        </Body2>
        <Body2>
          <b>{subscribersCount}</b> подписчиков
        </Body2>
        <Body2>
          <b>{subscriptionsCount}</b> подписок
        </Body2>
      </Flex>
    </Flex>
  );
};
