import { useMedia } from '@/shared/hooks';
import { MobileView } from './MobileView';
import { DesktopView } from './DesktopView';
import { TGoodDtoExt } from '@/entities/goods-controller';

export const ProductGoods = ({ data }: { data: TGoodDtoExt }) => {
  const { isMobile } = useMedia();

  return isMobile ? <MobileView data={data} /> : <DesktopView data={data} />;
};
