import {
  AsyncSelect,
  BadgeInput,
  Button,
  Checkbox,
  Datepicker,
  Input,
  LabelWrapper,
  Popup,
  TAsyncSelectValue,
  TPopupProps,
} from '@/shared/ui';
import {
  AnimalSexSelect,
  AnimalStatusSelect,
  BreedsSelect,
  CitySelect,
  CountrySelect,
  SystemRegistrationSelect,
} from '@/features/select';
import { Flex } from 'antd';

import { Controller, FormProvider } from 'react-hook-form';
import { Divider, Drag, LIST_TYPE } from '@/shared/components';
import { TNurseryDto, useGetNurseriesQuery } from '@/entities/nursery-controller';
import { comparisonNode, queries } from '@/shared/utils';
import dayjs, { Dayjs } from 'dayjs';
import { SpeciesColors } from '@/features/animal/species-colors';

import { ANIMAL_FORM_FIELDS, TAnimalFormProps, useAnimalForm } from './hooks';
import { AddNurseryModal, NURSERY_FORM_FIELDS } from '@/features/nursery';
import { useModal } from '@/shared/hooks';
import { SPECIES } from '@/entities/species-controller';

import styles from './styles.module.scss';

export enum OWNER {
  EXTERNAL,
  INTERNAL,
}

type TProps = TPopupProps &
  TAnimalFormProps & {
    owner?: OWNER;
    disabledFields?: (keyof typeof ANIMAL_FORM_FIELDS)[];
  };

export const AnimalCrudPopup = ({
  speciesId,
  defaultValues,
  schema,
  onSubmitFinish,
  open,
  owner = OWNER.INTERNAL,
  disabledFields = [],
  ...props
}: TProps) => {
  const { formMethods, onFormSubmit, isLoading } = useAnimalForm({ defaultValues, speciesId, onSubmitFinish, schema });
  const { isOpen: isOpenAddNursery, open: openAddNursery, close: closeAddNursery } = useModal();

  const { setValue, watch } = formMethods;

  const [
    isShowChipIdWatch,
    isShowStampIdWatch,
    watchRegisterSystem,
    dateBirthWatch,
    isShowDeathDate,
    watchLiveCountry,
  ] = watch([
    ANIMAL_FORM_FIELDS.isShowChipId,
    ANIMAL_FORM_FIELDS.isShowStampId,
    ANIMAL_FORM_FIELDS.animalRegistrationSystemId,
    ANIMAL_FORM_FIELDS.dateBirth,
    ANIMAL_FORM_FIELDS.isShowDeadDate,
    ANIMAL_FORM_FIELDS.liveCountryId,
  ]);

  const isFieldDisabled = (field: keyof typeof ANIMAL_FORM_FIELDS) => disabledFields.includes(field);

  const handleChangeRegisterSystem = (value: number) => {
    setValue(ANIMAL_FORM_FIELDS.animalRegistrationSystemId, value, { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.nursery, null);
    setValue(ANIMAL_FORM_FIELDS.breederName, '');
    setValue(ANIMAL_FORM_FIELDS.breedId, 0);
  };

  const handleChangeLiveCountry = (value: number) => {
    setValue(ANIMAL_FORM_FIELDS.liveCountryId, value, { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.cityId, 0);
  };

  const handleChangeNursery = (value: TAsyncSelectValue, option: TNurseryDto) => {
    const { account, breeds } = option;
    setValue(ANIMAL_FORM_FIELDS.nursery, value, { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.breederName, account.name || '', { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.breedId, breeds[0].id, { shouldValidate: true });

    if (owner === OWNER.EXTERNAL) {
      setValue(ANIMAL_FORM_FIELDS.owner, account.id);
    }
  };

  const handleChangeBirthDate = (value: string | null) => {
    setValue(ANIMAL_FORM_FIELDS.dateBirth, value, { shouldValidate: true });
    setValue(ANIMAL_FORM_FIELDS.dateDead, '');
  };

  const handlerCreateNursery = (nursery: TNurseryDto) => {
    setValue(ANIMAL_FORM_FIELDS.animalRegistrationSystemId, nursery.registrationSystem.id);
    handleChangeNursery({ value: nursery.id, label: nursery.nameEn }, nursery);
  };

  const useGetAsyncNurseries = (search: string) => {
    const { equal, partialEqual } = comparisonNode;
    const expressions = [partialEqual('nameEn', search), equal('species.id', speciesId)];

    if (watchRegisterSystem) {
      expressions.push(equal('registrationSystem.id', watchRegisterSystem));
    }

    return useGetNurseriesQuery({ query: queries.and(expressions) });
  };

  const disabledBirthDate = (current: Dayjs) => current.isAfter(dayjs());
  const disabledDeathDate = (current: Dayjs) => current.isBefore(dateBirthWatch) || current.isAfter(dayjs());

  return (
    <>
      <Popup
        title="Добавить питомца"
        cancelText="Отменить"
        size="MEDIUM"
        okText="Отправить на модерацию"
        onOk={onFormSubmit}
        okButtonProps={{ loading: isLoading }}
        open={open && !isOpenAddNursery}
        {...props}
      >
        <FormProvider {...formMethods}>
          <Flex gap={24} vertical className={styles['animal-form']}>
            <Controller
              render={({ field: { onChange, value = [] }, fieldState: { error } }) => (
                <Drag
                  labelProps={{ labelWrapperClassName: styles.drag }}
                  maxCount={6}
                  handleChange={onChange}
                  error={!!error}
                  fileList={value}
                  typeList={LIST_TYPE.IMAGE}
                />
              )}
              name={ANIMAL_FORM_FIELDS.images}
            />
            <SystemRegistrationSelect
              speciesId={speciesId}
              onChange={handleChangeRegisterSystem}
              controllerProps={{ name: ANIMAL_FORM_FIELDS.animalRegistrationSystemId }}
            />
            <LabelWrapper
              label={<Checkbox controlledProps={{ name: ANIMAL_FORM_FIELDS.isShowChipId }}>Микрочип ID</Checkbox>}
            >
              <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.chipId }} disabled={!isShowChipIdWatch} />
            </LabelWrapper>
            {speciesId === SPECIES.DOGS && (
              <LabelWrapper
                label={<Checkbox controlledProps={{ name: ANIMAL_FORM_FIELDS.isShowStampId }}>Клеймо</Checkbox>}
              >
                <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.stampId }} disabled={!isShowStampIdWatch} />
              </LabelWrapper>
            )}
            <Input
              controllerProps={{ name: ANIMAL_FORM_FIELDS.breederName }}
              labelProps={{ label: 'Заводчик' }}
              showErrorMsg={false}
              disabled
            />
            <LabelWrapper label="Питомник">
              <Flex vertical gap={16}>
                <AsyncSelect
                  useQuery={useGetAsyncNurseries}
                  onChange={(value, option) => {
                    if (!Array.isArray(option)) {
                      handleChangeNursery(value, option);
                    }
                  }}
                  showErrorMsg={false}
                  controllerProps={{ name: ANIMAL_FORM_FIELDS.nursery }}
                />
                <Button className="w-full" onClick={openAddNursery}>
                  Добавить питомник
                </Button>
              </Flex>
            </LabelWrapper>

            <Input controllerProps={{ name: ANIMAL_FORM_FIELDS.fullName }} labelProps={{ label: 'Имя' }} />
            <BreedsSelect speciesId={speciesId} disabled controllerProps={{ name: ANIMAL_FORM_FIELDS.breedId }} />
            <Datepicker
              controllerProps={{ name: ANIMAL_FORM_FIELDS.dateBirth }}
              labelProps={{ label: 'Дата рождения' }}
              maxDate={dayjs()}
              disabledDate={disabledBirthDate}
              onChange={handleChangeBirthDate}
            />
            <AnimalSexSelect
              speciesId={speciesId}
              disabled={isFieldDisabled(ANIMAL_FORM_FIELDS.animalSex)}
              controllerProps={{ name: ANIMAL_FORM_FIELDS.animalSex }}
            />
            <LabelWrapper
              label={
                <Checkbox controlledProps={{ name: ANIMAL_FORM_FIELDS.isShowDeadDate }} disabled={!dateBirthWatch}>
                  Дата смерти
                </Checkbox>
              }
            >
              <Datepicker
                controllerProps={{ name: ANIMAL_FORM_FIELDS.dateDead }}
                disabled={!isShowDeathDate}
                disabledDate={disabledDeathDate}
              />
            </LabelWrapper>
            <AnimalStatusSelect controllerProps={{ name: ANIMAL_FORM_FIELDS.status }} />
            <SpeciesColors speciesId={speciesId} />
            <CountrySelect
              controllerProps={{ name: ANIMAL_FORM_FIELDS.birthCountryId }}
              labelProps={{ label: 'Страна рождения' }}
            />
            <CountrySelect
              controllerProps={{ name: ANIMAL_FORM_FIELDS.liveCountryId }}
              labelProps={{ label: 'Страна проживания' }}
              onChange={handleChangeLiveCountry}
            />
            <CitySelect
              labelProps={{ label: 'Город' }}
              controllerProps={{ name: ANIMAL_FORM_FIELDS.cityId }}
              countryId={watchLiveCountry}
            />
            <Controller
              render={({ field: { onChange, value = [] } }) => (
                <Drag
                  labelProps={{
                    label: 'Загрузить родословную для подтверждения введенных данных',
                    subtitle: 'Данная информация необходима для модерации и не будет видна другим пользователям',
                    labelWrapperClassName: styles.drag,
                  }}
                  maxCount={1}
                  fileList={value}
                  handleChange={onChange}
                />
              )}
              name={ANIMAL_FORM_FIELDS.pedigreeId}
            />
            <Divider />
            <BadgeInput
              controllerProps={{ name: ANIMAL_FORM_FIELDS.hashTags }}
              labelProps={{ label: 'Хештеги' }}
              placeholder="Напишите хештег и нажмите Enter"
            />
          </Flex>
        </FormProvider>
      </Popup>
      {isOpenAddNursery && (
        <AddNurseryModal
          onSubmit={handlerCreateNursery}
          speciesId={speciesId}
          disabledFields={[NURSERY_FORM_FIELDS.speciesId]}
          onCancel={closeAddNursery}
        />
      )}
    </>
  );
};
