import { useGetBreedsQuery } from '@/entities/breed-controller';
import { useGetSpeciesRegistrationSystemsQuery } from '@/entities/registration-system-controller';
import { useGetSpeciesQuery } from '@/entities/species-controller';
import { Input, RadioGroup, Select } from '@/shared/components';
import { ColumnWrapper } from '@/shared/containers';
import { queries, typedIncludes } from '@/shared/utils';
import { nameRule, nurseryEngRule, selectRule } from '@/shared/validation';
import { Controller, useFormContext } from 'react-hook-form';
import { z } from 'zod';

const BREED = 'Породы';
const SYSTEM_REGISTRATION = 'Выберите систему регистрации питомника, указанного в имени животного';
const NAME_NURSERY_EN = 'Название питомника eng';

export const NURSERY_FORM_FIELDS = {
  nurseryNameEn: 'nurseryNameEn',
  speciesId: 'speciesId',
  breedId: 'breedId',
  registrationSystemId: 'registrationSystemId',
  breederName: 'breederName',
} as const;

type TProps = {
  width?: string | number;
  disabledFields?: (keyof typeof NURSERY_FORM_FIELDS)[];
  hideFields?: (keyof typeof NURSERY_FORM_FIELDS)[];
};

export const nurserySchema = z.object({
  [NURSERY_FORM_FIELDS.speciesId]: z.number(),
  [NURSERY_FORM_FIELDS.breedId]: selectRule(),
  [NURSERY_FORM_FIELDS.registrationSystemId]: selectRule(),
  [NURSERY_FORM_FIELDS.nurseryNameEn]: nurseryEngRule(),
  [NURSERY_FORM_FIELDS.breederName]: nameRule().optional(),
});

export type TNurserySchema = z.infer<typeof nurserySchema>;

export const NurseryCreate = ({ width, disabledFields }: TProps) => {
  const { control, watch, resetField } = useFormContext<TNurserySchema>();
  const species = watch(NURSERY_FORM_FIELDS.speciesId);

  const { data: speciesOptions = [] } = useGetSpeciesQuery();
  const { data: breeds = [], isFetching: isBreedsFetching } = useGetBreedsQuery({
    query: queries.equal('species.id', species),
  });
  const { data: registrationSystems, isFetching: isRegistrationSystemsFetching } =
    useGetSpeciesRegistrationSystemsQuery(species);

  const getBooleanFlag = (field: keyof typeof NURSERY_FORM_FIELDS, arr?: (keyof typeof NURSERY_FORM_FIELDS)[]) =>
    typedIncludes(field, arr);

  return (
    <ColumnWrapper style={{ width }}>
      {!!speciesOptions.length && (
        <Controller
          control={control}
          name={NURSERY_FORM_FIELDS.speciesId}
          render={({ field }) => (
            <RadioGroup
              {...field}
              options={speciesOptions}
              disabled={getBooleanFlag(NURSERY_FORM_FIELDS.speciesId, disabledFields)}
              onChange={(event) => {
                field.onChange(event);
                resetField(NURSERY_FORM_FIELDS.breedId);
                resetField(NURSERY_FORM_FIELDS.registrationSystemId);
              }}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name={NURSERY_FORM_FIELDS.breedId}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            labelProps={{ label: BREED }}
            options={breeds}
            errorMsg={error?.message}
            disabled={getBooleanFlag(NURSERY_FORM_FIELDS.breedId, disabledFields)}
            loading={isBreedsFetching}
          />
        )}
      />
      <Controller
        control={control}
        name={NURSERY_FORM_FIELDS.registrationSystemId}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            labelProps={{ label: SYSTEM_REGISTRATION }}
            options={registrationSystems}
            disabled={getBooleanFlag(NURSERY_FORM_FIELDS.registrationSystemId, disabledFields)}
            loading={isRegistrationSystemsFetching}
            errorMsg={error?.message}
          />
        )}
      />
      <Controller
        name={NURSERY_FORM_FIELDS.nurseryNameEn}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <Input
            {...field}
            errorMsg={error?.message}
            disabled={getBooleanFlag(NURSERY_FORM_FIELDS.nurseryNameEn, disabledFields)}
            labelProps={{ label: NAME_NURSERY_EN }}
          />
        )}
      />
    </ColumnWrapper>
  );
};
