import { ErrorWrapper, LabelWrapper, TLabelProps } from '@/shared/ui';
import { Controller, FieldError } from 'react-hook-form';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';
import { TControllerProps } from '@/shared/common';
import cn from 'classnames';

type TProps = TextAreaProps & {
  labelProps?: TLabelProps;
  error?: FieldError;
  showErrorMsg?: boolean;
  isBlank?: boolean;
  controllerProps: TControllerProps;
};

const { TextArea: AntTextArea } = Input;

export const TextareaInput = ({ controllerProps, className, showErrorMsg, labelProps, ...props }: TProps) => {
  return (
    <Controller
      render={({ field, fieldState: { error } }) => (
        <LabelWrapper {...labelProps}>
          <ErrorWrapper message={showErrorMsg ? error?.message : ''}>
            <AntTextArea
              className={cn('font-normal', className)}
              size="large"
              status={error && 'error'}
              {...field}
              {...props}
            />
          </ErrorWrapper>
        </LabelWrapper>
      )}
      {...controllerProps}
    />
  );
};
