import { Controller } from 'react-hook-form';
import { ErrorWrapper, Input, LabelWrapper, TInputProps } from '@/shared/ui';
import { useState } from 'react';
import { Flex } from 'antd';
import { Badge, MultiBadge } from '@/shared/components';

type TProps = TInputProps & {
  value?: string[];
  onChange?: (value: string[]) => void;
};

export const BadgeInputComponent = ({ value = [], onChange, labelProps, error, ...props }: TProps) => {
  const [inputValue, setInputValue] = useState<string>('');

  return (
    <Flex gap={8} vertical>
      <LabelWrapper {...labelProps}>
        <ErrorWrapper message={error?.message}>
          <Input
            onPressEnter={() => {
              const trimmedValue = inputValue.trim().replace(/\s+/g, '');
              if (trimmedValue && !value.includes(trimmedValue)) {
                onChange?.([...value, trimmedValue]);
                setInputValue('');
              }
            }}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            trim
            {...props}
          />
        </ErrorWrapper>
      </LabelWrapper>
      <MultiBadge>
        {!!value.length &&
          value.map((option: string) => (
            <Badge label={option} key={option} onClose={() => onChange?.(value.filter((item) => item !== option))} />
          ))}
      </MultiBadge>
    </Flex>
  );
};

export const BadgeInput = ({ controllerProps, ...props }: TProps) => {
  if (!controllerProps) {
    return <BadgeInputComponent {...props} />;
  }

  return (
    <Controller
      render={({ field, fieldState: { error } }) => <BadgeInputComponent {...field} error={error} {...props} />}
      {...controllerProps}
    />
  );
};
