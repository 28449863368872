import { EditIcon } from '@/app/icons';
import { CustomAvatar, Image, TUploadFile, Upload } from '@/shared/components';

export const ProfileAvatar = ({
  url,
  onChange,
  name,
}: {
  url?: string;
  onChange?: (value: TUploadFile) => void;
  name: string;
}) => {
  return (
    <div className="relative">
      {url ? <Image width={100} height={100} src={url} preview /> : <CustomAvatar size="100px" src={url} name={name} />}
      {onChange && (
        <Upload
          className="absolute top-0 right-[-20px] cursor-pointer"
          handleChange={(value: TUploadFile[]) => onChange(value[0])}
        >
          <EditIcon />
        </Upload>
      )}
    </div>
  );
};
