import { useParams } from 'react-router-dom';
import { useGetGoodsByIdQuery } from '@/entities/goods-controller';
import { Loader } from '@/app/icons';
import { AnimalGoods } from './animal-goods';
import { ProductGoods } from './product-goods';

export const ShowroomGoods = () => {
  const { goodsId, accountId } = useParams();
  const { data, isFetching } = useGetGoodsByIdQuery(String(goodsId), { skip: !goodsId });

  if (!data || isFetching) {
    return <Loader />;
  }

  if (data.animalExt) {
    return <AnimalGoods data={data} accountId={accountId} />;
  }

  if (data.product) {
    return <ProductGoods data={data} accountId={accountId} />;
  }

  return null;
};
