import { TGoodDto, useGetForeignGoodsQuery, useGetMyGoodsQuery } from '@/entities/goods-controller';
import { useNavigate, useParams } from 'react-router-dom';
import { useMedia } from '@/shared/hooks';
import { ACCOUNT_LINKS, ACCOUNT_TABS, HOME_PATHS } from '@/app/navigation';
import { GoodsCardsList } from '@/features/goods';
import { selectCurrentAccountId, useTypedSelector } from '@/app/store';
import { useState } from 'react';

export const ShowroomGoodsList = () => {
  const { isDesktop } = useMedia();
  const { accountId, goodsId } = useParams();
  const navigate = useNavigate();
  const myAccountId = useTypedSelector(selectCurrentAccountId);
  const [page, setPage] = useState<number>(1);

  const isForeign = !!accountId;
  const querySelector = isForeign ? useGetForeignGoodsQuery : useGetMyGoodsQuery;
  const queryAccountId = isForeign ? accountId : myAccountId;

  const { data, isFetching } = querySelector(
    { page, accountId: String(queryAccountId) },
    {
      skip: !queryAccountId,
      selectFromResult: ({ data, ...rest }) => ({
        data: data
          ? {
              ...data,
              totalElements: goodsId ? data.totalElements - 1 : data.totalElements,
              content: goodsId ? data.content.filter((item) => item.id !== goodsId) : data.content,
            }
          : undefined,
        ...rest,
      }),
    },
  );

  const onOpenGood = (good: TGoodDto) => {
    const getPath = () => {
      if (accountId) {
        return `${ACCOUNT_LINKS.account}/${accountId}/${ACCOUNT_TABS.showroom}/${good.id}`;
      }
      return `${HOME_PATHS.showroom}/${good.id}`;
    };
    navigate(getPath());
  };

  const emptyText = !goodsId ? (accountId ? 'У этого юзера еще нет товаров' : 'У вас еще нет товаров') : undefined;
  const title = goodsId && accountId ? 'Другие товары этого продавца' : undefined;

  return (
    <GoodsCardsList
      data={data}
      setPage={setPage}
      isFetching={isFetching}
      title={title}
      emptyText={emptyText}
      slidesToShow={isDesktop ? 3 : 2}
      onClick={onOpenGood}
    />
  );
};
