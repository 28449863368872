import { TImageProps } from '@/shared/components';
import { Card, Caption2 } from '@/shared/ui';
import React from 'react';
import animalAvatar from '@/app/icons/svg/animalAvatar.svg';
import { CardProps, Flex } from 'antd';

type TProps = CardProps & {
  image: TImageProps;
  onClick: () => void;
};

export const NurseryAnimalCard = ({ image, onClick }: TProps) => (
  <Card
    className="h-[264px] w-[156px] lg:w-[169px] hover:scale-[1.05] duration-[0.2s] ease-in-out"
    onClick={onClick}
    cover={<img alt="card-img" className="h-[156px] object-cover" src={image.src || animalAvatar} />}
    metaProps={{
      description: (
        <Flex vertical>
          <Caption2 className="max-h-[60px] line-clamp-3">{image.bottomInfo?.title}</Caption2>
          <Caption2 className="font-semibold">{image.bottomInfo?.subTitle}</Caption2>
        </Flex>
      ),
    }}
  />
);
