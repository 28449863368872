import { TGoodDto } from '@/entities/goods-controller';
import { Body2, Caption2, Headline4, TCardProps } from '@/shared/ui';
import animalAvatar from '@/app/icons/svg/animalAvatar.svg';
import React from 'react';
import { useCurrencyConvert } from '@/shared/hooks/currency';

export const useGoodCard = (good: TGoodDto): Partial<TCardProps> => {
  const { animal, product, currency, price, country, city } = good;
  const getPrice = useCurrencyConvert();

  const getSrc = () => {
    if (animal) {
      return animal.avatar?.url;
    }
    if (product) {
      return product.images[0].url;
    }
  };

  const getDescription = () => {
    if (animal) {
      return (
        <>
          <Headline4 className="text-neutral2">{getPrice(currency, price)}</Headline4>
          <Body2>{animal.nursery.nameEn}</Body2>
          <Body2 className="font-semibold">{animal.fullName}</Body2>
          <Caption2 className="text-neutral4">
            {country?.name}, {city?.name}
          </Caption2>
        </>
      );
    }
    if (product) {
      return (
        <>
          <Headline4 className="text-neutral2">{getPrice(currency, price)}</Headline4>
          <Body2>{product.name}</Body2>
          <Caption2 className="text-neutral4">
            {country?.name}, {city?.name}
          </Caption2>
        </>
      );
    }
  };

  return {
    cover: <img alt="card-img" className="h-[200px] object-cover" src={getSrc() || animalAvatar} />,
    metaProps: { description: getDescription() },
  };
};
