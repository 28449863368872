import cn from 'classnames';
import {
  GoodsDelivery,
  GoodsDescription,
  GoodsFooter,
  GoodsImagesView,
  GoodsSeller,
  GoodsTitle,
} from '@/features/goods';
import { Collapse } from '@/shared/components';
import { FlexPage } from '@/shared/containers';
import { TGoodDtoExt } from '@/entities/goods-controller';
import { MarketplaceGoodsList } from '@/features/marketplace';

export const MobileView = ({ data }: { data: TGoodDtoExt }) => {
  const {
    product,
    city,
    canEdit,
    account: { id: accountId },
  } = data;

  if (!product) {
    return null;
  }

  const { images, description } = product;

  return (
    <>
      <FlexPage className={cn({ 'pb-[120px]': !canEdit }, { 'pb-[70px]': canEdit })}>
        <GoodsTitle data={data} />
        <GoodsImagesView images={images} />
        {city && <GoodsDelivery city={city.name} />}
        {!canEdit && <GoodsSeller accountId={accountId} good={data} />}
        <Collapse
          defaultActiveKey={1}
          items={[{ key: 1, label: 'Описание', children: <GoodsDescription description={description} /> }]}
        />
        <MarketplaceGoodsList />
      </FlexPage>
      <GoodsFooter goodData={data} />
    </>
  );
};
