import { baseApi } from '@/app/core';
import { convertToSelectOption } from '@/shared/utils';
import { TSelectOption } from '@/shared/common';

type TFileInfoDto = {
  id: string;
  url: string;
  fileName: string;
};

export enum GOODS_CATEGORY {
  DOGS = 1,
  CATS,
  PRODUCTS,
  SERVICES,
  CLOTH,
  ANIMALS,
}

export type TGoodMainCategoryDto = {
  id: GOODS_CATEGORY;
  name: string;
  fileImageId: TFileInfoDto;
};

export type TGoodCategoryParentDto = TGoodMainCategoryDto;

export type TGoodCategoryDto = {
  id: number;
  name: string;
  fileImageId: TFileInfoDto;
  parent: TGoodCategoryParentDto;
};

export type TGoodCategoryDetailsDto = TGoodMainCategoryDto & {
  id: number;
  name: string;
  fileImageId: TFileInfoDto;
  parents: TGoodCategoryParentDto[];
  children: TGoodCategoryDto[];
};

export const goodCategoryApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMainCategories: builder.query<(TGoodMainCategoryDto & TSelectOption)[], void>({
      query: () => 'goods/categories/main',
      transformResponse: (response: TGoodMainCategoryDto[]) => convertToSelectOption(response),
    }),
    getCategoriesByMain: builder.query<TGoodCategoryDetailsDto, GOODS_CATEGORY>({
      query: (id) => `goods/categories/${id}`,
    }),
  }),
});

export const { useGetMainCategoriesQuery, useGetCategoriesByMainQuery, useLazyGetCategoriesByMainQuery } =
  goodCategoryApi;
