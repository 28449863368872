import { Flex } from 'antd';
import { Tabs, TTabsProps } from '@/shared/components';
import { Body2, Button, Caption4, Card, Headline2, Headline4, Headline6, List } from '@/shared/ui';
import { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import cn from 'classnames';
import { TPriceDto, useGetPeriodsQuery, useGetPeriodTariffQuery } from '@/entities/tariff-controller';
import { selectCurrency, selectTariff, useTypedSelector } from '@/app/store';
import { priceConverter } from '@/shared/utils';
import { Success } from '@/app/icons';
import { usePostTariffPaymentMutation } from '@/entities/payment-controller';
import { useAuth, useMedia } from '@/shared/hooks';

const TariffPrice = ({ prices, periodId }: { prices: TPriceDto[]; periodId: number | null }) => {
  const currency = useTypedSelector(selectCurrency);

  if (!prices.length) {
    return <Headline6 className="text-neutral5">Бесплатно</Headline6>;
  }

  const selectedPrice = prices.find((item) => item.currencyId === currency?.id);

  if (!selectedPrice) {
    return null;
  }

  const { price, discountedPrice, pricePerMonth } = selectedPrice;

  const getPeriod = () => {
    if (periodId === 2) {
      return '/ 6 мес.';
    }

    if (periodId === 1) {
      return '/ месяц';
    }

    if (periodId === 3) {
      return '/ год';
    }
  };

  return (
    <Flex vertical gap={4}>
      <Flex align="center">
        <Headline6 className="text-neutral5 mr-[4px] line-through">
          {periodId ? `${priceConverter(price)} ${currency?.name}` : 'Бесплатно'}
        </Headline6>
        {discountedPrice && (
          <Headline2 className="text-primary1 mr-[4px]">
            {discountedPrice} {currency?.name}
          </Headline2>
        )}
        {discountedPrice && <Body2>{getPeriod()}</Body2>}
      </Flex>
      {pricePerMonth && (
        <Headline6 className="text-xs">{`или ${priceConverter(pricePerMonth)} ${currency?.name} / месяц`}</Headline6>
      )}
    </Flex>
  );
};

export const TariffsPlans = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>('');
  const { data: periods = [] } = useGetPeriodsQuery();
  const { data: tariffs = [] } = useGetPeriodTariffQuery(selectedPeriod, { skip: !selectedPeriod });
  const [postPayment, { isLoading }] = usePostTariffPaymentMutation();
  const currency = useTypedSelector(selectCurrency);
  const currentTariff = useTypedSelector(selectTariff);
  const { handleAuth, currentAccountId } = useAuth();
  const { isMobile } = useMedia();

  const items: TTabsProps['items'] = periods.map(({ id, period, discount }) => ({
    label: (
      <Flex className="w-[88px] lg:w-[122px]" vertical align="center">
        <Caption4 className="lg:text-dody2">{period}</Caption4>
        <Caption4 className="lg:text-button3 text-primary3">Скидка {discount}%</Caption4>
      </Flex>
    ),
    key: String(id),
  }));

  const onPayTariff = async (tariffId: number) => {
    if (!currency) {
      return;
    }

    window.location.href = await postPayment({ currencyId: currency?.id, tariffId }).unwrap();
  };

  useEffect(() => {
    if (periods.length) {
      setSelectedPeriod(String(periods[0].id));
    }
  }, [periods]);

  const handlePayTariff = (id: number) => {
    handleAuth(() => onPayTariff(id));
  };

  return (
    <Flex gap={24} vertical>
      <div className={cn('lg:mx-auto lg:p-[4px] border border-neutral6 rounded-[100px]', styles['tariff-tabs'])}>
        {!!items.length && <Tabs items={items} activeKey={selectedPeriod} onTabClick={setSelectedPeriod} />}
      </div>
      <List
        className={styles['tariffs-list']}
        grid={{ gutter: isMobile ? 16 : 8, column: isMobile ? 1 : 3 }}
        dataSource={tariffs}
        customItem={({ name, recommended, prices, options, periodId, id }) => {
          const isFreeTariff = !prices.length && currentAccountId;

          return (
            <Card
              style={{
                height: 'inherit',
                width: '100%',
              }}
              className={cn('flex flex-col justify-normal', styles['tariff-card'], {
                [styles['recommended-tariff-card']]: recommended,
              })}
              title={
                <Flex vertical gap={4} className="min-h-[74px] h-full">
                  <Flex justify="space-between" align="center">
                    <Headline4>{name}</Headline4>
                    {recommended && (
                      <Body2
                        className="text-primary4 h-fit rounded-l-[100px]
                        font-normal bg-primary4 bg-opacity-10 px-xs absolute right-0"
                      >
                        {id === currentTariff ? 'Текущий' : 'Рекомендуем'}
                      </Body2>
                    )}
                    {id === currentTariff && !recommended && (
                      <Body2
                        className="text-neutral4 h-fit rounded-l-[100px]
                        font-normal bg-neutral4 bg-opacity-10 px-xs absolute right-0"
                      >
                        Текущий
                      </Body2>
                    )}
                  </Flex>
                  <TariffPrice prices={prices} periodId={periodId} />
                </Flex>
              }
              metaProps={{
                description: (
                  <Flex vertical gap={8} className="min-h-[56px] h-full">
                    {options.map((item) => (
                      <Flex gap={8} key={item.id}>
                        <Success />
                        <Body2>{item.name}</Body2>
                      </Flex>
                    ))}
                  </Flex>
                ),
              }}
              actions={[
                <Button
                  style={{ display: isFreeTariff ? 'none' : '', width: '100%' }}
                  key="choose"
                  size="x-small"
                  loading={isLoading}
                  onClick={() => handlePayTariff(id)}
                  disabled={id === currentTariff}
                >
                  Выбрать
                </Button>,
              ]}
            />
          );
        }}
      />
    </Flex>
  );
};
