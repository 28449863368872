import { Button, Headline4, List } from '@/shared/ui';
import { useMedia, useModal } from '@/shared/hooks';
import { Flex } from 'antd';
import { AddLitterIndividualModal } from '@/features/litters/add-litter-individual-modal';
import { useParams } from 'react-router-dom';
import { LitterIndividualsCard } from '@/pages/nursery/litters/litters-details/ui/litter-individuals-card';
import { useGetLittersAnimalsQuery } from '@/entities/animal-controller';
import { TNurseryDto } from '@/entities/nursery-controller';
import { GridViewSwitcherIcon, ListViewSwitcherIcon, Loader } from '@/app/icons';

type TProps = {
  canEdit: boolean;
  nursery?: TNurseryDto;
};

export const LitterIndividuals = ({ canEdit, nursery }: TProps) => {
  const { open, close, isOpen } = useModal();
  const { id } = useParams();
  const { data = [], isFetching: isPetsFetching } = useGetLittersAnimalsQuery(id as string);
  const { isMobile } = useMedia();
  const { isOpen: isListView, close: openGridView, open: openListView } = useModal();

  if (isPetsFetching) {
    return <Loader />;
  }

  return (
    <Flex vertical gap={16}>
      <Flex>
        <Headline4>Рождённые в помёте ({data.length})</Headline4>
        {!!data.length && (
          <Flex gap={12} justify="flex-end" className="ml-auto items-center">
            {isListView ? (
              <ListViewSwitcherIcon className="view-switcher cursor-pointer" onClick={openGridView} />
            ) : (
              <GridViewSwitcherIcon className="view-switcher cursor-pointer hover:fill-black" onClick={openListView} />
            )}
          </Flex>
        )}
      </Flex>
      <List
        grid={{ gutter: 16, column: isMobile && !isListView ? 2 : isListView ? 1 : 3 }}
        itemLayout={isListView ? 'vertical' : 'horizontal'}
        dataSource={data}
        customItem={(individual) => (
          <LitterIndividualsCard key={individual.id} animal={individual} isList={isListView} />
        )}
      />
      {canEdit && (
        <Button size="x-small" onClick={open}>
          Добавить особь
        </Button>
      )}

      {nursery && isOpen && (
        <AddLitterIndividualModal
          litter={Number(id)}
          nurseryId={nursery.id}
          speciesId={nursery.species.id}
          open={isOpen}
          onCancel={close}
        />
      )}
    </Flex>
  );
};
