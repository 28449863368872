import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@/app/core';
import { TProductsDto, TProductsPatchDto, TProductsRequestDto } from '@/entities/products-controller/types';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getProductById: builder.query<TProductsDto, string>({
      query: (id) => ({
        url: `products/${id}`,
        method: 'GET',
      }),
    }),
    postProduct: builder.mutation<TProductsDto, TProductsRequestDto>({
      query: (data) => ({
        url: 'products',
        method: 'POST',
        data,
      }),
    }),
    putProduct: builder.mutation<TProductsDto, TProductsRequestDto>({
      query: (data) => ({
        url: `products/${data.id}`,
        method: 'PUT',
        data,
      }),
    }),
    patchProduct: builder.mutation<TProductsDto, TProductsPatchDto>({
      query: (data) => ({
        url: `products/${data.id}`,
        method: 'PATCH',
        data,
      }),
    }),
  }),
});

export const { useGetProductByIdQuery, usePatchProductMutation, usePostProductMutation, usePutProductMutation } =
  productsApi;
