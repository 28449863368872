import { Flex } from 'antd';
import { FilterIcon } from '@/app/icons';
import { Button } from '@/shared/ui';
import { useModal } from '@/shared/hooks';
import { FilterDrawer } from './filter-drawer';

export const MarketplaceFilter = () => {
  const { isOpen, open, close } = useModal();

  return (
    <Flex>
      <Button onClick={open} type="link" size="unset" icon={<FilterIcon />} />
      {isOpen && <FilterDrawer onCancel={close} open={isOpen} onClose={close} />}
    </Flex>
  );
};
