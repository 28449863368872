import { baseApi } from '@/app/core';

export type TPriceDto = {
  currencyId: number;
  price: number;
  discountedPrice: number;
  pricePerMonth: number;
};

export type TOptionDto = {
  id: number;
  name: string;
  type: string;
  value: string;
};

export type TTariffDto = {
  id: number;
  name: string;
  description: string;
  periodId: number;
  prices: TPriceDto[];
  options: TOptionDto[];
  recommended: boolean;
};

export type TPeriodDto = {
  id: number;
  period: string;
  discount: number;
};

export const tariffApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getPeriods: builder.query<TPeriodDto[], void>({
      query: () => ({
        url: 'tariff/periods',
        method: 'GET',
      }),
    }),
    getPeriodTariff: builder.query<TTariffDto[], string>({
      query: (periodId) => ({
        url: `tariff/period/${periodId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetPeriodsQuery, useGetPeriodTariffQuery } = tariffApi;
