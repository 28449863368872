import { useSendFileMutation } from '@/entities/file-controller/api';
import { DragWithCrop, LIST_TYPE, Popup } from '@/shared/components';
import type { TDragFile } from '@/shared/components/DragWithCrop';
import { compressImage, getCroppedImg } from '@/shared/components/DragWithCrop/helpers';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Area } from 'react-easy-crop';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

const TITLE = 'Добавить медиа файл';

const FORM_FIELDS = {
  images: 'images',
} as const;

const validation = z.object({
  [FORM_FIELDS.images]: z
    .array(
      z.object({
        id: z.string(),
        url: z.string(),
      }),
    )
    .min(1, 'Необходимо загрузить хотя бы одно изображение'),
});

type TSchema = z.infer<typeof validation>;

type TProps = {
  onSubmit: (data: TSchema) => void;
  onCancel: () => void;
};

export const AddMediaModal = ({ onCancel, onSubmit }: TProps) => {
  const formMethods = useForm<TSchema>({
    defaultValues: {
      [FORM_FIELDS.images]: [],
    },
    resolver: zodResolver(validation),
  });

  const { handleSubmit, setValue } = formMethods;
  const [uploadedFiles, setUploadedFiles] = useState<TDragFile[]>([]);
  const [croppedAreas, setCroppedAreas] = useState<Record<string, Area>>({});
  const [sendFile] = useSendFileMutation();

  const saveCroppedImages = async () => {
    const uploaded: TDragFile[] = [];

    await Promise.all(
      uploadedFiles.map(async (file) => {
        const croppedArea = croppedAreas[file.uid];
        if (!croppedArea || !file.originFileObj) return;

        const compressedFile = await compressImage(file.originFileObj);
        const processedFile = await getCroppedImg(compressedFile, croppedArea);

        return sendFile(processedFile)
          .unwrap()
          .then((response) => {
            uploaded.push({
              ...file,
              ...response,
              croppedUrl: URL.createObjectURL(processedFile),
            });
          });
      }),
    );

    return uploaded;
  };

  const handleFormSubmit = async () => {
    const savedFiles = await saveCroppedImages();
    setValue(FORM_FIELDS.images, savedFiles);
    handleSubmit(onSubmit)();
  };

  return (
    <Popup
      title={TITLE}
      open
      okButtonProps={{ children: 'Далее', onClick: handleFormSubmit }}
      cancelButtonProps={{ children: 'Отменить', onClick: onCancel }}
      onCancel={onCancel}
    >
      <FormProvider {...formMethods}>
        <Controller
          render={({ field: { onChange }, fieldState: { error } }) => (
            <DragWithCrop
              maxCount={6}
              handleChange={(files) => {
                setUploadedFiles(files);
                onChange(files);
              }}
              onCropComplete={(croppedArea, croppedAreaPixels, uid) => {
                setCroppedAreas((prev) => ({ ...prev, [uid]: croppedAreaPixels }));
              }}
              error={!!error}
              fileList={uploadedFiles}
              typeList={LIST_TYPE.IMAGE}
            />
          )}
          name={FORM_FIELDS.images}
        />
      </FormProvider>
    </Popup>
  );
};
