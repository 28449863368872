import { Flex } from 'antd';
import { Headline3 } from '@/shared/ui';
import { PublishMarketplaceButton } from '@/features/buttons';
import { PlusButtonIcon } from '@/app/icons';
import { ShopCatalogList } from '@/features/shop/shop-catalog-list';

export const MyCatalogList = () => {
  return (
    <Flex vertical gap={16}>
      <Flex justify="space-between">
        <Headline3>Мои товары</Headline3>
        <PublishMarketplaceButton
          buttonProps={{
            size: 'x-small',
            text: 'Добавить товар',
            className: 'w-full md:w-auto',
            icon: <PlusButtonIcon width={16} height={16} />,
          }}
        />
      </Flex>
      <ShopCatalogList />
    </Flex>
  );
};
