import { Card as AntCard, CardProps } from 'antd';
import { CardMetaProps } from 'antd/es/card/Meta';
import './styles.scss';
import cn from 'classnames';
import { ReactNode } from 'react';

export type TCardProps = CardProps & {
  metaProps?: CardMetaProps;
  actionIcon?: ReactNode;
};

const { Meta } = AntCard;

export const Card = ({ metaProps, className, actionIcon, children, ...props }: TCardProps) => {
  return (
    <AntCard className={cn('h-full flex flex-col custom-card relative', className)} {...props}>
      {actionIcon && <div className="absolute top-xxs right-xxs">{actionIcon}</div>}
      {metaProps && <Meta {...metaProps} />}
      {children}
    </AntCard>
  );
};
