import { SVGProps } from 'react';

export const Pencil = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V9.75"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0304 2.26501L6.1204 8.17501C5.8954 8.40001 5.6704 8.84251 5.6254 9.16501L5.3029 11.4225C5.1829 12.24 5.7604 12.81 6.5779 12.6975L8.8354 12.375C9.1504 12.33 9.5929 12.105 9.8254 11.88L15.7354 5.97001C16.7554 4.95001 17.2354 3.76501 15.7354 2.26501C14.2354 0.765006 13.0504 1.24501 12.0304 2.26501Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1816 3.11249C11.6841 4.90499 13.0866 6.30749 14.8866 6.81749"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
