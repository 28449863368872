import { HOME_PATHS } from '@/app/navigation';
import { Button, TButtonProps } from '@/shared/ui';
import { MyGoodsButtonIcon } from '@/app/icons/MyGoodsButtonIcon';
import cn from 'classnames';
import { useHistory } from '@/shared/hooks';

type TProps = TButtonProps;

export const MyGoodsButton = ({
  children = 'Мои товары',
  icon = <MyGoodsButtonIcon width={16} height={16} />,
  className,
  ...props
}: TProps) => {
  const navigate = useHistory();

  const onOpenShowroom = () => {
    navigate(HOME_PATHS.showroom);
  };

  return (
    <Button
      type="default"
      onClick={onOpenShowroom}
      className={cn('w-full lg:w-auto bg-neutral6', className)}
      icon={icon}
      {...props}
    >
      {children}
    </Button>
  );
};
